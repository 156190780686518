import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { label } from "../../../../config";
import { number } from "../../../../config/constants";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import "./task-details.scss";

/**
 * Handles the Task Effort field of sidebar
 * @author Sejal
 * Parent Component TastDetail.js
 */
const TaskEffort = () => {
  const [value, setValue] = useState(null);
  const [unit, setUnit] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { effortUnitList } = useSelector((state) => state.tasks?.defaultDetails);
  const { workflowBasicDetails, workflowFeatures } = useSelector((state) => state.taskWorkflowDetails);

  /*
   * It is used for initiallizing the values of effort field in sidebar
   */
  useEffect(() => {
    if (task.taskId && defaultDetails) {
      setUnit(defaultDetails?.effortUnitList?.find((c) => c.value == task.EffortUnit));
      setValue(task.Effort);
    }
  }, [task?.taskId, task?.Effort, task?.EffortUnit, defaultDetails?.effortUnitList]);

  useEffect(() => {
    if (isNewTask && workflowFeatures && workflowFeatures[number.THREE]?.IsSelected === number.ONE) {
      const effort = workflowBasicDetails?.Effort;
      const effortUnit = workflowBasicDetails?.EffortUnit;
      setUnit(defaultDetails?.effortUnitList?.find((c) => c.value == effortUnit));
      setValue(effort);
      handleTaskKeyUpdate(isNewTask, "effort", effort);
      handleTaskKeyUpdate(isNewTask, "effortUnit", effortUnit);
    } else if (isNewTask) {
      setValue(number.ZERO);
      setUnit(defaultDetails?.effortUnitList[number.ONE]);
    }
  }, [workflowBasicDetails]);

  /*
   * It is used for handling any change in the numeric text box
   * @params Event
   * @Author Sejal
   */
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setErrorMessage(false);
    setValue(inputValue);
    if (inputValue >= number.ONE && inputValue <= number.NINTY_NINE) {
      setValue(inputValue);
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };

  /*
   * It is used for handling any change in the dropdown of effort field
   * @params Event
   * @Author Sejal
   */
  const handleUnitChange = (e) => {
    setUnit(e.target.value);
    const unitSelected = e.target.value;
    const { value } = unitSelected;
    if (value !== task?.effortUnit) {
      handleTaskKeyUpdate(isNewTask, "effortUnit", value);
    }
  };

  /*
   * It is used for updating the effort field in the database through onBlur function
   * @params Event
   * @Author Sejal
   */
  const setDropdownValue = () => {
    if (value >= number.ONE && value <= number.NINTY_NINE && value !== task.effort) {
      handleTaskKeyUpdate(isNewTask, "effort", value);
      if (!unit) {
        setUnit(defaultDetails?.effortUnitList[number.ONE]);
        handleTaskKeyUpdate(isNewTask, "effortUnit", number.TWO);
      }
    }
  };

  return (
    <div className='form-group col-lg-4 col-md-4 col-sm-4'>
      <label>{label.EXPECTED_EFFORT}</label>
      <div className='d-flex'>
        <NumericTextBox
          value={value}
          onChange={handleInputChange}
          id='task-effort-days'
          className='effort-input-box'
          min={number.ONE}
          max={number.NINTY_NINE}
          format='n0'
          decimals={number.ZERO}
          onBlur={setDropdownValue}
        />
        <DropDownList className='ml-2' id='task-effort-unit' data={effortUnitList.slice(1)} dataItemKey='value' value={unit} onChange={handleUnitChange} textField='label' />
      </div>
      {errorMessage && <div className='text-danger'>{value > number.NINTY_NINE ? label.MAX_LIMIT_EFFORT : label.MIN_LIMIT_EFFORT}</div>}
    </div>
  );
};

export default TaskEffort;
