import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCloneRecurrenceEnabled, setExpanded, storeRecurrenceWorkflowAttachements, storeRecurrenceflowElement, toggleDescPopup } from "../../../../actions/taskSidebar";
import { number } from "../../../../config/constants";
import { lowercaseKeys } from "../../../../helper/common";
import { getWorkflow } from "../../../../shared/services/workflow.services";
import { isTrueBit } from "../../../../utils";
import { getTaskRecurrence } from "../../../Tasks/tasks.service";
import ApproverView from "../Approvals/ApproverView";
import RecurringTask from "./RecurringTask";
import TaskAssignment from "./TaskAssignment/TaskAssignment";
import { TaskAssignmentContextProvider } from "./TaskAssignment/taskAssignment.context";
import { TaskDueDate, TaskEffort, TaskFollower, TaskPriority, TaskReminder, TaskStages, TaskTags } from "./index";

/*
 * Render Compact Details Panel on the basis of showSimplifiedDetail
 * @author Bhavana
 */

const TaskDetail = () => {
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const { isNewTask, task, showDescription, descriptionPopupSetting, expanded } = useSelector((state) => state.taskSidebar);
  const { recurrenceDetails } = useSelector((state) => state.recurrence);
  const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
  const dispatch = useDispatch();

  /**
   * fetches and updates workflow details for the applied recurrence (WF based assignment)
   * @author Muskan Thakur
   */
  useEffect(() => {
    (async () => {
      if (recurrenceDetails?.WorkflowId && recurrenceDetails?.TaskAssignmentType === number.FIVE) {
        let workflow = {};
        if (recurrenceDetails.TaskAssignmentType === number.FIVE) {
          workflow = await getWorkflow({ workflowId: recurrenceDetails.WorkflowId });
        }
        workflow?.workflowAttachments?.length && dispatch(storeRecurrenceWorkflowAttachements(workflow?.workflowAttachments));
        dispatch(storeRecurrenceflowElement(workflow?.workflowElements?.map((workflowElement) => lowercaseKeys(workflowElement))));
      } else {
        dispatch(storeRecurrenceflowElement({}));
        dispatch(storeRecurrenceWorkflowAttachements([]));
      }
    })();
  }, [recurrenceDetails?.WorkflowId, recurrenceDetails?.TaskAssignmentType, recurrenceDetails?.Id]);

  useEffect(() => {
    (async () => {
      if (task?.taskId) {
        await getTaskRecurrence({ taskId: task.taskId });
      }
    })();
  }, []);

  /**
   * sets clone based recurrence true if it is applied
   * @author Muskan Thakur
   */
  useEffect(() => {
    if (recurrenceDetails?.TaskAssignmentType === number.TWO && recurrenceDetails?.RecurrenceMode === number.TWO) {
      dispatch(setCloneRecurrenceEnabled());
    }
  }, [recurrenceDetails?.TaskAssignmentType, recurrenceDetails?.RecurrenceMode]);

  /**
   *  Sets intial description popup settings
   */
  useEffect(() => {
    if (descriptionPopupSetting && task?.taskId) {
      const descriptionPanel = (isTrueBit(task, "IsApproved") || task?.ShowLockedDescription) && showDescription ? "task-approval-description" : "task-description";
      dispatch(setExpanded(expanded.filter((item) => item !== descriptionPanel)));
    }
    if (task?.taskId) {
      dispatch(
        toggleDescPopup({
          status: descriptionPopupSetting,
          type: (isTrueBit(task, "IsApproved") || task?.ShowLockedDescription) && showDescription ? "approval-description" : "description",
        })
      );
    }
  }, [task?.taskId, descriptionPopupSetting, showDescription]);

  return (
    <div className='form-row'>
      {!!isTrueBit(task, "InApproval") ? (
        <ApproverView />
      ) : (
        <TaskAssignmentContextProvider>
          {showTaskDetailPanel && !isNewTask ? (
            <div className='col-md-12 col-lg-12 col-sm-12 d-flex'>
              <TaskAssignment />
            </div>
          ) : (
            <TaskAssignment />
          )}
        </TaskAssignmentContextProvider>
      )}
      {!!isTrueBit(task, "InApproval") ? <TaskFollower /> : <TaskStages />}
      {showTaskDetailPanel && !isNewTask ? (
        <>
          <TaskDueDate />
          <TaskReminder />
          <TaskPriority />
        </>
      ) : (
        <>
          <TaskPriority />
          <div className='form-group col-lg-5 col-md-5 col-sm-5'>
            <div className='d-flex'>
              <TaskDueDate />
              <TaskReminder />
              <RecurringTask />
            </div>
          </div>
          <TaskTags />
          <TaskFollower />
          {workflowBasicDetails?.ShowTimesheet ? <TaskEffort /> : <></>}
        </>
      )}
    </div>
  );
};

export default TaskDetail;
