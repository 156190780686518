import React from "react";
import { icon, label } from "../../config";
import { formatDate } from "../editor.helper";

/**
 * Displays detailed information about the task such as name, due date, priority, and assignee.
 *@author Muskan Thakur
 */
const TaskDetailsContent = ({ taskInfo, userInfo }) => {
  const { Name, DueDate, Priority, PriorityName } = taskInfo;

  return (
    <>
      <div className='details-horizontal'>
        <div className='details-column mb-2'>
          <label>{label.TASK}</label>
          <div>{Name}</div>
        </div>
      </div>

      <div className='details-horizontal'>
        <div className='details-column'>
          <label>{label.DUE_DATE}</label>
          <div>{formatDate(DueDate)}</div>
        </div>

        <div className='details-column'>
          <label>{label.PRIORITY}</label>
          <div className='task-content'>
            <div>{icon[Priority]}</div>
            <div>{PriorityName}</div>
          </div>
        </div>

        <div className='details-column'>
          <label>{label.ASSIGN_TO}</label>
          <div className='task-content'>
            {userInfo?.PhotoLink ? (
              <img className='object-fit-cover user-image  rounded-circle' src={userInfo ? userInfo?.PhotoLink : icon.NO_ASSIGNEE} alt='user-image' />
            ) : (
              <div>{icon["ACCOUNT_CIRCLE"]}</div>
            )}
            <div>{userInfo?.label ?? label.UNASSIGNED}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(TaskDetailsContent);
