// actions/action-types.js

export const SET_CURR_USER = "SET_CURR_USER";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const UPDATEPASSWORD_SUCCESS = "UPDATEPASSWORD_SUCCESS";
export const UPDATECOMPANY_STATUS = "UPDATECOMPANY_STATUS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const UPDATE_CURR_USER = "UPDATE_CURR_USER";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const GETQUEUES = "GETQUEUES";
export const ADD_QUEUES = "ADD_QUEUES";
export const UPDATE_QUEUES = "UPDATE_QUEUES";
export const ADD_PROJECT = "ADD_PROJECT";
export const SET_USERS_OF_COMPANY = "SET_USERS_OF_COMPANY";
export const GET_PROJECTS = "GETPROJECTS";
export const GET_SELECTS = "GET_SELECTS";
export const GET_USER_QUEUES = "GET_USER_QUEUES";
export const GET_USER_PROJECTS = "GET_USER_PROJECTS";
export const SET_NEW_PROJECT = "SET_NEW_PROJECT";
export const SET_QUEUE_DESCRIPTION = "SET_QUEUE_DESCRIPTION";
export const GET_PROJECT_USERS = "GET_PROJECT_USERS";
export const GET_FILTERED_PROJECT_USERS = "GET_FILTERED_PROJECT_USERS";
export const GET_QUEUE_PROJECTS = "GET_QUEUE_PROJECTS";
export const ADD_QUEUE_PROJECT = "ADD_QUEUE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const ADD_PROJECT_MEMBER = "ADD_PROJECT_MEMBER";
export const DELETE_PROJECT_MEMBER = "DELETE_PROJECT_MEMBER";
export const UPDATE_PROJECT_MEMBER = "UPDATE_PROJECT_MEMBER";
export const FILTER_DATA_BY_PROJECT_ID = "FILTER_DATA_BY_PROJECT_ID";
export const SET_PROJECT_LOADER = "SET_PROJECT_LOADER";
export const SET_ALL_QUEUE_PROJECTS = "SET_ALL_QUEUE_PROJECTS";
export const GET_FILTERED_QUEUE_PROJECTS = "GET_FILTERED_QUEUE_PROJECTS";
export const GET_ACTIVITY_LOG = "GET_ACTIVITY_LOG";
export const GET_FILTERS_ACTIVITY_LOG = "GET_FILTERS_ACTIVITY_LOG";
export const GET_SECONDARY_PROJECTS = "GET_SECONDARY_PROJECTS";
export const GET_SECONDARY_QUEUES = "GET_SECONDARY_QUEUES";
export const GET_TASKS = "GET_TASKS";
export const GET_USER_TASKS = "GET_USER_TASKS";
export const GET_USER_FILTERS = "GET_USER_FILTERS";
export const ARCHIVED_COUNT = "ARCHIVED_COUNT";
export const DEFAULT_TASK_VALUES = "DEFAULT_TASK_VALUES";
export const SET_ASSIGNEE_LIST = "SET_ASSIGNEE_LIST";
export const GET_BODY_CONFIG = "GET_BODY_CONFIG";
export const GET_TASK_ATTACHMENTS = "GET_TASK_ATTACHMENTS";
export const SET_PINNED = "SET_PINNED";
export const SET_PINNED_TASKSIDEBAR = "SET_PINNED_TASKSIDEBAR";
export const SET_DESCRIPTION_POPUP_SETTING = "SET_DESCRIPTION_POPUP_SETTING";
export const SET_NUDGE = "SET_NUDGE";
export const UPDATE_TASK_DESCRIPTIONS = "UPDATE_TASK_DESCRIPTIONS";
export const SHOW_TASKS_WITHOUT_DUEDATE = "SHOW_TASKS_WITHOUT_DUEDATE";
export const SET_DEPENDENCY_TOGGLE = "SET_DEPENDENCY_TOGGLE";
export const UPDATE_TASK_DETAIL = "UPDATE_TASK_DETAIL";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const STORE_GRID_GROUP = "STORE_GRID_GROUP";
export const RESET_STORE = "RESET_STORE";
export const ALERT_NOTIFICATION = "ALERT_NOTIFICATION";
export const UPDATE_PROJECT_DESC = "UPDATE_PROJECT_DESC";
export const SET_ORGANIZATION_DESCRIPTION = "SET_ORGANIZATION_DESCRIPTION";
export const IS_NEWUSERS_OF_COMPANY_INVITED = "IS_NEWUSERS_OF_COMPANY_INVITED";
export const CLEAR_ADMIN_ACTIVITY_LOGS = "CLEAR_ADMIN_ACTIVITY_LOGS";
export const FILTER_GROUP = "FILTER_GROUP";
export const FILTER_SAVED = "FILTER_SAVED";
export const SET_PROJECT_OWNERS = "SET_PROJECT_OWNERS";
export const UPDATE_TASK_RECURRENCE = "UPDATE_TASK_RECURRENCE";
export const UPDATE_NEW_TASK_VARIABLES = "UPDATE_NEW_TASK_VARIABLES";

// <========== Comments-Action-Type ==========>

export const GET_COMMENTS = "GET_COMMENTS";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const ADD_COMMENT = "ADD_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const GET_REPLIES = "GET_REPLIES";
export const CREATE_REPLY = "CREATE_REPLY";
export const EDIT_REPLY = "EDIT_REPLY";
export const DELETE_REPLY = "DELETE_REPLY";
export const CLEAR_ALL_REPLIES = "CLEAR_ALL_REPLIES";
export const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const SET_EMOJI_LIST = "SET_EMOJI_LIST";
export const COMMENT_REACTION = "COMMENT_REACTION";
export const REPLY_REACTION = "REPLY_REACTION";
export const SET_LOADING = "SET_LOADING";
export const HIDE_NEW_BAR = "HIDE_NEW_BAR";
export const IS_ENTER_CLICKED = "IS_ENTER_CLICKED";
export const CLEAR_STATES = "CLEAR_STATES";
export const HANDLE_NEW_COMMENTS = "HANDLE_NEW_COMMENTS";
export const REMOVE_COMMENT_IMAGES = "REMOVE_COMMENT_IMAGES";
export const REMOVE_REPLY_IMAGES = "REMOVE_REPLY_IMAGES";
export const SET_REALTIME_COMMENTS = "SET_REALTIME_COMMENTS";
export const SET_ACTION_STATUS = "SET_ACTION_STATUS";
export const SET_EDIT_ENTITY = "SET_EDIT_ENTITY";

// Project Settings Actions

export const SET_PROJECT_SETTINGS_DATA = "SET_PROJECT_SETTINGS_DATA";

//Notes actions

export const SET_SHOW_NOTESLIST = "SET_SHOW_NOTESLIST";
export const INCREASE_ZINDEX = "INCREASE_ZINDEX";
export const TOGGLE_NEW_NOTE_EDITOR = "TOGGLE_NEW_NOTE_EDITOR";
export const GET_ALLNOTES = "GET_ALL_NOTES ";
export const CREATE_IN_ALLNOTES = "CREATE_IN_ALLNOTES";
export const REMOVE_IN_ALLNOTES = "REMOVE_IN_ALLNOTES";
export const UPDATE_IN_ALLNOTES = "UPDATE_IN_ALLNOTES";
export const ADD_IN_OPENED_NOTES = "ADD_IN_OPENED_NOTES";
export const REMOVE_IN_OPENED_NOTES = "REMOVE_IN_OPENED_NOTES";
export const UPDATE_IN_OPENED_NOTES = "UPDATE_IN_OPENED_NOTES";
export const SET_ISMORE_NOTES = "SET_ISMORE_NOTES";
export const CLEAR_ALL_NOTES = "CLEAR_ALL_NOTES";
export const STORE_PROJECT_STREAMS = "STORE_PROJECT_STREAMS";
export const STORE_EMOJI = "STORE_EMOJI";
export const UPDATE_STREAM_EMOJI = "UPDATE_STREAM_EMOJI";
export const GET_TASK_PROJECT_ACTIVITY_LOGS = "GET_TASK_PROJECT_ACTIVITY_LOGS";
export const GET_TASK_ACTIVITY_LOGS = "GET_TASK_ACTIVITY_LOGS";
export const GET_PROJECT_ACTIVITY_LOGS = "GET_PROJECT_ACTIVITY_LOGS";
export const CONTEXT_SEARCH = "CONTEXT_SEARCH";

export const ADD_NEW_STREAM = "ADD_NEW_STREAM";
export const IS_LAST_STREAM = "IS_LAST_STREAM";
export const CLEAR_STREAMS = "CLEAR_STREAMS";
export const CLEAR_TASK_ACTIVITY_LOGS = "CLEAR_TASK_ACTIVITY_LOGS";
export const CLEAR_PROJECT_ACTIVITY_LOGS = "CLEAR_PROJECT_ACTIVITY_LOGS";
export const IS_LAST_PROJECT_ACTIVITY_LOGS = "IS_LAST_PROJECT_ACTIVITY_LOGS";
export const IS_LAST_TASK_ACTIVITY_LOGS = "IS_LAST_TASK_ACTIVITY_LOGS";
export const STREAMS_MARK_AS_READ = "STREAMS_MARK_AS_READ";
export const SET_UPDATE_LAYOUT_DESC = "SET_UPDATE_LAYOUT_DESC";
export const IS_COLLAB_CENTRAL_OPENED = "IS_COLLAB_CENTRAL_OPENED";
export const OPEN_DASHBOARD_DESC = "OPEN_DASHBOARD_DESC";

// Bookmarks action types

export const GET_TASK_BOOKMARKS = "GET_TASK_BOOKMARKS";
export const GET_PROJECT_BOOKMARKS = "GET_PROJECT_BOOKMARKS";
export const GET_QUEUE_BOOKMARKS = "GET_QUEUE_BOOKMARKS";

// Notifications action types

export const GET_UNCLEARED_NOTIFICATIONS = "GET_UNCLEARED_NOTIFICATIONS";
export const EMPTY_UNCLEARED_NOTIFICATIONS = "EMPTY_UNCLEARED_NOTIFICATIONS";
export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const SET_NOTIFICATION_HEADER_COUNTS = "SET_NOTIFICATION_HEADER_COUNTS";
export const SET_NAVIGATED_NOTIFICATION = "SET_NAVIGATED_NOTIFICATION";
export const IS_USER_IN_NOTIFICATION_PAGE = "IS_USER_IN_NOTIFICATION_PAGE";
export const SET_REMINDER_COUNT = "SET_REMINDER_COUNT";

// SuperAdmin action types

export const GET_SUPERADMIN_WIDGET_DATA = "GET_SUPERADMIN_WIDGET_DATA";
export const SET_SUPERADMIN_TABLE_DATA = "SET_SUPERADMIN_TABLE_DATA";
export const SET_ALL_SUPERADMIN_TABLE_DATA = "SET_ALL_SUPERADMIN_TABLE_DATA";
export const SET_ACTIVE_ORG_COUNT = "SET_ACTIVE_ORG_COUNT";
export const SET_INACTIVE_ORG_COUNT = "SET_INACTIVE_ORG_COUNT";
export const SET_SUPERADMIN_STREAM_ALIASNAME = "SET_SUPERADMIN_STREAM_ALIASNAME";

//Reminders action types
export const GET_REMINDERS = "GET_REMINDERS";
export const SET_COMMON_GRID_REMINDERS = "SET_COMMON_GRID_REMINDERS";
export const SET_REMINDER_ID = "SET_REMINDER_ID";
export const UPDATE_REMINDER = "UPDATE_REMINDER";
export const DELETE_REMINDER = "DELETE_REMINDER";
export const SET_NAVIGATED_REMINDER = "SET_NAVIGATED_REMINDER";

//Recurrence action types
export const GET_RECURRENCE = "GET_RECURRENCE";
export const GET_RECURRENCE_DEFAULTS = "GET_RECURRENCE_DEFAULTS";
export const SET_RECURRENCE_WORKFLOW_ATTACHEMENT = "SET_RECURRENCE_WORKFLOW_ATTACHEMENT";
export const SET_RECURRENCE_FLOW_ELEMENT = "SET_RECURRENCE_FLOW_ELEMENT";
export const RESET_RECURRENCE = "RESET_RECURRENCE";

//TaskSidebar actions
export const TOGGLE_TASK_SIDEBAR = "TOGGLE_TASK_SIDEBAR";
export const SET_EXPANDED = "SET_EXPANDED";
export const SET_NEW_TASK_DATA = "SET_NEW_TASK_DATA";
export const SET_TASK_ON_BLUR = "SET_TASK_ON_BLUR";
export const SET_IS_TASK_SAVED = "SET_IS_TASK_SAVED";
export const RESET_SIDEBAR = "RESET_SIDEBAR";
export const API_COMPLETED = "API_COMPLETED";
export const SELECTED_VIEW = "SELECTED_VIEW";
export const TOGGLE_SIDEBAR_LOADER = "TOGGLE_SIDEBAR_LOADER";
export const GET_FOCUSED_SECTION = "GET_FOCUSED_SECTION";
export const SET_FOCUSED_ELEMENT = "SET_FOCUSED_ELEMENT";
export const ALL_ATTACHMENT = "ALL_ATTACHMENT";
export const SET_TASK_ATTACHMENTS = "SET_TASK_ATTACHMENTS";
export const CLONE_TASK = "CLONE_TASK";
export const CLONE_ATTACHMENT = "CLONE_ATTACHMENT";
export const CLONE_OR_NEW_TASK_ATTACHMENT_DATA = "CLONE_OR_NEW_TASK_ATTACHMENT_DATA";
export const ATTACHMENT_FILE_CONTENT = "ATTACHMENT_FILE_CONTENT";
export const CLONED = "CLONED";
export const UPDATE_FOLLOWER_IDS = "UPDATE_FOLLOWER_IDS";
export const SET_SIDEBAR_LOADING_STATE = "SET_SIDEBAR_LOADING_STATE";
export const UPDATE_FOLLOWER_NEW_TASK = "UPDATE_FOLLOWER_NEW_TASK";
export const UPDATE_TASK_URL = "UPDATE_TASK_URL";
export const TOGGLE_DESC_POPUP = "TOGGLE_DESC_POPUP";
export const TOGGLE_APPROVAL = "TOGGLE_APPROVAL";
export const SET_APPROVAL_LOGS = "SET_APPROVAL_LOGS";
export const SET_TEMPORY_TASK_DESCRIPTION = "SET_TEMPORY_TASK_DESCRIPTION";
export const SET_TASK_WORKFLOW_DETAILS = "SET_TASK_WORKFLOW_DETAILS";
export const SET_STAGES = "SET_STAGES";
export const SET_FLOW_ELEMENTS = "SET_FLOW_ELEMENTS";
export const SET_WORKFLOW_CUSTOM_VARIABLES = "SET_WORKFLOW_CUSTOM_VARIABLES";
export const SET_ASSIGNMENT_DEFAULTS = "SET_ASSIGNMENT_DEFAULTS";
export const SET_SHOW_ATTACHMENT = "SET_SHOW_ATTACHMENT";
export const SET_SHOW_DESCRIPTION = "SET_SHOW_DESCRIPTION";
export const SET_SHOW_SIMPLIFIED_DETAIL = "SET_SHOW_SIMPLIFIED_DETAIL";
export const SET_SHOW_LINKED_SUB_TASK = "SET_SHOW_LINKED_SUB_TASK";
export const SET_SHOW_COMMENTS = "SET_SHOW_COMMENTS";
export const SET_SCROLL_SECTION = "SET_SCROLL_SECTION";
export const ADD_RELATION_DATA = "ADD_RELATION_DATA";
export const SHOW_TASK_OWNERSHIP = "SHOW_TASK_OWNERSHIP";
export const SHOW_TRANSFER_OWNERSHIP = "SHOW_TRANSFER_OWNERSHIP";
export const SET_CURRENT_VIEW = "SET_CURRENT_VIEW";
export const SET_TASK_DETAIL = "SET_TASK_DETAIL";
export const SET_NEW_COMMENT_COUNT = "SET_NEW_COMMENT_COUNT";
export const SET_NEW_REPLY_COUNT = "SET_NEW_REPLY_COUNT";
export const CREATE_TASK_VIA_SHARED_FLOW = "CREATE_TASK_VIA_SHARED_FLOW";
export const UPDATE_DESC_MODE = "UPDATE_DESC_MODE";
export const UPDATE_CREATOR = "UPDATE_CREATOR";
export const CLONE_RECURRENCE_ENABLED = "CLONE_RECURRENCE_ENABLED";
export const EXISTING_TASK_DESC_CHANGED = "EXISTING_TASK_DESC_CHANGED";
export const TASK_DESC_LOG_SENT = "TASK_DESC_LOG_SENT";
export const IS_Y_DOC_EXISTS = "IS_Y_DOC_EXISTS";

//ProjectSidebar actions
export const TOGGLE_PROJECT_SIDEBAR = "TOGGLE_PROJECT_SIDEBAR";
export const SET_SELECTED_PROJECT_SECTION = "SET_SELECTED_PROJECT_SECTION";
export const SET_PROJECT_ON_BLUR = "SET_PROJECT_ON_BLUR";
export const ADD_IN_UPDATED_FIELDS = "ADD_IN_UPDATED_FIELDS";
export const REMOVE_FROM_UPDATED_FIELDS = "REMOVE_FROM_UPDATED_FIELDS";
export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const CLOSE_PROJECT_SIDEBAR = "CLOSE_PROJECT_SIDEBAR";
export const UPDATE_PROJECT_DETAILS = "UPDATE_PROJECT_DETAILS";
export const CHANGE_PROJECT_SIDEBAR = "CHANGE_PROJECT_SIDEBAR";
export const SET_ASSIGNEES = "SET_ASSIGNEES";
export const SET_IS_DISABLED = "SET_IS_DISABLED";
export const SET_STAGELIST = "SET_STAGELIST";

//Latest sidebar reducer actions
export const TASKS = "TASKS";
export const PROJECTS = "PROJECTS";
export const TEAMS = "TEAMS";

//Team SideBar actions
export const TOGGLE_TEAM_SIDEBAR = "TOGGLE_TEAM_SIDEBAR";
export const SET_TEAM_SIDEBAR_DETAILS = "SET_TEAM_SIDEBAR_DETAILS";

//New Admin User Data actions
export const NEW_ADMIN_USER_DATA = "NEW_ADMIN_USER_DATA";
export const NEW_USER_PROFILE_SETUP = "NEW_USER_PROFILE_SETUP";
export const RESET_ADMIN_USER_DATA = "RESET_ADMIN_USER_DATA";
export const RESET_PROFILE_SETUP = "RESET_PROFILE_SETUP";

//Linked Task actions
export const RESET_LINK_TASK_NEW_TASK = "RESET_LINK_TASK_NEW_TASK";
export const SET_CHILD_TASK = "SET_CHILD_TASK";
export const SET_PARENT_TASK = "SET_PARENT_TASK";
export const SET_RELATED_TASK = "SET_RELATED_TASK";
export const SET_SUB_TASK = "SET_SUB_TASK";
export const EXCLUDE_LINK_TASK_SEARCH_DATA = "EXCLUDE_LINK_TASK_SEARCH_DATA";
export const SET_LINK_TASK_COUNT = "SET_LINK_TASK_COUNT";
export const SET_INCOMPLETE_SUBTASK_COUNT = "SET_INCOMPLETE_SUBTASK_COUNT";
export const CLONE_SUBTASK_LINKEDTASK = "CLONE_SUBTASK_LINKEDTASK";

//Manage user
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SHOW_PROFILE_SETTINGS = "SHOW_PROFILE_SETTINGS";
export const SHOW_CONFIRMATION_MODAL = "SHOW_CONFIRMATION_MODAL";

// Search history actions
export const SET_SHOW_RECENT_HISTORY = "SET_SHOW_RECENT_HISTORY";
export const SET_RECENT_SEARCH_TEXT = "SET_RECENT_SEARCH_TEXT";
export const SET_RECENT_HISTORY_LISTS = "SET_RECENT_HISTORY_LISTS";

export const SET_LOADER = "SET_LOADER";

// queue task page actions
export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_PROJECTTASK = "UPDATE_PROJECTTASK";
export const SET_DEFAULT_FILTER_VALUES = "SET_DEFAULT_FILTER_VALUES";
export const SET_QUEUE_FILTER_DETAILS = "SET_QUEUE_FILTER_DETAILS";
export const SET_SAVED_FILTERS = "SET_SAVED_FILTERS";
export const SET_QUEUE_TASKS = "SET_QUEUE_TASKS";
export const EMPTY_QUEUE_FILTER_DETAILS = "EMPTY_QUEUE_FILTER_DETAILS";
export const EMPTY_QUEUE_TASKS = "EMPTY_QUEUE_TASKS";
export const SET_USER_SAVED_FILTER = "SET_USER_SAVED_FILTER";
export const EMPTY_USER_SAVED_FILTER = "EMPTY_USER_SAVED_FILTER";
export const SET_LAST_FILTER_ID = "SET_LAST_FILTER_ID";
export const SET_QUEUE_CARD_COUNTS = "SET_QUEUE_CARD_COUNTS";

// team task page actions
export const SET_TEAM_FILTER_UTILITIES = "SET_TEAM_FILTER_UTILITIES";
export const SET_EMAIL_FILTER = "SET_EMAIL_FILTER";
export const SET_TEAM_FILTER_DETAILS = "SET_TEAM_FILTER_DETAILS";
export const EMPTY_TEAM_FILTER_DETAILS = "EMPTY_TEAM_FILTER_DETAILS";
export const SET_TEAM_SAVED_FILTERS = "SET_TEAM_SAVED_FILTERS";
export const SET_CLONE_TEAM_FILTER_ID = "SET_CLONE_TEAM_FILTER_ID";
export const SET_LAST_FILTER = "SET_LAST_FILTER";
export const IS_APPROVAL_FILTER_SELECTED = "IS_APPROVAL_FILTER_SELECTED";

export const ADD_PROJECT_WORKFLOW = "ADD_PROJECT_WORKFLOW";
export const DELETE_PROJECT_WORKFLOW = "DELETE_PROJECT_WORKFLOW";

export const SET_NAVIGATION_TASKS = "SET_NAVIGATION_TASKS";
export const SET_MY_TASKS = "SET_MY_TASKS";

//sidebar content actions
export const TOGGLE_TASK_DETAIL = "TOGGLE_TASK_DETAIL";
export const TOGGLE_PARENT_TASK_DETAIL = "TOGGLE_PARENT_TASK_DETAIL";
export const TOGGLE_TASK_APPROVAL = "TOGGLE_TASK_APPROVAL";

// USER POSITION ACTIONS
export const SET_USER_POSITION = "SET_USER_POSITION";
export const RESET_POSITION = "RESET_POSITION";

export const IS_PLAN_MY_DAY_ACTIVE = "IS_PLAN_MY_DAY_ACTIVE";
export const SET_PLAN_MY_DAY_COUNT = "SET_PLAN_MY_DAY_COUNT";
export const SET_PLAN_MY_DAT_TASKS = "SET_PLAN_MY_DAT_TASKS";
export const RESET_PLAN_MY_DAY = "RESET_PLAN_MY_DAY";
export const SET_ALL_PLAN_MYDAY_TASK = "SET_ALL_PLAN_MYDAY_TASK";
export const SET_IS_CUSTOM_REMINDER = "SET_IS_CUSTOM_REMINDER";
export const SET_PLAN_MY_DAY_TASKS = "SET_PLAN_MY_DAY_TASKS";
export const SET_PLAN_MY_DAY = "SET_PLAN_MY_DAY";

//tour action
export const SET_RUN = "SET_RUN";
export const RESET_TOUR = "RESET_TOUR";

// app
export const SET_APP_DOMAIN_SLUG = "SET_APP_DOMAIN_SLUG";
export const RESET_APP_DOMAIN = "RESET_APP_DOMAIN";
export const SET_TENANT_LIST = "SET_TENANT_LIST";
export const SET_APP_URL = "SET_APP_URL";
export const SET_SELECT_ORGANIZATION = "SET_SELECT_ORGANIZATION";
// ATtachments actions

export const SET_ALL_ATTACHMENTS = "SET_ALL_ATTACHMENTS";
// export const RESET_TOUR = "RESET_TOUR";

export const SET_IS_CLONE_TASK = "SET_IS_CLONE_TASK";
export const SET_CLONE_TASK = "SET_CLONE_TASK";
export const SET_CLONE_TASK_DETAILS = "SET_CLONE_TASK_DETAILS";
export const RESET_CLONE_TASK = "RESET_CLONE_TASK";

export const SET_KANBAN_TASKS = "SET_KANBAN_TASKS";
export const SET_GROUP_BY = "SET_GROUP_BY";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_KANBAN_COLUMNS = "SET_KANBAN_COLUMNS";
export const SET_WORK_ALLOCATION = "SET_WORK_ALLOCATION";

// Content Manger actions
export const SET_SELECTED_CONTENT_ITEM = "SET_SELECTED_CONTENT_ITEM";
export const SET_CONTENT_MANAGER_DATA = "SET_CONTENT_MANAGER_DATA";
export const SET_STATE_CONTENT_MANAGER_DATA = "SET_STATE_CONTENT_MANAGER_DATA";
export const SET_CONTENT_MANAGER_PANES = "SET_CONTENT_MANAGER_PANES";
export const SET_CONTENT_MANAGER_FILES = "SET_CONTENT_MANAGER_FILES";
export const SET_CONTENT_MANAGER_DATA_VIEW = "SET_CONTENT_MANAGER_DATA_VIEW";
export const SET_UPLOAD_CONTENT_MANAGER_FILES = "SET_UPLOAD_CONTENT_MANAGER_FILES";
