import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { format, parseISO } from "date-fns";
import { icon, label, number, quote } from "../../config";
import { addWorkflowReminder, deleteWorkflowReminder } from "../../shared/services/workflow.services";
import { ReminderContext } from "../Tasks/Reminders/ReminderContextProvider";
import { useTaskDynamicReminderPopup } from "../Tasks/Reminders/taskRemindersHook";
import { WorkflowContext } from "./WorkflowContextProvider";
import "../Tasks/Reminders/reminder.scss";

export const useWorkflowReminderList = () => {
  const dispatch = useDispatch();
  const { setEditRem, setEditName, setEditNoOfDays, setDelId, setEditAddRem, setIsUpdatingReminder, editAddRem, setIsAddClicked, setIsReminderClicked } = useContext(ReminderContext);
  const { workflowReminders, setWorkflowReminders } = useContext(WorkflowContext);
  const { workflowId } = useParams();

  /**
   * show hide modal/popup
   */
  const handleClick = useCallback(
    ({ Id, ReminderMessage, NoOfDays }) => {
      setEditName(ReminderMessage);
      setEditRem(Id);
      setEditNoOfDays(NoOfDays);
      setEditAddRem(!editAddRem);
      setIsReminderClicked(true);
      setIsUpdatingReminder(true);
    },
    [editAddRem]
  );

  /**
   * handle api for reminder
   * @param {payload(reminderId  )}
   * @returns {Void}
   */
  const delReminder = (Id, e, handleOutSideClick) => {
    e.stopPropagation();
    const payload = {
      workflowId: Number(workflowId),
      workflowReminderId: Id,
    };
    dispatch(deleteWorkflowReminder(payload));
    setWorkflowReminders(workflowReminders.filter((rem) => rem.Id !== Id));
    setDelId(null);
    workflowReminders.length - number.ONE === number.ZERO && handleOutSideClick && handleOutSideClick();
  };

  /**
   * Sets the Id of reminder that is meant to be deleted
   * @param {payload(reminderId  )}
   * @returns {Void}
   */
  const setDelReminder = (Id, e) => {
    e.stopPropagation();
    setDelId(Id);
  };

  /**
   * handle Add Reminders
   */
  const handleAddRem = (fromAddButton) => {
    fromAddButton ? setIsAddClicked(true) : setIsReminderClicked(false);
    setIsUpdatingReminder(false);
    setEditRem(null);
    setEditAddRem(!editAddRem);
  };

  return { handleClick, delReminder, setDelReminder, handleAddRem };
};

export const useWorkflowDynamicReminderPopup = ({ setShowReminder, defaultAssignee }) => {
  const {
    daysBefore,
    setDaysBefore,
    setShowCustomPopup,
    displayWarning,
    setDisplayWarning,
    setIsAddClicked,
    setEditAddRem,
    reminderMessage,
    setReminderMessage,
    isReminderMessageEmpty,
    setIsReminderMessageEmpty,
  } = useContext(ReminderContext);
  const { workflowReminders, setWorkflowReminders, taskName, workflowName } = useContext(WorkflowContext);
  const { workflowId , projectId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { handleKeyDown } = useTaskDynamicReminderPopup();

  /**
   * sets the default value of message
   */
  useEffect(() => {
    setReminderMessage(taskName ? taskName : workflowName);
  }, []);

  const handleDaysBeforeChange = (increment) => {
    increment ? setDaysBefore(daysBefore + number.ONE) : setDaysBefore(daysBefore - number.ONE === number.ZERO ? number.ONE : daysBefore - number.ONE);
  };

  /**
   * creates workflow reminders in case of existing worflow
   * @param {*} reminderMessage
   * @param {*} days
   * @author { Sarthak Arora}
   */
  const createReminder = async (reminderMessage, days) => {
    const payload = {
      workflowId: Number(workflowId),
      reminderMessage: reminderMessage,
      noOfDays: days,
    };
    const res = await dispatch(addWorkflowReminder(payload));
    if (res) {
      const addedReminder = {
        Id: res.addedWorkflowId,
        WorkflowId: Number(workflowId),
        ReminderMessage: reminderMessage,
        NoOfDays: daysBefore,
        CreatedBy: user.id,
      };
      setWorkflowReminders([...workflowReminders, addedReminder]);
    }

    return res;
  };

  /**
   * adds reminder in workflowReminders for new workflow
   * @author Sarthak Arora
   */
  const createReminderForNewWorkspace = async () => {
    const addedReminder = {
      Id: workflowReminders.length + 1,
      ReminderMessage: reminderMessage,
      ReminderType: number.TWO,
      NoOfDays: daysBefore,
      CreatedBy: user.id,
    };
    setWorkflowReminders([...workflowReminders, addedReminder]);
  };

  /**
   *   handles the creation of reminder based on the type
   * @author Sarthak Arora
   */

  const handleSaveReminder = async () => {
    workflowId ? createReminder(reminderMessage, daysBefore) : createReminderForNewWorkspace();
    setEditAddRem(false);
    setIsAddClicked(false);
    setShowCustomPopup(false);
    setDaysBefore(number.ONE);
  };

  useEffect(() => {
    workflowReminders.length > number.FOUR ? setDisplayWarning(true) : setDisplayWarning(false);
  }, [workflowReminders]);

  /**
   *  sets the reminderMessage
   *  @author: Sarthak Arora
   */

  const inputEvent = (event) => {
    setIsReminderMessageEmpty(event.target.value === "");
    setReminderMessage(event.target.value);
  };

  /**
   * allows to type numbers in the numeric text box
   * @param {*} e
   */
  const handleNumericTyping = (e) => {
    setDaysBefore(e.target.value);
  };

  /**
   * renders the content for the dynamic reminder popup for workflow
   * @author { Sarthak Arora}
   */
  const popupBodyContent = () => {
    return (
      <>
        <div className='form-group mr-2 ml-2'>
          <label>{label.TITLE}</label>
          <textarea id='workflow-dynamic-reminder-popup-' className='w-100 form-control reminder-title-text' onChange={inputEvent} placeholder={label.TITLE} defaultValue={taskName ? taskName : workflowName} rows="1"/>
        </div>
        <div className='d-flex align-items-center ml-2 mr-2 mb-2'>
          <span className='d-flex align-items-center'>
            <input className='days-from-today mr-2' type='numeric' value={daysBefore} maxLength={number.TWO} onKeyDown={handleKeyDown} onChange={handleNumericTyping} />
            <div className='dynamic-reminder-popup-inc-dec-btn-group d-flex  align-items-center justify-content-center flex-column'>
              <button
                id='workflow-dynamic-reminder-popup-inc-days-before'
                className='mr-2 mt-0 p-0 dynamic-reminder-popup-inc-dec-btn  border-0 outline-none bg-transparent'
                onClick={() => handleDaysBeforeChange(true)}>
                {icon.ARROW_UP}
              </button>
              <button
                id='workflow-dynamic-reminder-popup-dec-days-before'
                className='mr-2 mt-1 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none bg-transparent'
                onClick={() => handleDaysBeforeChange(false)}>
                {icon.ARROW_DOWN}
              </button>
            </div>
            {label.DAYS_BEFORE_DUE_DATE}
          </span>
        </div>
        <span className='reminder-note ml-2 '>{quote.REMINDERS_FOR_DEFAULT_ASSIGNEE}</span>
        {displayWarning && (
          <div>
            <p className='text-red mr-1'> {quote.UPPER_LIMIT_REACHED}</p>
          </div>
        )}

        {(!defaultAssignee && Number(projectId) !== user.myProjectId)&& (
          <div className='d-flex'>
            <span className='text-red ml-2 mr-2 default-assignee-warning-icon'>{icon.WARNING}</span> <p className='text-red mr-1 default-assignee-warning '>{quote.NO_DEFAULT_ASSIGNEE}</p>
          </div>
        )}
        {isReminderMessageEmpty && (
          <div>
            <p className='text-red mr-1 ml-2'>{quote.REMINDER_TITLE_EMPTY}</p>
          </div>
        )}
      </>
    );
  };

  return { popupBodyContent, handleSaveReminder };
};
