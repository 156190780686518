import { createContext, useState } from "react";

export const TaskAssignmentContext = createContext({
  assignmentType: null,
  setAssignmentType: () => {},
  assignedId: null,
  setAssignedId: () => {},
  relatedAssignmentList: [],
  setRelatedAssignment: () => {},
  relatedAssignmentId: null,
  setRelatedAssignmentId: () => {},
  workflowAssignmentType: null,
  setWorkflowAssignmentType: () => {},
  matchingFlowElement: null,
  setMatchingFlowElement: () => {},
  externalUser: false,
  setExternalUser: () => {},
  delegationType: null,
  setDelegationType: () => {},
  currentProject: null,
  setCurrentProject: () => {},
  isDisabled: false,
  setIsDisabled: () => {},

  selection: [],
  setSelection: () => {},
});

/**
 * Wrapper component for Task assignment context provider
 * @author Himanshu Negi
 */
export const TaskAssignmentContextProvider = ({ children }) => {
  const [assignmentType, setAssignmentType] = useState(null);
  const [assignedId, setAssignedId] = useState(null);
  const [relatedAssignmentList, setRelatedAssignmentList] = useState([]);

  const [relatedAssignmentId, setRelatedAssignmentId] = useState(null);

  const [workflowAssignmentType, setWorkflowAssignmentType] = useState(null);
  const [matchingFlowElement, setMatchingFlowElement] = useState(null);

  const [externalUser, setExternalUser] = useState(false);
  const [delegationType, setDelegationType] = useState({ delegate: false, passOn: false, approval: false });
  const [currentProject, setCurrentProject] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [selection, setSelection] = useState([]);

  const value = {
    assignmentType,
    setAssignmentType,
    assignedId,
    setAssignedId,
    relatedAssignmentList,
    setRelatedAssignmentList,
    relatedAssignmentId,
    setRelatedAssignmentId,
    externalUser,
    setExternalUser,
    delegationType,
    setDelegationType,
    currentProject,
    setCurrentProject,
    isDisabled,
    setIsDisabled,
    workflowAssignmentType,
    setWorkflowAssignmentType,
    matchingFlowElement,
    setMatchingFlowElement,

    selection,
    setSelection,
  };
  return <TaskAssignmentContext.Provider value={value}>{children}</TaskAssignmentContext.Provider>;
};
