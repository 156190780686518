import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Input } from "@progress/kendo-react-inputs";
import { setNewTaskVariables } from "../../../actions/task";
import { useWorkflowContext } from "../../../components/Workflow/WorkflowContextProvider";
import { icon } from "../../../config";
import { useEditorContext } from "../../editor.Context";
import { customVariablesClasses, generateUniqueId } from "../../editor.helper";
import { useTaskVariableUpdates } from "./hooks/taskCustomVariable.hooks";

const CustomVariableInput = ({ updateNonEditable, variableDetails, smartChipId, setShowEditChip }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { isNewTask } = useSelector((state) => state.taskSidebar);
  const location = useLocation();

  const { taskCustomVariables, setTaskCustomVariables } = useEditorContext();
  const { addTaskCustomVariables, addWorklowDescriptionCustomVariables } = useTaskVariableUpdates();

  const workflowState = useWorkflowContext();

  const exisitingVariable = useMemo(() => {
    if (smartChipId) {
      if (workflowState?.workflow && workflowState?.workflow?.Id) {
        return taskCustomVariables?.find((variable) => variable.workflowDescriptionVariableId == smartChipId);
      } else {
        return taskCustomVariables?.find((variable) => variable.taskVariableId == smartChipId);
      }
    }
  }, [smartChipId, taskCustomVariables]);

  const newVariableDetails = useMemo(() => {
    return variableDetails || exisitingVariable?.workflowVariableDetails;
  }, [variableDetails, exisitingVariable]);

  useEffect(() => {
    if (smartChipId) return setSelectedValue(exisitingVariable?.variableValue);
    setSelectedValue(newVariableDetails?.attributes?.defaultValue);
  }, [smartChipId, newVariableDetails?.attributes]);

  const inputChangeHandler = (event) => {
    const chosenValue = event.target.value;
    setSelectedValue(chosenValue);
  };

  const updateNode = async () => {
    if (!selectedValue) return;
    if (smartChipId) {
      const updateChip = document.getElementById(smartChipId);
      updateChip.innerHTML = selectedValue;
      setShowEditChip(false);
      const taskCustomVariable = { ...exisitingVariable, variableValue: selectedValue };
      if (location?.pathname?.includes("/workflow")) {
        const newTaskCustomVariables = taskCustomVariables.map((item) => (item.workflowDescriptionVariableId === smartChipId ? { ...taskCustomVariable } : item));
        setTaskCustomVariables([...newTaskCustomVariables]);
        if (workflowState?.workflow?.Id) addWorklowDescriptionCustomVariables([...newTaskCustomVariables], workflowState?.workflow);
        else workflowState?.setworkflowDescriptionVariables([...newTaskCustomVariables]);
      } else {
        const newTaskCustomVariables = taskCustomVariables.map((item) => (item.taskVariableId === smartChipId ? { ...taskCustomVariable } : item));
        setTaskCustomVariables([...newTaskCustomVariables]);
        if (!isNewTask) addTaskCustomVariables([...newTaskCustomVariables]);
        else dispatch(setNewTaskVariables([...newTaskCustomVariables]));
      }
    }

    if (!smartChipId) {
      const id = await generateUniqueId(user.id, "customInput", selectedValue);
      updateNonEditable(selectedValue, id, customVariablesClasses[2]);

      if (location?.pathname?.includes("/workflow")) {
        const taskCustomVariable = {
          workflowDescriptionVariableId: id,
          workflowVariableId: variableDetails?.id,
          workflowVariableDetails: variableDetails,
          variableName: variableDetails?.name,
          variableValue: selectedValue,
          userId: user.id,
        };
        setTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
        if (workflowState?.workflow?.Id) addWorklowDescriptionCustomVariables([...taskCustomVariables, taskCustomVariable], workflowState?.workflow);
        else workflowState?.setworkflowDescriptionVariables([...taskCustomVariables, taskCustomVariable]);
      } else {
        const taskCustomVariable = {
          taskVariableId: id,
          workflowVariableId: variableDetails?.id,
          workflowVariableDetails: variableDetails,
          variableName: variableDetails?.name,
          variableValue: selectedValue,
          userId: user.id,
        };
        setTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
        if (!isNewTask) addTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
        else dispatch(setNewTaskVariables([...taskCustomVariables, taskCustomVariable]));
      }
    }
  };

  return (
    <div style={{ minWidth: "200px" }} className='dt-editor-menu'>
      <div className='w-100' id={`update-editor-custom-input-chip`}>
        <div className='d-flex justify-content-between p-2'>
          <div className='font-weight-bold'>{newVariableDetails?.name}</div>
        </div>
        <div className='d-flex gap-2'>
          <Input
            value={selectedValue}
            onChange={inputChangeHandler}
            placeholder={newVariableDetails?.attributes?.placeholder}
            required={newVariableDetails?.attributes?.required}
            minLength={newVariableDetails?.attributes?.min}
            maxLength={newVariableDetails?.attributes?.max}
          />
          <button className='cursor-pointer' onClick={updateNode}>
            {icon.CORRECT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomVariableInput;
