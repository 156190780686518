import { useSelector } from "react-redux";
import { addTaskVariables } from "../services/taskCustomVariable.service";
import { addWorkflowDescriptionVariables } from "../services/workflowDescriptionVariable.service";

export const useTaskVariableUpdates = () => {
  const { isNewTask, newTaskData, task } = useSelector((state) => state.taskSidebar);

  const addTaskCustomVariables = async (variablesData, newTaskId) => {
    const finalVariablesData = variablesData?.map((variable) => ({
      ...variable,
      workflowVariableDetails: variable?.workflowVariableId,
    }));
    const payload = {
      taskId: newTaskId ? newTaskId : task.taskId,
      projectId: isNewTask ? newTaskData?.project : task.CurrentProject,
      variables: [...finalVariablesData],
    };

    await addTaskVariables(payload);
  };

  const addWorklowDescriptionCustomVariables = async (variablesData, workflow) => {
    const finalVariablesData = variablesData?.map((variable) => ({
      ...variable,
      workflowVariableDetails: variable?.workflowVariableId,
    }));
    const payload = {
      workflowId: workflow?.Id,
      projectId: workflow.ProjectId,
      variables: [...finalVariablesData],
    };

    await addWorkflowDescriptionVariables(payload);
  };

  return { addTaskCustomVariables, addWorklowDescriptionCustomVariables };
};
