import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { setNewTaskVariables } from "../../../actions/task";
import { useWorkflowContext } from "../../../components/Workflow/WorkflowContextProvider";
import { useEditorContext } from "../../editor.Context";
import { customVariablesClasses, generateUniqueId } from "../../editor.helper";
import { useTaskVariableUpdates } from "./hooks/taskCustomVariable.hooks";

const CustomVariableDropdown = ({ updateNonEditable, variableDetails, smartChipId, setShowEditChip }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const parentRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const { isNewTask } = useSelector((state) => state.taskSidebar);
  const { taskCustomVariables, setTaskCustomVariables } = useEditorContext();
  const { addTaskCustomVariables, addWorklowDescriptionCustomVariables } = useTaskVariableUpdates();
  const dispatch = useDispatch();
  const workflowState = useWorkflowContext();
  const location = useLocation();

  const exisitingVariable = useMemo(() => {
    if (smartChipId)
      if (workflowState?.workflow && workflowState?.workflow?.Id) {
        return taskCustomVariables?.find((variable) => variable.workflowDescriptionVariableId == smartChipId);
      } else {
        return taskCustomVariables?.find((variable) => variable.taskVariableId == smartChipId);
      }
  }, [smartChipId, taskCustomVariables]);

  const newVariableDetails = useMemo(() => {
    return variableDetails || exisitingVariable?.workflowVariableDetails;
  }, [variableDetails, exisitingVariable]);

  useEffect(() => {
    if (smartChipId) return setSelectedValue(exisitingVariable?.variableValue);
    setSelectedValue(newVariableDetails?.attributes?.default ? newVariableDetails?.attributes?.default : { label: `Select ${newVariableDetails?.name}`, value: null });
  }, [smartChipId, newVariableDetails?.attributes]);

  const updateNode = async (chosenValue) => {
    const { label, value } = chosenValue;
    setSelectedValue(chosenValue);
    if (smartChipId) {
      const updateChip = document.getElementById(smartChipId);
      updateChip.innerHTML = label;
      setShowEditChip(false);
      const taskCustomVariable = { ...exisitingVariable, variableValue: chosenValue };
      if (location?.pathname?.includes("/workflow")) {
        const newTaskCustomVariables = taskCustomVariables.map((item) => (item.workflowDescriptionVariableId === smartChipId ? { ...taskCustomVariable } : item));
        setTaskCustomVariables([...newTaskCustomVariables]);
        if (workflowState?.workflow?.Id) addWorklowDescriptionCustomVariables([...newTaskCustomVariables], workflowState?.workflow);
        else workflowState?.setworkflowDescriptionVariables([...newTaskCustomVariables]);
      } else {
        const newTaskCustomVariables = taskCustomVariables.map((item) => (item.taskVariableId === smartChipId ? { ...taskCustomVariable } : item));
        setTaskCustomVariables([...newTaskCustomVariables]);
        if (!isNewTask) addTaskCustomVariables([...newTaskCustomVariables]);
        else dispatch(setNewTaskVariables([...newTaskCustomVariables]));
      }
    }

    if (!smartChipId) {
      const id = await generateUniqueId(user.id, "customDropdown", value);
      updateNonEditable(label, id, customVariablesClasses[0]);
      if (location?.pathname?.includes("/workflow")) {
        const taskCustomVariable = {
          workflowDescriptionVariableId: id,
          workflowVariableId: variableDetails?.id,
          workflowVariableDetails: variableDetails,
          variableName: variableDetails?.name,
          variableValue: chosenValue,
          userId: user.id,
        };
        setTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
        if (workflowState?.workflow?.Id) addWorklowDescriptionCustomVariables([...taskCustomVariables, taskCustomVariable], workflowState?.workflow);
        else workflowState?.setworkflowDescriptionVariables([...taskCustomVariables, taskCustomVariable]);
      } else {
        const taskCustomVariable = {
          taskVariableId: id,
          workflowVariableId: variableDetails?.id,
          workflowVariableDetails: variableDetails,
          variableName: variableDetails?.name,
          variableValue: chosenValue,
          userId: user.id,
        };
        setTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
        if (!isNewTask) addTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
        else dispatch(setNewTaskVariables([...taskCustomVariables, taskCustomVariable]));
      }
    }
  };

  return (
    <div style={{ minWidth: "200px" }} className='dt-editor-menu'>
      <div className='w-100' id={`update-editor-custom-dropdown-chip`} ref={parentRef}>
        <div className='d-flex justify-content-between p-2'>
          <div className='font-weight-bold'>{newVariableDetails?.name}</div>
        </div>
        <div>
          <DropDownList
            popupSettings={{ appendTo: parentRef.current }}
            value={selectedValue}
            onChange={(e) => updateNode(e.target.value)}
            data={[{ label: `Select ${newVariableDetails?.name}`, value: null }, ...(newVariableDetails?.attributes?.options || [])]}
            textField='label'
          />
        </div>
      </div>
    </div>
  );
};

export default CustomVariableDropdown;
