import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { label } from "../../../config";

/**
 * adds "Show more" label in the "Detail" panel bar item
 * @author Muskan Thakur
 */
const CustomPanelBarItemContent = ({ title, showTaskDetailPanel }) => {
  const { task } = useSelector((state) => state.taskSidebar);
  
  return (
    <div className='panel-bar-item'>
      <span>{title}</span>
      {title === label.DETAILS && showTaskDetailPanel && task?.taskId ? <span className={`show-more ${showTaskDetailPanel === true ? "text-white" : "text-gray" } `}>{label.EXPAND}</span> : <></>}
    </div>
  );
};

export default CustomPanelBarItemContent;
