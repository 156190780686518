import React, { useState, useEffect, useRef, useMemo } from "react";
import { label, number } from '../../../config';
import { languages } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { getTeamSideBarDetails, updateQueue } from "../../../shared/services/queues.service";
import { convertFormatOfDateTime } from "../../../helper/common";
import { splitString } from "../../../utils";
import { setTeamSidebarDetails } from "../../../actions/teamSidebar";
import KendoEditor from "../../../editor/KendoEditor";
import { useParams } from "react-router";
const language = languages.ENGLISH;
const themeStyle = document.createElement('style');

/*  @author: "Sarthak Arora"
    Parent Component : TeamSideBarContent
    Description : Provides a editable description box in the Board tab of Team Sidebar
*/

const Board = () => {
  const [description, setDescription] = useState("");
  const editRef = useRef(true);
  const viewRef = React.useRef(null);
  const editableRef = useRef(true);
  const editorRef = React.useRef(null);
  const [isEditable, setIsEditable] = useState(false);
  const [queuePrivacy, setQueuePrivacy] = useState({});
  const { queueDetails } = useSelector((state) => state.teamSidebar)
  const allProjects = useSelector((state) => state.allProjects)
  const queueProjects = useSelector((state) => state.queueProjects)
  const [name, setName] = useState("");
  const [previousProject, setPreviousProject] = useState("");
  const [currentProject, setCurrentProject] = useState({});
  const [owners, setOwners] = useState([]);
  const { user } = useSelector((state) => state.auth)
  const { QueueName, Description, QueueId, ProjectId, userId, UserName, Privacy } = queueDetails;
  const isEditableCondition = !Description || Description == description && isEditable;
  const { queueId } = useParams();


  const dispatch = useDispatch();
  const payload = {
    userId: user.id,
    companyId: user.companyId,
    queueId: queueId
  };

  /**
   * updates the queueDetails 
   * @author Sarthak Arora
   */
  useEffect(()=> {
    dispatch(getTeamSideBarDetails(payload))
  },[])

  const setEditableDesc = (editable) => {
    let editor = editRef?.current
    let iFrame = editor?.iframe
    setIsEditable(isEditableCondition ? true : false)
    iFrame?.contentDocument?.querySelector('.k-content')?.setAttribute('contenteditable', editable)
    editableRef.current = isEditableCondition ? true : false;
  }

  useEffect(() => {
    if (isEditableCondition) setEditableDesc(true)
    else setEditableDesc(false)
  }, [isEditable, queueDetails])


  useEffect(() => {
    setQueuePrivacy(Privacy ? allProjects?.queuePrivacy?.find((p) => p.Id == Privacy) : allProjects?.queuePrivacy?.find((p) => p.Id == number.TWO))
  }, [allProjects?.queuePrivacy])

  const authUser = { value: user.id, label: user.name }

  const setOwnersData = () => {
    let ownerIds = [], ownerNames = [];
    let ownersData = []
    if (userId) { ownerIds = splitString(userId, ",") }
    if (UserName) { ownerNames = splitString(UserName, ",") }
    if (ownerIds.length > number.ZERO) {
      for (let i = number.ZERO; i < ownerIds.length; i++) {
        ownersData.push({ value: ownerIds[i], label: ownerNames[i] })
      }
    }
    setOwners(QueueName ? ownersData : [authUser])
  }

  useEffect(() => {
    setName(QueueName ? QueueName : "");
    setPreviousProject(ProjectId);
    setQueuePrivacy(Privacy ? allProjects?.queuePrivacy?.find((p) => p.Id == Privacy) : allProjects?.queuePrivacy?.find((p) => p.Id == number.TWO))
    setCurrentProject(queueProjects.projects.find((project) => project.ProjectId === ProjectId))
    setOwnersData();
  }, [queueDetails, queueProjects?.projects])

  const updateDescription = () => {
    const payload = {
      queueId: QueueId,
      description,
    };
    dispatch(setTeamSidebarDetails({ ...queueDetails, Description: description }))
    dispatch(updateQueue(payload));
  }

  useEffect(() => {
    setDescription(Description ? Description : "");
  }, [Description])


  const getEditorEditIcon = useMemo(() => {
    if (editableRef.current) {
      if (Description === description) return number.ONE
      return number.THREE
    }
    return number.TWO
  }, [editableRef.current, Description, description])

  const toggleEditable = async () => {
    if (isEditable) { updateDescription() }
    setIsEditable(!isEditable);
    editableRef.current = !isEditable;
  }


  return (

    <div className="form-row">
      <div className="form-group col-md-12">
        <div className='desc-popup-editor p-0 flex-grow-1 flex-grow-1' data-testid="descpopup-testid">
          <div className='workflow-desc-editor create-queue-description-box'>

            <KendoEditor
              desc={description}
              setDesc={setDescription}
              viewRef={viewRef}
              editableRef={editableRef}
              toggleEditable={toggleEditable}
              editorRef={editorRef}
              editorEditIcon={getEditorEditIcon}
              showEditIcons={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Board;