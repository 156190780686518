import React, { useEffect, useState } from "react";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { updateCurrUser } from "../../../actions/auth";
import { setContentManagerDataView, setSelectedContentItem } from "../../../actions/contentManager";
import { setShowNotifications } from "../../../actions/notifications";
import { setIsApprovalFilterSelected, setLastFilterId } from "../../../actions/projects";
import { setSelectedView } from "../../../actions/task";
import drutaslogoWhite from "../../../assets/images/drutas-logo-white-d.png";
import { getBookmarks } from "../../../components/Bookmarks/bookmarks.service";
import Chatbot from "../../../components/Chatbot/chatbot";
import { getNotificationsCount } from "../../../components/Notifications/notifications.service";
import ProjectSidebar from "../../../components/ProjectSidebar/ProjectSidebar";
import PlanMyDayActions from "../../../components/TaskSidebar/PlanMyDayActions/PlanMyDayActions";
import TaskSidebar from "../../../components/TaskSidebar/TaskSidebar";
import TeamSideBar from "../../../components/TeamSideBar/TeamSideBar";
import { icon, label, link, quote, route } from "../../../config/";
import { RESPONSIVE_WIDTH, number, tooltip } from "../../../config/constants";
import config from "../../../env.config";
import { getQueryParams, getTimeFromDate } from "../../../helper/common";
import { appendString, dateConvert } from "../../../utils/common";
import { getVersionNo } from "../../services/common.service";
import { loadState } from "../../services/session.service";
import { subStrWithTooltip } from "../../validators/validator";
import SidePannel from "./SidePannel";
import "./sidebar.scss";

const SideBar = ({ sendCollapse }) => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [toggleItems, setToggleItems] = useState({ tasks: false, projects: false, queues: false, comment: false, approval: false, reminder: false });
  const { id, companyId, operationalTeamName, companyPicture, companyDisplayName, IsChatbotEnabled, name, IsDirectoryEnabled } = useSelector((state) => state.auth.user);
  const { showTaskSidebar } = useSelector((state) => state.taskSidebar);
  const { showProjectSidebar } = useSelector((state) => state.projectSidebar);
  const latestSidebar = useSelector((state) => state.latestSidebar);
  const { showQueueSidebar } = useSelector((state) => state.teamSidebar);
  const { commentCount, reminderCount, approvalCount } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const [version, setVersion] = useState();
  const homeRef = React.useRef();
  const projectRef = React.useRef();
  const queueRef = React.useRef();
  let pathname = window.location.pathname;
  const projectMatchPath = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
  const queueMatchPath = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH });
  const queueId = queueMatchPath?.params?.queueId;
  const projectId = projectMatchPath?.params?.projectId;
  const { isPlanMyDayActive } = useSelector((state) => state.planMyDay);
  const { teamSavedFilters } = useSelector((state) => state.teamFilter);

  useEffect(() => {
    const storedVersion = loadState("version").length ? loadState("version") : "";
    if (version !== storedVersion) {
      setVersion(storedVersion);
    }
  });

  useEffect(() => {
    if (queueId) {
      setToggleItems({ home: false, projects: false, queues: true, comment: false, approval: false, reminder: false });
    }
    if (projectId) {
      setToggleItems({ home: false, projects: true, queues: false, comment: false, approval: false, reminder: false });
    }
  }, [queueId, projectId]);

  useEffect(() => {
    (async () => {
      dispatch(getBookmarks(id));
      dispatch(setIsApprovalFilterSelected(false));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      dispatch(getVersionNo());
      setToggleItems({ home: false, projects: false, queues: false, comment: false, approval: false, reminder: false, library: false });
    })();
  }, []);

  window.onresize = function () {
    resizeActions();
  };
  window.onload = function () {
    resizeActions();
  };
  /**
   * Window Resize Actions for responsive sidebar
   */
  const resizeActions = () => {
    if (window.innerWidth <= RESPONSIVE_WIDTH) menuIconClick(true);
    else menuIconClick(false);
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  /**
   * handling the open/close of sidebar
   * @returns {boolean to open/close sidebar}
   */
  const menuIconClick = (menu) => {
    setMenuCollapse(menu);
    sendCollapse(menu);
  };
  const toggleMenu = (item) => {
    dispatch(setIsApprovalFilterSelected(false));
    if (item === "approval") {
      dispatch(setIsApprovalFilterSelected(true));
      const approvalFilter = teamSavedFilters?.find((filter) => filter.label === label.PENDING_APPROVALS);
      dispatch(setLastFilterId(approvalFilter?.value));
      dispatch(updateCurrUser({ mode: number.ZERO }));
    }
    let items = toggleItems;
    dispatch(getNotificationsCount(id, getTimeFromDate(new Date()), dateConvert(new Date())));
    items = Object.fromEntries(Object.keys(items).map((key) => [key, false]));
    items[item] = true;
    setToggleItems(items);
    if (item == "home" || item === "tasks") dispatch(updateCurrUser({ mode: 0 }));
    if (item == "library") {
      dispatch(setSelectedView(label.LIBRARY));
      dispatch(updateCurrUser({ mode: number.TWO }));
      dispatch(setSelectedContentItem([]));
      dispatch(setContentManagerDataView(label.CMS_GRID));
    }
  };

  /**
   * returns html element with tooltip if operationalTeamName is long
   * else returns String
   * @params
   * @author Pragun Gandotra
   */
  const getTeamLabel = () => {
    if (operationalTeamName) return subStrWithTooltip(appendString(operationalTeamName, label.SMALL_S), number.ZERO, number.TEN);
    else return label.TEAM;
  };

  return (
    <>
      <ProSidebar collapsed={menuCollapse} className='vertical-menu position-fixed'>
        <Tooltip anchorElement='target' position='right' parentTitle='true'>
          <SidebarHeader className={menuCollapse ? "menu-collapse" : "menu-expand justify-content-center"}>
            <Link to={IsDirectoryEnabled && route.PRIVATE_ROUTE.USER_DIRECTORY.PATH}>
              <span className='brand-logo  overflow-hidden  text-center mt-2' title={companyDisplayName}>
                {companyPicture ? <img src={`${companyPicture}`} alt='org' className='logo h-auto mb-2'></img> : icon.BUSINESS}
              </span>
            </Link>
            <button className='btn btn-sm px-3 collapse-menu-icon position-absolute' id='button-sidebar-collapse' onClick={() => menuIconClick(!menuCollapse)}>
              <span className='menu-bar-icon'>{icon.MENU}</span>
              <span className='menu-close-icon'>{icon.CLOSE}</span>
            </button>
          </SidebarHeader>
        </Tooltip>
        <div className='h-100 overflow-auto d-flex flex-column sidebar-content overflow-y-auto sidebar-scrollbar'>
          <SidebarContent>
            <Menu>
              <Link to={route.PRIVATE_ROUTE.TASKS.PATH}>
                <MenuItem onClick={() => toggleMenu("home")} ref={homeRef} onMouseOver={() => handleMouseEnter("home")} onMouseLeave={handleMouseLeave} className={toggleItems.home ? "active " : ""}>
                  <span className='tour-sidebar-my-board'>{icon.HOME}</span>
                  {hoveredItem && hoveredItem === "home" && <SidePannel anchor={homeRef} type={hoveredItem} />}
                </MenuItem>
              </Link>

              <Link id='team-sidebar' to={route.PRIVATE_ROUTE.PROJECT.PATH}>
                <MenuItem
                  onClick={() => toggleMenu("projects")}
                  ref={projectRef}
                  onMouseEnter={() => handleMouseEnter("projects")}
                  onMouseLeave={handleMouseLeave}
                  className={toggleItems.projects ? "active" : ""}>
                  {icon.DVR}
                  {hoveredItem && hoveredItem === "projects" && <SidePannel anchor={projectRef} type={hoveredItem} />}
                </MenuItem>
              </Link>

              <Link id='queue-sidebar' to={route.PRIVATE_ROUTE.QUEUES.PATH}>
                <MenuItem
                  onClick={() => toggleMenu("queues")}
                  ref={queueRef}
                  onMouseEnter={() => handleMouseEnter("queues")}
                  onMouseLeave={handleMouseLeave}
                  className={toggleItems.queues ? "active" : ""}>
                  {icon.GROUPS}
                  {hoveredItem && hoveredItem === "queues" && <SidePannel anchor={queueRef} type={hoveredItem} />}
                </MenuItem>
              </Link>

              <Tooltip anchorElement='target' parentTitle={true} position='right'>
                <Link id='library-sidebar' to={`${route.PRIVATE_ROUTE.TASKS.PATH}`}>
                  <MenuItem onClick={() => toggleMenu("library")} className={toggleItems.library ? "active " : ""}>
                    <div title={label.LIBRARY} className='notifications-action-item d-flex justify-content-center align-items-center cursor-pointer'>
                      {icon.LIBRARY}
                    </div>
                  </MenuItem>
                </Link>
              </Tooltip>

              <Tooltip anchorElement='target' parentTitle={true} position='right'>
                <Link id='comment-sidebar' to={`${route.PRIVATE_ROUTE.COMMENT.PATH}`}>
                  <MenuItem onClick={() => toggleMenu("comment")} className={toggleItems.comment ? "active " : ""}>
                    <div title={commentCount > number.ZERO ? quote.NEW_COMMENTS : label.COMMENTS} className='notifications-action-item d-flex justify-content-center align-items-center cursor-pointer'>
                      {icon.CHAT}
                      {commentCount > 0 && <span className='notification-dot'></span>}
                    </div>
                  </MenuItem>
                </Link>
              </Tooltip>

              <Tooltip anchorElement='target' parentTitle={true} position='right'>
                <Link id='reminder-sidebar' to={`${route.PRIVATE_ROUTE.REMINDER.PATH}`}>
                  <MenuItem onClick={() => toggleMenu("reminder")} className={toggleItems.reminder ? "active " : ""}>
                    <div
                      title={reminderCount > number.ZERO ? tooltip.REMINDER_DUE : label.REMINDERS}
                      className='notifications-action-item d-flex justify-content-center align-items-center cursor-pointer'>
                      {icon.BELL_ICON}
                      {reminderCount > 0 && <span className='notification-dot'></span>}
                    </div>
                  </MenuItem>
                </Link>
              </Tooltip>

              {approvalCount > number.ZERO && (
                <Tooltip anchorElement='target' parentTitle={true} position='right'>
                  <Link id='approval-sidebar' to={`${route.PRIVATE_ROUTE.TASKS.PATH}`}>
                    <MenuItem onClick={() => toggleMenu("approval")} className={toggleItems.approval ? "active " : ""}>
                      <div title={label.APPROVALS} className='notifications-action-item d-flex justify-content-center align-items-center cursor-pointer'>
                        {icon.APPROVAL_ICON}
                        {approvalCount > 0 && <span className='notification-dot'></span>}
                      </div>
                    </MenuItem>
                  </Link>
                </Tooltip>
              )}
            </Menu>
          </SidebarContent>
        </div>
        <SidebarFooter>
          <div className='text-center mb-3'></div>
          {!IsChatbotEnabled && (
            <div className='mb-2'>
              <a href={link.HELP_SUPPORT} className='head-set text-center' target='_blank' rel='noopener noreferrer'>
                <Tooltip anchorElement='target' parentTitle={true} position='right'>
                  <span>{icon.HEADSET}</span>
                </Tooltip>
              </a>
            </div>
          )}
          <div className='text-center'>
            <a className='help-text small' href={link.VERSION_URL} target='_blank' rel='noreferrer'>
              {quote.VERSION} {version}
            </a>
          </div>
          <div>{!!IsChatbotEnabled && <Chatbot userId={id} organizationId={companyId} baseUrl={config.BASE_URL.BASE_API_URL} userName={name} />}</div>
        </SidebarFooter>
      </ProSidebar>
      <div className={`task-slide-left position-fixed p-0 right-0  ${showTaskSidebar ? "page-slide-show" : ""} ${latestSidebar.tasks ? "top-sidebar" : ""}`}>{showTaskSidebar && <TaskSidebar />}</div>
      <div className={`task-slide-left position-fixed p-0 right-0  ${showProjectSidebar ? "page-slide-show" : ""} ${latestSidebar.projects ? "top-sidebar" : ""}`}>
        {showProjectSidebar && <ProjectSidebar />}
      </div>
      <div className={`task-slide-left position-fixed p-0 right-0 pt-2  ${showQueueSidebar ? "page-slide-show" : ""} ${latestSidebar?.teams ? "top-sidebar" : ""}`}>
        {showQueueSidebar && <TeamSideBar />}
      </div>
      {isPlanMyDayActive && <PlanMyDayActions />}
    </>
  );
};
export default SideBar;