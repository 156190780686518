import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ySyncPluginKey } from "y-prosemirror";
import * as Y from "yjs";
import { icon, label, notifyIcon, number, placeholder, tooltip } from "../../../../config";
import { getNotification } from "../../../../utils/common";
import { formatUserTimestamp } from "../../sidebar.common";

const DescriptionHistory = ({ yjsDocRef, viewRef, setDesc }) => {
  const anchorTag = useRef();
  const [version, setVersion] = useState({ label: label.CURRENT_VERSION, isCurrent: true });
  const [versionList, setVersionList] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [snapshotMode, setSnapshotMode] = useState(false);

  /**
   * Add Current Y-Doc version as a snapshot
   */
  const addVersion = () => {
    const ydoc = yjsDocRef.current;
    const versions = ydoc.getArray("versions");
    const prevVersion = versions.length === 0 ? null : versions.get(versions.length - 1);
    const prevSnapshot = prevVersion === null ? Y.emptySnapshot : Y.decodeSnapshot(prevVersion.snapshot);
    const snapshot = Y.snapshot(ydoc);
    if (prevVersion != null) {
      // account for the action of adding a version to ydoc
      const prevVersionID = prevSnapshot.sv.get(prevVersion.clientID);
      prevSnapshot.sv.set(prevVersion.clientID, prevVersionID + 1);
    }
    if (!Y.equalSnapshots(prevSnapshot, snapshot)) {
      const versionDate = new Date();
      versions.push([
        {
          date: versionDate.getTime(),
          snapshot: Y.encodeSnapshot(snapshot),
          clientID: ydoc.clientID,
          user: user.name,
          label: null,
        },
      ]);
      setVersionList([...versions.toArray()].reverse());
      getNotification(label.ADD_VERSION_MSG, notifyIcon.SUCCESS_ICON);
    }
  };

  /**
   * Render Snapshot as a view to the editor
   * @param {Y.Snapshot} version
   */
  const renderVersion = (version) => {
    const editorView = viewRef.current;
    const ydoc = yjsDocRef.current;
    try {
      const snapshot = Y.decodeSnapshot(version.snapshot);
      const yXmlFragment = ydoc.getXmlFragment("prosemirror");
      if (snapshot && yXmlFragment) {
        const versionsArray = ydoc.getArray("versions");
        const lastVersion = versionsArray.length ? Y.decodeSnapshot(versionsArray.get(versionsArray.length - 1).snapshot) : Y.emptySnapshot;
        // Update editor state with the selected snapshot
        const binding = ySyncPluginKey.getState(editorView.state).binding;
        if (binding) {
          binding.renderSnapshot(snapshot, lastVersion);
        }
      }
    } catch (error) {
      getNotification(error?.message || error, notifyIcon.ERROR_ICON);
    }
  };

  /**
   * Reflect to the current editable version.
   */
  const unrenderVersion = () => {
    const editorView = viewRef.current;
    const binding = ySyncPluginKey.getState(editorView.state).binding;
    if (binding != null) {
      binding.unrenderSnapshot();
    }
    setVersion({ label: label.CURRENT_VERSION, isCurrent: true });
    setSnapshotMode(false);
  };

  /**
   * Handle version list change event
   * @param {Event} e
   */
  const handleListChange = (e) => {
    const selectedItem = e.value; // Access the selected item
    if (selectedItem.isCurrent) {
      // Logic for "Current Version"
      unrenderVersion();
    } else {
      // Logic for other versions
      const selectedIndex = e.target.index;
      if (selectedIndex >= 0) {
        setSnapshotMode(true);
        const selectedVersion = versionList[selectedIndex - number.ONE]; // Adjust index to skip the placeholder
        setInputValue(prepareVersionObj(selectedVersion).label);
        setVersion(prepareVersionObj(selectedVersion));
        renderVersion(prepareVersionObj(selectedVersion));
      }
    }
    setIsEditing(false);
  };

  /**
   * Handle Droplist Label view
   * @param {Object} v
   * @returns
   */
  const prepareVersionObj = (v) => {
    const yDoc = yjsDocRef.current;
    return {
      ...v,
      clientID: v?.clientID ?? yDoc.clientID,
      date: new Date(v.date).toLocaleString(),
      user: v?.user ?? user.name,
      label: v?.label ? v.label : formatUserTimestamp(v.user ? v.user : user.name, v.date),
    };
  };

  /**
   * Fetches the list of snapshots (versions) from the Y.Doc and updates the state.
   */
  const fetchSnapshots = () => {
    const ydoc = yjsDocRef.current;
    const versions = ydoc.getArray("versions");
    setVersionList([...versions.toArray()].reverse());
  };

  const handleVersionList = () => {
    setIsEditing(false);
    setDropdownOpen(true);
    fetchSnapshots();
  };

  /**
   * Filters the list of snapshots based on the provided filter criteria.
   * @param {Event} e - The event triggered by the filter input (e.g., user typing or selecting a filter).
   */
  const filterSnapshots = (e) => {
    setVersionList(
      filterBy(
        versionList.map((v) => prepareVersionObj(v)),
        e.filter
      )
    );
  };

  /**
   * Restore a particular snapshot to the editor for editing.
   */
  const restoreVersion = async () => {
    try {
      await addVersion();
      const editorContent = viewRef?.current?.dom?.innerHTML;
      unrenderVersion();
      viewRef.current.dom.innerHTML = editorContent;
      setDesc(editorContent);
      getNotification(label.VERSION_RESTORED_MSG, notifyIcon.SUCCESS_ICON);
    } catch (error) {
      getNotification(error?.message || error, notifyIcon.ERROR_ICON);
    }
  };

  /**
   * Rename version name
   */
  const updateVersionName = () => {
    const ydoc = yjsDocRef.current;
    const versions = ydoc.getArray("versions");
    const versionsArr = versions.toArray();
    const index = versionsArr.findIndex((v) => new Date(v.date).toLocaleString() === new Date(version.date).toLocaleString());
    if (index > -number.ONE && index < versions.length && inputValue.trim()) {
      const updatedVersion = { ...versions.get(index), label: inputValue.trim() };
      // Update the Y.js array
      versions.delete(index, number.ONE);
      versions.insert(index, [updatedVersion]);
      setVersionList([...versions.toArray()]);
      setIsEditing(false);
      getNotification(label.UPDATE_VERSION_NAME_MSG, notifyIcon.SUCCESS_ICON);
    } else {
      setIsEditing(false);
    }
    unrenderVersion();
  };

  // Custom Render Kendo-Dropdown List (Version Label + Edit icon)
  const itemRender = (li, itemProps) => {
    const { dataItem } = itemProps;

    return React.cloneElement(li, { ...li.props, className: "position-relative px-3 py-2 history-list-icon cursor-pointer " }, [
      <Tooltip parentTitle={true} position='bottom'>
        <div key={dataItem.label} className='item-label text-truncate pr-4 w-100 d-block' title={dataItem.label}>
          {dataItem.label}
        </div>
      </Tooltip>,
      !dataItem.isCurrent && (
        <Tooltip parentTitle={true} position='bottom'>
          <div
            className='position-absolute edit-history-icon'
            title={tooltip.EDIT_VERSION_NAME}
            onClick={(e) => {
              e.stopPropagation();
              setDropdownOpen(false);
              setVersion(dataItem);
              setInputValue(prepareVersionObj(dataItem).label);
              setIsEditing(true);
            }}>
            <span>{icon.EDIT_ICON}</span>
          </div>
        </Tooltip>
      ),
    ]);
  };

  return (
    <div className='d-flex' ref={anchorTag}>
      <DropDownList
        id='snapshot-history'
        data={[
          { label: label.CURRENT_VERSION, isCurrent: true }, // Add "Current Version" at the top
          ...versionList.map((v) => prepareVersionObj(v)),
        ]}
        value={version}
        textField='label'
        onChange={(e) => handleListChange(e)}
        itemRender={itemRender} // Use custom render
        filterable={true}
        onFilterChange={(e) => filterSnapshots(e)}
        onOpen={() => handleVersionList()}
        onClose={() => setDropdownOpen(false)}
        opened={dropdownOpen}
        className='width-200 height-30'
      />
      {isEditing && anchorTag && (
        <Popup anchor={anchorTag.current} show={isEditing} position='bottom' collision={{ vertical: "fit", horizontal: "fit" }}>
          <div className='popup-content'>
            <h6>{tooltip.EDIT_VERSION_NAME}</h6>
            <div className='d-flex align-items-center'>
              <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder={placeholder.WRITE_SOMETHING} />
              <span
                className='ml-3 mr-2 text-green'
                onClick={(e) => {
                  e.preventDefault();
                  updateVersionName();
                }}>
                {icon.DONE}
              </span>
              <span
                onClick={() => {
                  setIsEditing(false);
                  unrenderVersion();
                }}>
                {icon.CLEAR}
              </span>
            </div>
          </div>
        </Popup>
      )}
      <Tooltip parentTitle={true} position='bottom'>
        <div className='d-flex'>
          {!snapshotMode && (
            <div className='font-weight-semi-bold ml-3  cursor-pointer' title={label.SAVE_VERSION} onClick={addVersion}>
              <div className='d-flex align-items-center'>
                <span className='editor-tool-down-caret'>{icon.SAVE_SNAPSHOT}</span>
              </div>
            </div>
          )}
          {snapshotMode && (
            <div className={`ml-3 font-weight-semi-bold ${!version?.isCurrent ? "cursor-pointer" : "disabled pointer-events-none"}`} title={label.RESTORE} onClick={restoreVersion}>
              <div className='d-flex align-items-center'>
                <span className='editor-tool-down-caret'>{icon.RESTORE_SNAPSHOT}</span>
              </div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default DescriptionHistory;
