import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SplitButton } from "@progress/kendo-react-buttons";
import { setContentManagerData, setSelectedContentItem, setUploadContentManagerFiles } from "../../../../actions/contentManager";
import { addFolder, searchTreeItem } from "../../../../components/ContentManagementSystem/helper";
import { button, icon, label, notifyIcon, quote } from "../../../../config";
import { getNotification } from "../../../../utils/common";
import { getContentAttachmentsCount } from "../../../services/contentManger.services";

/**
 * MultiUploadDropdown component to handle multiple file management actions
 * such as uploading files, adding folders, tasks, and reminders.
 *
 * @returns {JSX.Element} The rendered component.
 * @author Muskan Thakur
 */
const MultiUploadDropdown = ({ handleAddTask, handleAddReminder, popupSettings }) => {
  const { selectedContentManagerItem, contentManagerData } = useSelector((state) => state.contentManager);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /**
   * Creates the click handler function for each dropdown item.
   */
  const handleClick = (handler) => (e) => {
    e.preventDefault();
    if (handler) {
      handler();
    }
  };

  /**
   * Handles the logic to add a folder, either inside a selected folder or at the root level.
   */
  const handleAddFolder = async () => {
    const folderCounter = await getContentAttachmentsCount({ companyId: user.companyId });
    let newData;
    if (!selectedContentManagerItem?.id) {
      return getNotification(quote.SELECT_FOLDER, notifyIcon.WARNING_ICON);
    }
    if (selectedContentManagerItem) {
      newData = await addFolder(contentManagerData, selectedContentManagerItem, folderCounter);
      const newTreeItem = searchTreeItem(newData, selectedContentManagerItem);
      dispatch(setSelectedContentItem(newTreeItem));
    } else {
      newData = await addFolder(contentManagerData, null, folderCounter);
    }

    dispatch(setContentManagerData(newData));
  };

  /**
   * Handles the upload action, dispatching to trigger the file upload modal.
   */
  const handleUpload = () => {
    if (!selectedContentManagerItem?.id) {
      return getNotification(quote.SELECT_FOLDER, notifyIcon.WARNING_ICON);
    } else dispatch(setUploadContentManagerFiles(true));
  };

  const items = useMemo(
    () => [
      {
        text: button.ADD_FOLDER,
        icon: icon.FOLDER,
        onClick: handleClick(handleAddFolder),
      },
      {
        text: label.ADD_TASK,
        icon: icon.ADD_TASK,
        onClick: handleClick(handleAddTask),
      },
      {
        text: label.ADD_REM,
        icon: icon.WORKFLOW_9,
        onClick: handleClick(handleAddReminder),
      },
    ],
    [handleAddTask, handleAddReminder, handleUpload, handleAddFolder]
  );

  return (
    <SplitButton
      className='btn-width btn btn-primary mr-3'
      popupSettings={popupSettings}
      text={
        <span id='header-multi-add-button-cms' title={label.UPLOAD_FILE}>
          <span>{icon.FILE_UPLOAD}</span> {label.UPLOAD_FILE}
        </span>
      }
      items={items.map((item) => ({
        ...item,
        render: () => (
          <div className='d-flex align-items-center' onClick={item.onClick}>
            {item.icon}
            <span className='ml-2'>{item.text}</span>
          </div>
        ),
      }))}
      onButtonClick={handleUpload}
    />
  );
};

export default MultiUploadDropdown;
