import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { updateCurrUser } from "../../../../actions/auth";
import { closeProjectSidebar, toggleProjectSidebar } from "../../../../actions/projectSidebar";
import { setShowTeamSidebar } from "../../../../actions/teamSidebar";
import { resetTourToggle, setRun } from "../../../../actions/tour";
import userDummy from "../../../../assets/images/dummy-user.jpg";
import googleSSO from "../../../../assets/images/google-SSO.png";
import { inviteUsers, updateUserLoginType } from "../../../../components/Admin/admin.service";
import { PROJECT_TYPE } from "../../../../components/ProjectSidebar/projectSidebar.constants";
import { handleCloseSidebar } from "../../../../components/Tasks/tasks.common";
import { icon, label, notifyIcon, quote, route } from "../../../../config";
import { button, text } from "../../../../config/constants";
import { checkTargetFile, isTrueBit } from "../../../../utils";
import { getNotification } from "../../../../utils/common";
import { resetPassword } from "../../../services/auth.service";
import { loadState } from "../../../services/session.service";
import ChangePassword from "../ChangePassword/ChangePassword";
import { UserProfileContext } from "./UserProfile.context";
import "./userProfile.scss";

/**
 * LeftProfilePanel component is responsible for setting up an user profile, restart tour and to select login types.
 * parent component: UserProfileForm
 * @author Bhavana
 */
const LeftProfilePanel = () => {
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const taskSidebar = useSelector((state) => state.taskSidebar);
  const { IsGoogleSignUp, IsManualSignUp } = user;
  const { selectedImage, setSelectedImage, googleSSOValues, setGoogleSSOValues, userIdValues, setUserIdValues, setSelectedImageFolder, setProfileData } = useContext(UserProfileContext);
  const history = useHistory();

  const taskSidebarData = taskSidebar?.isNewTask;

  /**
   * Initialitizes the dropdown values of login-types when the component is rendered
   * @author Bhavana
   */
  useEffect(() => {
    initializeDropdownValues();
  }, [IsGoogleSignUp, IsManualSignUp]);

  useEffect(() => {
    setIsGoogleUser(loadState(label.GOOGLE_USER));
  }, [user.id]);

  /**
   * Initialitizes the dropdown values of login-types
   * @author Bhavana
   */
  const initializeDropdownValues = () => {
    const googleSSOValues = isTrueBit(user, "IsGoogleSignUp") ? [label.DEACTIVATE, label.RESEND_INVITE] : [label.ACTIVATE, label.RESEND_INVITE];
    const userIdValues = isTrueBit(user, "IsManualSignUp") ? [label.DEACTIVATE, label.RESET_PASSWORD] : [label.ACTIVATE ];
    setGoogleSSOValues(googleSSOValues);
    setUserIdValues(userIdValues);
  };

  /**
   * Handles the input of a user profile picture, and updates the selectedImage state with the file URL.
   * @author Bhavana
   */
  const handleFileInput = (event) => {
    const targetFile = checkTargetFile(event);
    if (targetFile) {
      const fileURL = URL.createObjectURL(targetFile);
      setSelectedImage(fileURL);
      setSelectedImageFolder(targetFile);
      setProfileData((prevState) => ({
        ...prevState,
        PhotoLink: fileURL,
      }));
    } else {
      inputFileRef.current.value = "";
      setSelectedImage("");
    }
  };

  /**
   * Navigate the page to my-board and closes all the sidebar
   * @author Bhavana
   */
  const handleRestartTour = async () => {
    history.push(route.PRIVATE_ROUTE.TASKS.PATH);
    await dispatch(toggleProjectSidebar(PROJECT_TYPE.PERSONAL_PROJECT, false));
    await handleCloseSidebar(dispatch, taskSidebarData);
    await dispatch(setShowTeamSidebar({ showQueueSidebar: false }));
    await closeProjectSidebar();
    await new Promise((resolve) => setTimeout(resolve, 3000));
    await restartTour();
  };

  /**
   * Handles restarting the tour by navigating to the tasks route and dispatching necessary actions to start tour
   * @author Bhavana
   */
  const restartTour = async () => {
    await dispatch(resetTourToggle(true));
    await dispatch(setRun(true));
  };

  /**
   * remove image from the input filed
   * @author Bhavana
   */
  const handleRemoveImage = (event) => {
    event.preventDefault();
    inputFileRef.current.value = "";
    setSelectedImage("");
  };

  /**
   * handles the change of action in drop-down of login-types
   *  @author Bhavana
   */
  /**
   * Handles actions from the login dropdown menu.
   * @param {Object} event - The event object from the dropdown.
   * @param {Object} event.target - The target of the event.
   * @param {string} event.target.value - The selected value from the dropdown.
   */
  const handleLoginDropdown = async (event) => {
    const eventVal = event.target.value;
    const field = event.target.element.id;

    if (eventVal === label.ACTIVATE || eventVal === label.DEACTIVATE) {
      await handleLoginTypeChange(field, eventVal);
    } else if (eventVal === label.RESEND_INVITE) {
      inviteUsers([user?.email], user, "resend", user?.id);
    } else if (eventVal === label.RESET_PASSWORD) {
      dispatch(resetPassword({ userEmailId: user?.email, companyId: user?.companyId }, history));
    }
  };

  /**
   * change state bool true to open modal popup
   */
  const openModal = () => {
    setShowPassword(true);
  };

  /**
   * change state bool false to close modal popup
   */
  const hideModal = () => {
    setShowPassword(false);
  };
  /**
   * Handles the change of login type (activation or deactivation).
   * @param {string} field - The id of the dropdown field.
   * @param {string} eventVal - The selected value (ACTIVATE or DEACTIVATE).
   */
  const handleLoginTypeChange = async (field, eventVal) => {
    const googleSSO = field === "leftprofilepanel-google-sso-dropdown";
    const activateLoginType = eventVal === label.ACTIVATE;

    if (eventVal === label.DEACTIVATE) {
      if (!canDeactivateLoginType(googleSSO)) {
        getNotification(quote.LOGIN_TYPES_DISABLE_ERROR, notifyIcon.WARNING_ICON);
        return;
      }
    }

    const userId = user?.id;
    const loginEmailData = {
      userName: user?.name,
      userEmail: user?.email,
    };

    const { updatedLoginType } = await updateUserLoginType({
      userId,
      googleSSO,
      activate: activateLoginType,
      adminId: userId,
      loginEmailData,
    });

    if (updatedLoginType) {
      await updateUserLoginState(googleSSO, updatedLoginType);
    }
  };

  /**
   * Checks if the login type can be deactivated.
   * @param {boolean} googleSSO - Whether the check is for Google SSO.
   * @returns {boolean} Whether the login type can be deactivated.
   */
  const canDeactivateLoginType = (googleSSO) => {
    return (googleSSO && isTrueBit(user, "IsManualSignUp")) || (!googleSSO && isTrueBit(user, "IsGoogleSignUp"));
  };

  /**
   * Updates the user's login state in the store.
   * @param {boolean} googleSSO - Whether the update is for Google SSO.
   * @param {boolean} updatedLoginType - The new login type state.
   */
  const updateUserLoginState = async (googleSSO, updatedLoginType) => {
    if (googleSSO) {
      await dispatch(updateCurrUser({ IsGoogleSignUp: updatedLoginType }));
    } else {
      await dispatch(updateCurrUser({ IsManualSignUp: updatedLoginType }));
    }
  };

  return (
    <div className='col-md-5 text-center'>
      <div className='user-profile-avatar-upload position-relative mx-auto'>
        <div>{isTrueBit(user, "IsGoogleSignUp") && <img src={googleSSO} title={label.GOOGLE_SSO_SIGN_IN} alt='user' className='google-sso-img rounded-circle'></img>}</div>
        <div>
          <img src={selectedImage || userDummy} alt='user' className='profile-user-avatar border-style rounded-circle light-grey mx-auto d-block' />
          <input hidden type='file' onChange={handleFileInput} ref={inputFileRef} id='leftprofilepanel-upload-image' />
        </div>
        <label htmlFor='leftprofilepanel-upload-image' className='w-100 cursor-pointer custom-upload-label d-flex justify-content-center mt-2'>
          <span className='d-flex align-items-center'>{label.UPLOAD_AN_IMAGE}</span>
        </label>
        <button id='leftprofilepanel-remove-photo' className='bg-transparent border-0 text-lightblue mt-2' onClick={handleRemoveImage}>
          {label.REMOVE_PHOTO}
        </button>
        <div className='form-group d-flex mt-3'>
          <div className='cursor-pointer' id='leftprofilepanel-restart-tour-icon' onClick={handleRestartTour}>
            {icon.QUICK_TOUR}
          </div>
          <label className='label mt-1 ml-2 cursor-pointer text-lightblue' id='leftprofilepanel-restart-tour-text' onClick={handleRestartTour}>
            {label.TAKE_TOUR}
          </label>
        </div>
        <div className='form-group text-left mt-3'>
          <label>{label.LOGIN_TYPE}</label>
          <DropDownList
            className={isTrueBit(user, "IsGoogleSignUp") ? "w-100 green-border" : "w-100 border-none"}
            data={googleSSOValues}
            value={text.SSO_LOGIN}
            onChange={handleLoginDropdown}
            id='leftprofilepanel-google-sso-dropdown'
          />
        </div>
        <div className='form-group text-left'>
          <DropDownList
            className={isTrueBit(user, "IsManualSignUp") ? "w-100 green-border" : "w-100 border-none"}
            data={userIdValues}
            id='leftprofilepanel-id-dropdown'
            value={text.USER_ID}
            onChange={handleLoginDropdown}
          />
          {isGoogleUser && isTrueBit(user, "IsManualSignUp") && (
            <button
              id='user-dropdown-change-password-on-click'
              className='form-group w-100 mt-3 p-2 change-password'
              onClick={(e) => {
                e.preventDefault();
                openModal("showPassword");
              }}>
              {button.CHANGE_PASSWORD}
            </button>
          )}
        </div>
      </div>
      <div>
        {showPassword && (
          <ChangePassword
            show={showPassword}
            hideModal={() => {
              hideModal("showPassword");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LeftProfilePanel;
