import * as React from "react";
import { EditorView } from "@progress/kendo-editor-common";
import { icon } from "../../config";
import { addBookmark } from "./bookmarkUtils";

/**
 * Custom Bookmark Tool for KendoEditor
 * @param {EditorView} props
 * @returns JSX Bookmark icon
 */
const CustomBookmark = (props) => {
  const { view } = props;

  const handleAddBookmark = (view) => {
    addBookmark(view);
  };

  return (
    <button className='custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button'>
      <span onClick={() => handleAddBookmark(view)} className='Bookmark-Tool' title='Add Bookmark'>
        {icon.BOOKMARK_TOOL}
      </span>
    </button>
  );
};

export default CustomBookmark;
