import React from "react";
import { useSelector } from "react-redux";
import { Breadcrumb } from "@progress/kendo-react-layout";
import { number } from "../../../config";
import { convertToBreadcrumbData } from "../helper";

/**
 * Parent Component: FileManagerContent
 *A wrapper around the Kendo React `Breadcrumb` component to display a navigable breadcrumb structure.
 * Allows users to select or navigate through breadcrumb items
 * @author Muskan Thakur
 */
export const BreadcrumbComponent = React.memo((props) => {
  const { projectName } = useSelector((state) => state.contentManager);
  /** Handles the selection of a breadcrumb item. Triggers the parent callback with the event details. */
  const handleItemSelect = (event) => {
    props.handleBreadcrumbSelection(event);
  };

  /**Handles keyboard navigation for the breadcrumb items. Triggers the parent callback with the event details. */
  const handleKeyDown = (event) => {
    props.handleBreadcrumbSelection(event);
  };

  const breadcrumbData = convertToBreadcrumbData(props.data, projectName);

  /**
   * generates breadcrumb data for display.
   * if the input data contains more than five items, "..." is shown in place of remaining folder names
   */
  const displayBreadcrumbData = breadcrumbData.length > number.FIVE ? [...breadcrumbData.slice(-number.THREE), { name: "..." }] : breadcrumbData;

  return (
    <Breadcrumb
      data={displayBreadcrumbData ? displayBreadcrumbData : []}
      textField={"name"}
      onItemSelect={handleItemSelect}
      onKeyDown={handleKeyDown}
      style={{ margin: 0, padding: "5px" }}
      className='btn'
    />
  );
});
