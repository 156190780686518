import React from "react";
import { useSelector } from "react-redux";
import { filterBy, process } from "@progress/kendo-data-query";
import { setGroupIds } from "@progress/kendo-react-data-tools";
import { EditorTools, ProseMirror } from "@progress/kendo-react-editor";
import { icon, notifyIcon, number, quote, tooltip } from "../config";
import { getNotification, splitString } from "./common";

const { Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight, AlignJustify, OrderedList, UnorderedList, Link, Unlink } = EditorTools;

export const initialDataState = {
  take: 100000000000,
  skip: 0,
  group: [],
};

export const processWithGroups = (data, dataState) => {
  const newDataState = process(data ? data : [], dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

export const GridContext = React.createContext({
  reorder: (dataItem) => {},
  dragStart: (dataItem) => {},
});

export const reordering = (dataItem, activeItem, gridData) => {
  if (activeItem === dataItem) {
    return;
  }
  let reorderedData = gridData?.slice();
  let prevIndex = reorderedData?.findIndex((p) => p === activeItem);
  let nextIndex = reorderedData?.findIndex((p) => p === dataItem);
  reorderedData?.splice(prevIndex, number.ONE);
  reorderedData?.splice(nextIndex, number.ZERO, activeItem || reorderedData[number.ZERO]);
  return reorderedData;
};

export const sortable = {
  allowUnsort: false,
  mode: "single",
};

export const onSortChange = (e, setSort) => {
  setSort(e.sort);
};

export const gridStyle = {
  height: "550px",
};

export const DragRow = (props) => {
  const currentContext = React.useContext(GridContext);
  return (
    <span
      onDragOver={(e) => {
        currentContext.reorder(props.dataItem);
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      }}>
      <span
        draggable={true}
        title={tooltip.MOVE}
        className='cursor-move drag-row'
        onDragStart={(e) => {
          currentContext.dragStart(props.dataItem);
          e.dataTransfer.setData("dragging", "");
        }}>
        {icon.DRAG_INDICATOR}
      </span>
    </span>
  );
};

/**
 * handles drag and drop(onDragEnd)
 */
export const DragRowWithDragEnd = (props) => {
  const currentContext = React.useContext(GridContext);
  const { flowElements, task } = useSelector((state) => state.taskSidebar);
  /**displays a notification if there are identical IDs coexisting, otherwise it performs sorting*/
  const handleDragEnd = (e) => {
    currentContext.setActiveItem(null);
    if (checkConsecutiveSameIds(props.dataItem)) {
      getNotification(quote.CAN_NOT_COEXIST, notifyIcon.WARNING_ICON);
      currentContext.handleSortChange({ sort: [{ field: "sortId", dir: "asc" }] });
    } else currentContext.onDrop(e);
  };

  /**checks if there are any consecutive elements with the same value in orderedProjectIds */
  const checkConsecutiveSameIds = () => {
    if (currentContext?.orderedFlowElements) {
      const { orderedFlowElements } = currentContext;
      const isInvalid = orderedFlowElements.some((flow, index) => {
        const nextElement = orderedFlowElements[index + number.ONE];
        if (nextElement) {
          return (
            flow.assignmentProjectId === nextElement.assignmentProjectId &&
            flow.workflowAssignmentId == nextElement.workflowAssignmentId &&
            flow.workflowAssignmentType === nextElement.workflowAssignmentType
          );
        }
      });
      return isInvalid;
    }
    return false;
  };

  return props?.noIdenticalElemntsCoexist && currentContext?.taskElement && (props.dataItem.isCompleted || task.isFollowed || flowElements?.filter((fe) => !fe.isCompleted)?.length == number.ONE) ? (
    <></>
  ) : (
    <span
      className='drag-icon'
      onDragOver={(e) => {
        if (e.dataTransfer.types.includes("dragging")) {
          currentContext.reorder(props.dataItem);
          e.preventDefault();
          e.dataTransfer.dropEffect = "move";
          props.noIdenticalElemntsCoexist && currentContext.setTaskFlows(e);
        }
      }}
      onDragEnd={
        props.noIdenticalElemntsCoexist
          ? handleDragEnd
          : (e) => {
              currentContext.setActiveItem(null);
              currentContext.onDrop(e);
            }
      }>
      <span
        draggable={true}
        className='cursor-move'
        onDragStart={(e) => {
          currentContext.dragStart(props.dataItem);
          e.dataTransfer.setData("dragging", "");
        }}>
        <span className={props.dataItem.pinned && "pinned-grid-drag-icon hide"}>{icon.DRAG_INDICATOR}</span>
      </span>
    </span>
  );
};

export const onOpen = (contentRef) => {
  contentRef && contentRef.current?.focus();
};

/**
 *  handles outSide Click
 * @returns {void}
 */

export const onFocus = (blurTimeoutRef) => {
  clearTimeout(blurTimeoutRef.current);
};

export const onBlur = (blurTimeoutRef, onClick) => {
  clearTimeout(blurTimeoutRef.current);
  blurTimeoutRef.current = setTimeout(onClick, number.TWO_HUNDRED);
};

/**
 * Filter the list based on the word searched
 * @param {String} filter
 * @param {Array} list
 * @returns {Array} filtered list
 * @author Prachi Jain
 */
export const filterData = (filter, list) => {
  return filterBy(list, filter);
};

/**
 * custom tooltip template for comments & reply reactions
 * @param {Object} props
 * @returns {JSX}
 */
export const UserListTooltip = (props) => {
  const userList = splitString(props?.title, ",");
  return (
    <>
      {userList.map((name, idx) => {
        return (
          <span key={idx} className='d-block'>
            {name}
          </span>
        );
      })}
    </>
  );
};

/**
 * put focus at the end of the selected text
 * @param {*} anchor
 * @param {*} head
 * @param {*} view
 */

export const setEditorTextSelection = (anchor, head, view) => {
  const doc = view.state.doc;
  const selection = ProseMirror.TextSelection.create(doc, anchor, head);
  const transaction = view.state.tr;
  transaction.setSelection(selection);
  view.dispatch(transaction);
  view.focus();
};

export const streamEditorTools = [
  [Bold, Italic, Underline],
  [AlignLeft, AlignCenter, AlignRight, AlignJustify],
  [OrderedList, UnorderedList],
  [Link, Unlink],
];

//  detect the end of the text in editor
export const detectLastLineinKendoEditor = (editor, setEditorTextSelection) => {
  const view = editor?.current && editor?.current?.view;
  view && setEditorTextSelection(view.state.doc.content.size, view.state.doc.content.size, view);
};

export const descriptionStyles = `
    .k-content > p {
        margin: 0 0 0.0001pt !important;
        line-height: 1.3
    }
    ol {
        padding-inline-start: 20px;
    }
    ul {
        margin-block-start: 5px;
        margin-block-end: 5px;
        padding-inline-start: 20px;
    }
    p {
        margin-block-start: 5px;
        margin-block-end: 5px;
        margin: 0 0 0.0001pt !important;
        font-family: "Roboto", Helvetica, Arial, serif !important;
        font-size: 16px !important;
    }

`;

export const parentDescStyles = `
    body > .k-content.ProseMirror {
       height: unset;
    }
    .k-content > p {
       margin: 0 0 0.0001pt !important;
       line-height: 1.3
    }
`;

export const hideScrollBarStyles = `
    html {
      overflow-y: hidden;
    }
`;
/**
 * append Styles To KendoEditor iframe
 * @param {object} styleOptions
 */

export const appendStylesToKendoEditorDom = (styleOptions) => {
  const { event, themeStyle, autoheight, hideScrollBar } = styleOptions;
  const domDocument = event.dom.ownerDocument;
  const style = domDocument.createElement("style");
  if (autoheight) {
    style.appendChild(domDocument.createTextNode(parentDescStyles));
  }
  descriptionStyles && style.appendChild(domDocument.createTextNode(descriptionStyles));
  hideScrollBar && style.appendChild(domDocument.createTextNode(hideScrollBarStyles));
  style.appendChild(domDocument.createTextNode(themeStyle));
  domDocument.head.appendChild(style);
  domDocument.head.appendChild(themeStyle);
};

export const groupRows = (group) => {
  let gridGroups = [];
  group?.map((obj) => {
    gridGroups.push(obj.field);
  });
  return gridGroups.toString();
};
