import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import "./drutasDropdown.scss";

/**
 * used to render the search bar for selection custom components in filter popup
 * @param {*} props
 * @author {Prachi Jain}
 */
export const DrutasDropdown = React.memo((props) => {
  const { value, onChange, data, label, key, className, popupClass, isfilter, filterContent, itemRender, valueRender, disabled } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const dynamicSearchRef = useRef(null);

  useOnClickOutside(dynamicSearchRef, (e) => {
    setIsPopupOpen(false);
  });

  const togglePopup = () => {
    if (disabled) return;
    setIsPopupOpen(!isPopupOpen);
  };

  const handleOnClick = (item) => {
    onChange(item);
    setIsPopupOpen(false);
  };

  return (
    <div className={`drutas-dropdown-container  ${className ? className : ""}`} ref={dynamicSearchRef}>
      <div className='drutas-dropdown-value-container'>
        <div onClick={togglePopup} className='truncate-text'>
          {valueRender ? valueRender(value) : value ? value[label] : "select"}
        </div>
      </div>
      {isPopupOpen && (
        <div className={`drutas-dropdown-popup  ${popupClass ? popupClass : ""}`}>
          {isfilter && filterContent()}
          {data && !!data.length ? (
            <div className='drutas-dropdown-popup-elements'>
              {data?.map((item) => {
                return itemRender ? (
                  itemRender(item, handleOnClick)
                ) : (
                  <span className='drutas-dropdown-popup-element truncate-text' key={item[key]} onClick={() => handleOnClick(item)}>
                    {item[label]}
                  </span>
                );
              })}
            </div>
          ) : (
            <>No data Item</>
          )}
        </div>
      )}
    </div>
  );
});
