import { openSidebar } from "../components/Tasks/tasks.service";
import { icon, label } from "../config";
import store from "../store";
import { commentDateAndTimeFormat, getHighlightedText } from "./common";


const handleSidebarClick = async (TaskId) => {
    const state = store.getState();
    await openSidebar({ id: TaskId, userId: state.auth.user.id, taskHistoryId: null })
}

/**
 * get activity logs items.
 * @param {Description, CreatedOn}
 * @returns {JSX}
 */
const getActivityItems = (props) => {
    return (
     <div key={props?.Id} className="log-item position-relative">
            <span>{icon.DATE_RANGE}</span>
            {props?.Description?.includes(props?.TaskCode) ?
                <div onClick={() => handleSidebarClick(props?.TaskId)}>
                    {getHighlightedText(props?.Description, props?.TaskCode)}
                </div>

                :
                <div>{props?.Description}</div>
            }
            <p className="small text-muted log-date-time">{' at ' + commentDateAndTimeFormat(props?.CreatedOn)}</p>
        </div>
    )
}

/**
 * renders  activity logs items.
 * @param {activityLogData}
 * @returns {JSX}
 */
export const renderActivityLog = (activityLogData) => {
    return (
        <div>
            {Object.entries(activityLogData).map(([key, value] , index) => (
                <div>
                     {index === 0 && (
      <div className="d-flex blue font-weight-bold activity-label justify-content-center mt-2 mb-2 ">
        {label.ACTIVITY_LOG}
      </div>
    )}
                <div key={key} className="activity-log-wrapper position-relative">
              
                 <p className="activity-date-range d-inline-block">{(key)}</p>
                    <ul className="activity-log-item">
                        {value?.map(item => (
                            getActivityItems(item)
                        ))}
                    </ul>
                </div>
                </div>
            ))}
        </div>
    );
};

