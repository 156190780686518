import { ADD_QUEUE_PROJECT, GET_FILTERED_PROJECT_USERS, GET_PROJECTS, GET_PROJECT_USERS, GET_SELECTS } from "../../actions/action-types";
import { alertNotification } from "../../actions/alertNotification";
import { showProjectsSidebarOnTop } from "../../actions/latestSidebar";
import { setProjectSettingsData } from "../../actions/projectSettings";
import { changeProjectSidebar, setProjectDetails, setProjectLoader, setSelectedProjectSection, setSelectedTab, setStageList, toggleProjectSidebar } from "../../actions/projectSidebar";
import { addProjectWorkflows } from "../../actions/projectWorkflows";
import {
  addProjectAction,
  addProjectMemberAction,
  deleteProjectMemberAction,
  setLastFilterId,
  setNewProject,
  setTeamFilterDetails,
  setTeamSavedFilter,
  storeTeamFilterUtilities,
  updateProjectAction,
} from "../../actions/projects";
import { updateAllQueues } from "../../actions/queue";
import { addNewFilterToFilterList, setFilteredTaskList, setUpdatedFilterList } from "../../components/DynamicFilters/services/filter.service.helper";
import { PROJECT_TYPE, sidepanelOptions } from "../../components/ProjectSidebar/projectSidebar.constants";
import { formatArrDataValuesToKey, formatKeysAndData } from "../../components/Queues/QueueTasks/queue.common";
import { getTasks, quickFilterCardsCount } from "../../components/Tasks/tasks.service";
import { AUTH_TOKENS, label, notifyIcon, number, quote, requestMethod } from "../../config/";
import config from "../../env.config";
import store from "../../store";
import { checkApiSuccess } from "../../utils/";
import { createDynamicTeamName, getNotification } from "../../utils/common";
import { groupRows } from "../../utils/kendo";
import { fetch } from "./api.service";
import { getAddProjectPayload, getCreateProjectPayload } from "./service.helper";
import { loadState } from "./session.service";

const getAllProjectsAndNotification = async (user, payload, response) => {
  const state = store.getState();
  const { operationalTeamName } = state.auth.user;
  let project = await store.dispatch(getAllProjects());
  project?.find((p) => p.ProjectId == response.data.data.p_ProjectId)?.ProjectId
    ? store.dispatch(alertNotification(true, createDynamicTeamName(response.data.message, operationalTeamName), notifyIcon.SUCCESS_ICON))
    : store.dispatch(alertNotification(true, `${payload.name} ${label.ALREADY_EXISTS}`, notifyIcon.ERROR_ICON));
};

export const addQueueProject = (payload) => {
  return {
    type: ADD_QUEUE_PROJECT,
    payload: payload,
  };
};

const quickFilterCards = {
  QUICK_FILTER_ONE: "quickFilterOne",
  QUICK_FILTER_TWO: "quickFilterTwo",
  QUICK_FILTER_THREE: "quickFilterThree",
  QUICK_FILTER_FOUR: "quickFilterFour",
  QUICK_FILTER_FIVE: "quickFilterFive",
  QUICK_FILTER_SIX: "quickFilterSix",
};

/**
 * It returns quickFilterData for corresponding quickFilter
 * @param {Number} quickFilter
 * @returns {Object} quickFilterData
 * @author Muskan Thakur
 */
export const quickFilterData = (quickFilter) => {
  if (quickFilter == number.ONE) return quickFilterCards.QUICK_FILTER_ONE;
  if (quickFilter == number.TWO) return quickFilterCards.QUICK_FILTER_TWO;
  if (quickFilter == number.THREE) return quickFilterCards.QUICK_FILTER_THREE;
  if (quickFilter == number.FOUR) return quickFilterCards.QUICK_FILTER_FOUR;
  if (quickFilter == number.FIVE) return quickFilterCards.QUICK_FILTER_FIVE;
  if (quickFilter == number.SIX) return quickFilterCards.QUICK_FILTER_SIX;
};

/**
 * opens project sidebar
 * @param {String} type
 * @param {Integer} projectId
 */
export const openProjectSidebar = async (type, projectId, tabIndex) => {
  if (!type && !projectId) return;
  store.dispatch(setProjectLoader(true));
  await store.dispatch(toggleProjectSidebar({ type, showProjectSidebar: true }));
  store.dispatch(showProjectsSidebarOnTop());
  store.dispatch(changeProjectSidebar());
  type === PROJECT_TYPE.PERSONAL_PROJECT && store.dispatch(setSelectedProjectSection([sidepanelOptions[number.ONE].id, sidepanelOptions[number.TWO].id]));
  store.dispatch(setSelectedTab(tabIndex ? tabIndex : number.ZERO));
  const state = store.getState();
  const user = state.auth.user;
  let projectDetail = await store.dispatch(getProjectDetails({ userId: user.id, projectId, companyId: user.companyId }));
  projectDetail?.projectDetails?.ProjectId && (await store.dispatch(setProjectLoader(false)));
};

/**
 * handling api for adding queue
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const addProject = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/addNewProject`,
      data: data,
    };
    await fetch(requestConfig, true, false);
  };
};

export const createProject = (data) => {
  const workspacePage = data.workspacePage;
  delete data["workspacePage"];
  return async (dispatch, getState) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/addProject`,
      data: data,
    };
    const user = loadState(AUTH_TOKENS.USER);
    const state = store.getState();
    const queues = state.allQueues;
    const selectedQueue = queues?.find((item) => item.QueueId === data?.queue);
    const newCount = selectedQueue?.projectCount + number.ONE;
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const payload = getCreateProjectPayload(data, response);
      store.dispatch(setNewProject(payload));
      if (workspacePage) {
        addProjectWorkspacePage(response, data, newCount, dispatch);
      } else {
        const newProject = getAddProjectPayload(data, response?.data.data);
        await dispatch(addProjectAction(newProject));
        addProjectProjectPage(response, data, user, dispatch);
      }
    } else if (response) {
      dispatch(alertNotification(true, response.data.message, notifyIcon.ERROR_ICON));
    }
  };
};

/**
 * Used to handle add Project when Project is added through workspace page
 */
const addProjectWorkspacePage = async (response, data, newCount, dispatch) => {
  await dispatch(
    addQueueProject({
      ProjectId: response.data.data.p_ProjectId,
      ProjectName: data.name,
      Privacy: number.TWO,
    })
  );
  const Queuepayload = { QueueId: data.queue, projectCount: newCount };
  await dispatch(updateAllQueues(Queuepayload));
  getNotification(quote.TEAM_ADDED, notifyIcon.SUCCESS_ICON);
};

/**
 * Used to handle add Project when Project is added through project page
 */
const addProjectProjectPage = async (response, data, user, dispatch) => {
  await openProjectSidebar(PROJECT_TYPE.COMPANY_PROJECT, response.data.data.p_ProjectId);
  const payload = {
    userId: user.id,
    entityType: number.THREE,
    companyId: data.companyId,
    name: data.name,
  };
  getAllProjectsAndNotification(user, payload, response);
};

/**
 * handling api for getting all projects
 * @returns {void}
 * @author Prachi Jain
 */

export const getAllProjects = () => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getCompanyProjects`,
      params: {},
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeProjects(response.data.data));
      return response.data.data.data;
    }
  };
};

/**
 * saving the projects in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of projects to set}
 */

export const storeProjects = (projects) => {
  return {
    type: GET_PROJECTS,
    payload: projects,
  };
};

/**
 * handling api for getting all project selects
 * @returns {void}
 * @author Prachi Jain
 */

export const getProjectSelects = () => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getProjectSelects`,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeSelects(response.data.data));
    }
  };
};

/**
 * saving the project selects in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of users to set}
 */

export const storeSelects = (selectOptions) => {
  return {
    type: GET_SELECTS,
    options: selectOptions,
  };
};

export const updateProjectNew = (data) => {
  const state = store.getState();
  const { operationalTeamName } = state.auth.user;
  const allQueues = state.allQueues;
  let queueName;
  if (data?.queue) {
    const queue = allQueues.find((queue) => queue.QueueId === data?.queue);
    queueName = queue ? queue?.QueueName : undefined;
  }
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/updateProjects`,
      data: data,
    };
    var user = loadState(AUTH_TOKENS.USER);
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const payload = {
        ProjectId: data?.projectId,
        ProjectName: data?.name,
        ProjectCode: data?.projectCode,
        QueueId: data?.queue,
        QueueName: queueName,
        StartDate: data?.startDate,
        EndDate: data?.endDate,
        ProjectStatus: data?.projectStatus,
        DefaultView: data?.defaultView,
      };
      await store.dispatch(updateProjectAction(payload));
      response.data.data
        ? store.dispatch(alertNotification(true, createDynamicTeamName(response.data.message, operationalTeamName), notifyIcon.SUCCESS_ICON))
        : store.dispatch(alertNotification(true, response.data.message, notifyIcon.ERROR_ICON));
    }
  };
};

/**
 * saving the Projects in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of projects to set}
 */

export const storeUserProjects = (projects) => {
  return {
    type: GET_PROJECTS,
    payload: projects,
  };
};

/**
 * used to get secondary projects
 * @param {*} payload
 * @returns
 */

export const getSecondaryProjects = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getSecondaryProjects`,
      params: {
        userId: payload.userId,
        entityType: payload.entityType,
        companyId: payload.companyId,
      },
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeSecondaryProjects(response.data.data));
    }
  };
};

/**
 * saving the secondary Projects in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of projects to set}
 */

export const storeSecondaryProjects = (projects) => {
  return {
    type: GET_PROJECTS,
    payload: projects,
  };
};
/**
 * add users to a project
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const addProjectUsers = (data) => {
  return async (dispatch, getState) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/addProjectUsers`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    const state = getState();
    const assigneeList = state.tasks.defaultDetails?.assigneeList;
    const projectData = state.allProjects.data;
    const assigneeIds = data.projectUsers?.split(",");
    const assignee = assigneeList?.find((assignee) => assignee.value.toString() === data.projectUsers);
    const Project = projectData?.find((projectData) => projectData.ProjectId === data.projectId);
    const { members } = Project;
    if (response) {
      assigneeIds?.forEach((assigneeId) => {
        const assignee = assigneeList?.find((assignee) => assignee.value.toString() === assigneeId.trim());
        if (assignee) {
          const payload = {
            UserId: assignee.value,
            RoleId: +data.roleId,
            Name: assignee.label,
            Email: assignee.Email,
            AccountStatus: data?.accountStatus,
            PhotoLink: assignee.PhotoLink,
          };
          dispatch(addProjectMemberAction(payload));
        }
      });
      const newMemberCount = members + assigneeIds.length;
      const projectPayload = {
        ProjectId: data.projectId,
        members: newMemberCount,
      };
      dispatch(updateProjectAction(projectPayload));
    }
  };
};

/**
 * handling api for getting all users of a project
 * @returns {void}
 * @author Prachi Jain
 */

export const getProjectUsers = (entityId) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getProjectUsers`,
      params: {
        entityId: entityId,
      },
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeTeam(response.data.data));
    }
  };
};

/**
 * saving the users of a project in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of users to set}
 */

export const storeTeam = (users) => {
  return {
    type: GET_PROJECT_USERS,
    users: users,
  };
};

/**
 * saving the filtered users of a project in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of users to set}
 */

export const storeFilteredTeam = (search) => {
  return {
    type: GET_FILTERED_PROJECT_USERS,
    search: search,
  };
};

/**
 * handling api for updating users of project
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const updateUserRole = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/updateUserRole`,
      data: data,
    };
    var user = loadState(AUTH_TOKENS.USER);
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const payload = {
        UserId: data.data.teamMember,
        RoleId: +data.data.roleId,
      };
      checkAdmin(user.isAdmin) ? dispatch(getAllProjects(payload)) && dispatch(getProjectUsers(data.entityId)) : dispatch(getProjectUsers(data.entityId));
    }
  };
};

/**
 * handling api for deleting users from project
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const deleteProjectUser = (data) => {
  return async (dispatch, getState) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/deleteProjectUser`,
      data: data,
    };
    const state = getState();
    let response = await fetch(requestConfig, true, true);
    const projectData = state.allProjects.data;
    const Project = projectData?.find((projectData) => projectData.ProjectId === data.entityId);
    const { members } = Project;
    if (checkApiSuccess(response)) {
      const projectPayload = {
        ProjectId: data.entityId,
        members: members - number.ONE,
      };
      dispatch(updateProjectAction(projectPayload));
      dispatch(deleteProjectMemberAction(data.deletedUser));
    }
  };
};

/**
 * moves projects primary <=> secondary
 * @param {*} data
 * @returns
 */
export const primaryToSecondary = (data) => {
  const state = store.getState();
  const { operationalTeamName } = state.auth.user;
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/primaryToSecondary`,
      data: data,
    };
    var user = loadState(AUTH_TOKENS.USER);
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      getNotification(createDynamicTeamName(response.data.message, operationalTeamName), notifyIcon.SUCCESS_ICON);
      const payload = {
        ProjectId: data.data.entityId,
      };
      dispatch(updateProjectGrid(payload));
    }
  };
};

/**
 * gets project details
 * @param {*} payload
 * @returns
 */
export const getProjectDetails = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getProjectDetails`,
      params: payload,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const { priorityList, stageList, privacyList, projectDetails, QueueName, tagList, viewList, status, delegateOptions } = response.data.data;

      dispatch(setProjectSettingsData({ priorityList, privacyList, QueueName, tagList, viewList, status, delegateOptions }));
      dispatch(setProjectDetails(projectDetails));
      dispatch(setStageList(stageList));
      return response.data.data;
    }
  };
};

export const isNewStreamAdded = (userId, projectId) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/isNewStreamAdded`,
      data: { userId, projectId },
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) return response.data.data;
  };
};

export const saveProjectStages = (data) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/saveProjectStages`,
      data: data,
    };
    await fetch(requestConfig, false, true);
  };
};

export const getProjectWorkflows = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getProjectWorkflows`,
      params: {
        projectId: data.projectId,
      },
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      dispatch(addProjectWorkflows({ projectId: data.projectId, workflows: response.data.data }));
      return response.data.data;
    }
  };
};

export const getTeamDefaultFilterValues = (projectId) => async (dispatch, getState) => {
  const store = getState();
  const userId = store.auth.user.id;
  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/project/getTeamDefaultFilterValues`,
    params: {
      userId,
      projectId,
    },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
  }
};

export const saveTeamFilter = (projectId) => async (dispatch, getState) => {
  const store = getState();
  const { teamFilterData } = store.teamFilter;
  const { user } = store.auth;
  let requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/project/saveTeamFilter`,
    params: { projectId: projectId ?? store.auth.user.myProjectId },
    data: { ...formatArrDataValuesToKey(teamFilterData), isMyBoard: !projectId },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    const { tasks, newFilterId } = response.data.data;
    if (newFilterId) {
      teamFilterData.quickFilter && dispatch(quickFilterCardsCount(user.id, projectId ?? user.myProjectId));
      await dispatch(setLastFilterId(newFilterId));
      addNewFilterToFilterList(newFilterId);
      setFilteredTaskList(projectId, tasks);
    } else {
      getNotification(response.data.message, notifyIcon.ERROR_ICON);
    }
  }
};

export const updateTeamFilterByFilterId = (filterId, projectId, filterGroup, isPinnedNotification) => async (dispatch, getState) => {
  const store = getState();
  const { teamFilterData, teamSavedFilters } = store.teamFilter;
  const { user } = store.auth;
  const selectedFilterGroup = teamSavedFilters?.find((f) => f.value === filterId)?.filterGroup;
  let requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/project/updateTeamFilter`,
    params: { filterId, projectId: projectId ?? user.myProjectId },
    data: {
      ...formatArrDataValuesToKey({ ...teamFilterData }),
      filterGroup: filterGroup ? groupRows(filterGroup) : selectedFilterGroup,
      isMyBoard: !projectId,
    },
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    const { tasks, teamSavedFilters } = response.data.data;
    getNotification(isPinnedNotification ? `${label.FILTER_PIN_POSITION} ${teamFilterData?.quickFilter}` : response.data.message, notifyIcon.SUCCESS_ICON);
    teamFilterData.quickFilter && dispatch(quickFilterCardsCount(user.id, projectId ?? user.myProjectId));
    await dispatch(setLastFilterId(filterId));
    // setUpdatedFilterList(filterId, filterGroup ? groupRows(filterGroup ?? []) : selectedFilterGroup);
    dispatch(setTeamSavedFilter([...teamSavedFilters]));
    setFilteredTaskList(projectId, tasks);
  }
};

export const getTeamSavedFilter = (params) => async (dispatch, getState) => {
  const store = getState();
  const { isApprovalFilterSelected } = store.teamFilter;

  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/project/getTeamFilters`,
    params: params,
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    const { teamSavedFilters, lastFilterId } = response.data.data;
    dispatch(setTeamSavedFilter(teamSavedFilters));
    await dispatch(setLastFilterId(lastFilterId));
    if (!isApprovalFilterSelected) getTasks(null, true);
  }
};

export const updateLastTeamFilter = (projectId, filterId) => async (dispatch, getState) => {
  const store = getState();
  const userId = store.auth.user.id;
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/project/updateLastTeamFilter`,
    params: { userId, projectId },
    data: { filterId: filterId ?? null },
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    dispatch(setLastFilterId(filterId));
  }
};

export const getTeamFilterByFilterId = (filterId) => async (dispatch) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/project/getTeamFilterByFilterId`,
    params: { filterId },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    dispatch(setTeamFilterDetails(formatKeysAndData(response.data.data)));
  }
};

export const deleteTeamFilterByFilterId = (filterId, userId, projectId) => async (dispatch) => {
  let requestConfig = {
    method: requestMethod.DELETE,
    url: `${config.BASE_URL.BASE_API_URL}/project/deleteTeamFilter`,
    data: { filterId, userId, isPersonal: !projectId },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    const { tasks, defaultQuickFilter } = response.data.data;
    await dispatch(setLastFilterId(defaultQuickFilter));
    getTasks(null, true);
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    setFilteredTaskList(projectId, tasks);
  }
};

export const checkTeamFilterNameExists = (filterName) => async () => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/project/checkTeamFilterNameExists`,
    params: { filterName },
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data.filterExists;
  }
};

export const getTeamFilterUtilities = (projectId) => async (dispatch) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/project/getTeamFilterUtilities`,
    params: { projectId },
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    dispatch(storeTeamFilterUtilities(response.data.data));
  }
};
