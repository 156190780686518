import React, { useContext, useEffect } from "react";
import { useSelector } from 'react-redux';
import { number } from "../../../config/index";
import DynamicReminderPopup from "./DynamicReminderPopup";
import { ReminderContext } from "./ReminderContextProvider";
import ReminderList from "./ReminderList";
import './reminder.scss';
import { useTaskDynamicReminderPopup, useTaskReminderList } from "./taskRemindersHook";
import ReminderPopup from "./ReminderPopup";
import { subStrings } from "../../../utils";
import { formatDate } from "../../../helper/common";

/**
* Handles the condition to open ReminderPopup or reminder List
* @author Shivam Mishra
*/

const HandleTaskReminder = ({ isNew, onClick, show, anchor, setShowReminder }) => {

    const { showCustomPopup, isAddClicked, setIsAddClicked, displayWarning, isReminderClicked, editRem, editName, editDate, editTime, isDynamic, setIsReminderClicked } = useContext(ReminderContext);
    const { reminders } = useSelector((state) => state.reminders)
    const anchorAlign={ horizontal: "left", vertical: "top" }
    const popupAlign={ horizontal: "right", vertical: "middle" }
    const { task } = useSelector((state) => state.taskSidebar);

    
    const handleOutSideClick = () => {
        setShowReminder(false)
    }

    const { popupBodyContent, handleSaveReminder, closeCustomPopup } = useTaskDynamicReminderPopup(setShowReminder);
    const { handleClick, delReminder, setDelReminder, handleAddRem } = useTaskReminderList(handleOutSideClick)

    /**
    *  closes the reminder popup
    *  @author: Sarthak Arora
    */

    const handleCancel = () => {
        setShowReminder(false);
        setIsAddClicked(false)
    };




    const reminderDetails = (reminder) => {
        return <div className="small text-secondary">{subStrings(reminder.ReminderTime, number.FIVE)} , {formatDate(reminder.ReminderDate)}</div>
    }

    return (
        <>
            {
                !isNew &&
                    (isAddClicked || task.reminderCount === number.ZERO) ?
                    (<DynamicReminderPopup
                        popupAnchor={anchor}
                        show={show}
                        setShowReminder={setShowReminder}
                        popupBodyContent={popupBodyContent}
                        handleCancel={handleCancel}
                        handleSaveReminder={handleSaveReminder}
                        showCustomPopup={showCustomPopup}
                        closeCustomPopup={closeCustomPopup}
                        extraWidth ={ true }
                        skipButtons ={true}
                        anchorAlign={anchorAlign}
                        popupAlign={popupAlign}

                    />) :
                    <ReminderList onClick={onClick} show={show} anchor={anchor} reminders={reminders} handleClick={handleClick} delReminder={delReminder} setDelReminder={setDelReminder} handleAddRem={handleAddRem} handleOutSideClick={handleOutSideClick} reminderDetails={reminderDetails} />
            }
            {
                isReminderClicked && <ReminderPopup
                    onClick={onClick}
                    ReminderId={editRem}
                    TaskId={task.taskId}
                    Name={editRem ? editName : task.Name}
                    reminderDate={editDate}
                    reminderTime={editTime}
                    handleAddRem={handleAddRem}
                    popupAnchor={anchor}
                    show={show}
                    disable={isDynamic}
                    setIsReminderClicked={setIsReminderClicked}
                    setShowReminder={setShowReminder}
                />
            }

        </>

    )
}
export default HandleTaskReminder;