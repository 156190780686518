import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Editor, EditorUtils, ProseMirror } from "@progress/kendo-react-editor";
import { Tooltip } from "@progress/kendo-react-tooltip";
import _ from "lodash";
import { initProseMirrorDoc, yCursorPlugin, ySyncPlugin, yUndoPlugin } from "y-prosemirror";
import { WebsocketProvider } from "y-websocket";
import * as Y from "yjs";
import { setNewTaskVariables } from "../actions/task";
import { isYdocexists } from "../actions/taskSidebar";
import { getDefaultTaskValues } from "../components/Tasks/tasks.service";
import { useWorkflowContext } from "../components/Workflow/WorkflowContextProvider";
import { number } from "../config";
import envConfig from "../env.config";
import { EditorStateProvider, useEditorContext } from "./editor.Context";
import { createwebsocketUrl, customVariablesClasses, editorIcon, toolbarList } from "./editor.helper";
import "./editor.scss";
import EditorPopup from "./editorPopup/EditorPopup";
import { useTaskVariableUpdates } from "./editorPopup/TaskCustomVariables/hooks/taskCustomVariable.hooks";
import { getTaskVariables } from "./editorPopup/TaskCustomVariables/services/taskCustomVariable.service";
import { getWorkflowDescriptionVariables } from "./editorPopup/TaskCustomVariables/services/workflowDescriptionVariable.service";
import { addBookmark, navigateToBookmark, removeBookmark } from "./editorTools/bookmarkUtils";
import { insertImagePlugin } from "./upload-image/insertImagePlugin";
import { insertImageFiles } from "./upload-image/upload-image-util";
import { editorMarks, generateRandomHexColor, handleAnchorClick, handleKeydown, handleLinkHover, handleSmartChipClick, icon, iframe, inputRule, keymap, nonEditable } from "./util";

const KendoEditor = (props) => {
  return (
    <EditorStateProvider>
      <KendoEditorContent {...props} />
    </EditorStateProvider>
  );
};

const { EditorState, EditorView, Schema } = ProseMirror;
const { imageResizing, tableResizing, pasteCleanup, sanitize, replaceImageSourcesFromRtf } = EditorUtils;

// Settings for pasting
const pasteSettings = {
  stripTags: "span",
};

const KendoEditorContent = ({
  desc,
  setDesc,
  viewRef,
  toggleEditable,
  autoSaveKey,
  setUpdateAutoSaveKey,
  toolbar,
  editableRef,
  editorRef,
  editorEditIcon,
  hidePreview,
  acknowledgeMailEditor,
  showIconAtTop,
  provider,
  setProvider,
  collab = { value: false },
  taskKey,
  yjsDocRef,
  showEditIcons,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { mode } = useSelector((state) => state.taskSidebar.task);
  const { isNewTask, newTaskData, task } = useSelector((state) => state.taskSidebar);
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails);
  const { user } = useSelector((state) => state.auth);
  const editorState = useEditorContext();
  const workflowContext = useWorkflowContext();
  const location = useLocation();
  const {
    visible,
    setVisible,
    hashPopupVisible,
    setHashPopupVisible,
    setUrl,
    setSelectedLink,
    taskInfo,
    setTaskInfo,
    cancelTokenRef,
    setInputPopup,
    smartChipTarget,
    setChipTarget,
    prevKeyRef,
    modeRef,
    setTaskSearchPopup,
    setEditChip,
    setShowEditChip,
    setSmartChipId,
    openImage,
    setOpenImage,
    overallSearchRef,
    setTempChipId,
    tempChipID,
    setEditFrame,
    setEditFrameId,
    taskCustomVariables,
    setTaskCustomVariables,
    popupVisible,
    setPopupVisible,
    popupAnchor,
    setPopupAnchor,
  } = editorState;
  const editIcon = useMemo(() => {
    return editorIcon.find((i) => i.id === editorEditIcon);
  }, [editorIcon, editorEditIcon]);

  const ydocRef = React.useRef(null);
  const yXmlFragmentRef = React.useRef(null);

  const showEditIframe = modeRef.current === number.ONE || modeRef.current === number.THREE || !editableRef.current;
  const { addTaskCustomVariables, addWorklowDescriptionCustomVariables } = useTaskVariableUpdates();

  useEffect(() => {
    (async () => {
      if (location?.pathname?.includes("/workflow")) {
        if (workflowContext?.workflow?.Id) {
          const taskVariablesData = await getWorkflowDescriptionVariables({ workflowId: workflowContext?.workflow?.Id });
          if (taskVariablesData) setTaskCustomVariables([...taskVariablesData?.variables]);
        }
      } else {
        if (isNewTask) {
          let taskVariablesData;
          const workflowId = newTaskData.relatedAssignedType == 5 ? newTaskData.relatedAssignedId : taskWorkflowDetails?.workflowBasicDetails?.Id;
          if (workflowId) taskVariablesData = await getWorkflowDescriptionVariables({ workflowId });
          if (taskVariablesData) {
            taskVariablesData.variables = updateArrayObjects([...taskVariablesData?.variables], "workflowDescriptionVariableId", "taskVariableId");
            await dispatch(setNewTaskVariables([...taskVariablesData?.variables]));
            setTaskCustomVariables([...taskVariablesData?.variables]);
          }
        } else {
          const taskVariablesData = await getTaskVariables({ taskId: isNewTask ? newTaskData?.id : task.taskId });
          if (taskVariablesData) setTaskCustomVariables([...taskVariablesData?.variables]);
        }
      }
    })();
  }, [newTaskData.relatedAssignedId]);

  const updateArrayObjects = (arr, keyToDelete, newKey) => {
    if (arr) {
      return arr?.map((obj) => {
        if (keyToDelete in obj) {
          obj[newKey] = obj[keyToDelete];
          delete obj[keyToDelete];
        }
        return obj;
      });
    }
  };

  useEffect(() => {
    async () => {
      if (workflowContext?.workflow?.Id) {
        const workflowVariablesData = await getWorkflowDescriptionVariables({ workflowId: workflowContext?.workflow?.Id });
        if (workflowVariablesData) setTaskCustomVariables([...workflowVariablesData?.variables]);
      }
    };
  }, [workflowContext?.workflow?.Id]);
  const [isValueUpdated, setValueUpdated] = React.useState(false);
  const [lockedState, setLockedState] = useState(task.IsTaskLocked ? number.ONE : number.ZERO);
  const [isYjsBound, setIsYjsBound] = useState(false); // Track Yjs binding
  useEffect(() => {
    const fragment = yXmlFragmentRef?.current;
    if (fragment) {
      const handleDeepChange = async (events) => {
        if (!isYjsBound) {
          setIsYjsBound(true); // Yjs binding is complete
        }
        if (viewRef?.current) {
          if (events[0]?.transaction?.local && autoSaveKey) {
            debouncedSave();
          }
          const newContent = viewRef.current.dom?.innerHTML;
          // New task created with empty description
          // added to prevent duplication of value
          if (!desc && collab?.isYdocExists === false && !isValueUpdated && !isNewTask && newContent) {
            setValueUpdated(true);
            await dispatch(isYdocexists(true));
          }
          setDesc(newContent);
        }
      };
      // Attach the observer
      fragment.observeDeep(handleDeepChange);
      // Cleanup observer on unmount
      return () => {
        fragment.unobserveDeep(handleDeepChange);
        setIsYjsBound(false); // Reset binding state
      };
    }
  }, [yXmlFragmentRef?.current]);

  // Navigate to bookmark effect
  useEffect(() => {
    if (isYjsBound && editorRef?.current?.state?.view) {
      handleNavigateToBookmark();
    }
  }, [isYjsBound, editorRef?.current]);

  const handleNavigateToBookmark = () => {
    const hash = window.location.hash.slice(1); // Get the bookmark ID from the URL
    if (!hash) return;
    const { view } = editorRef.current.state;
    if (!view) return;
    navigateToBookmark(view, hash);
  };

  /**
   * Hook to dispatch an action to get default task values if not added already.
   * @author Shivam Mishra
   */
  useEffect(() => {
    if (!defaultDetails.isAdded) {
      dispatch(getDefaultTaskValues(user.companyId, user.id));
    }
  }, [user.companyId, user.id]);

  /**
   * Observes DOM changes to detect when a link dialog is added and automatically focuses on the input field.
   * Cleans up the observer when the component unmounts.
   * @author Bhavana
   */
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const linkInput = document.getElementById("k-editor-link-url");
          if (linkInput) {
            linkInput.focus();
          }
        }
        if (mutation.removedNodes.length) {
          mutation.removedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE && node.classList) {
              const hasTargetClass = Array.from(node.classList).some((cls) => customVariablesClasses.includes(cls));
              if (hasTargetClass) {
                if (location?.pathname?.includes("/workflow")) {
                  const newVariables = taskCustomVariables?.filter((variable) => variable.workflowDescriptionVariableId !== node.id);
                  setTaskCustomVariables([...newVariables]);
                  if (workflowContext?.workflow?.Id) addWorklowDescriptionCustomVariables([...newVariables], workflowContext?.workflow);
                  else workflowContext?.setworkflowDescriptionVariables([...newVariables]);
                } else {
                  const newVariables = taskCustomVariables?.filter((variable) => variable.taskVariableId !== node.id);
                  setTaskCustomVariables([...newVariables]);
                  if (!isNewTask) addTaskCustomVariables([...newVariables]);
                  else dispatch(setNewTaskVariables([...newVariables]));
                }
              }
            }
          });
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [taskCustomVariables]);

  /**
   * Hook to update editor mode.
   * @author Shivam Mishra
   */
  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  /**
   * Hook to update the editor's content editable state.
   * Sets 'contenteditable' attribute of the editor's content element.
   * @author Shivam Mishra
   */
  useEffect(() => {
    editorRef?.current?._contentElement?.setAttribute("contenteditable", editableRef.current);
  }, [editableRef.current]);

  useEffect(() => {
    if (taskKey === "description" && task.IsTaskLocked && !lockedState) {
      setDesc("");
      viewRef.current.dom.innerHTML = "";
      setLockedState((prevState) => prevState + number.ONE);
    }
    if (!task.IsTaskLocked) {
      setLockedState(number.ZERO);
    }
  }, [task.IsTaskLocked]);

  /**
   * Use Case :
   * 1. When User has created a new Task with some value in description
   * 2. Description in db (desc) is updated but yDoc is not created yet
   * 3. when you again open the task to initialise the value of the content of the yDoc
   * 4. we munually append the content to remain in sync with user data.
   */
  useEffect(() => {
    (async () => {
      if (!!(editorRef?.current && desc && collab?.isYdocExists === false && !isValueUpdated && !isNewTask)) {
        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = desc;

        const contentElement = editorRef.current._contentElement;
        if (contentElement) {
          // contentElement.innerHTML = ""; // Clear existing content
          while (tempContainer.firstChild) {
            contentElement.appendChild(tempContainer.firstChild);
          }
        }
        setValueUpdated(true);
        await dispatch(isYdocexists(true));
      }
    })();
  }, [desc, collab?.isYdocExists]);

  /**
   * Callback function to handle keydown events.
   * @param {EditorView} view - The editor view.
   * @param {KeyboardEvent} domEvent - The DOM event.
   * @author Shivam Mishra
   */
  const handleKeydownCallback = useCallback(
    (domEvent) => {
      // stops propagation of up and down arrow key to stop navigation .
      if (domEvent.key === "ArrowUp" || domEvent.key === "ArrowDown") domEvent.stopPropagation();
      // prevent default behaviour when editor is read only or update chip only .
      if (modeRef.current === number.ONE || modeRef.current === number.THREE || !editableRef.current) {
        domEvent.preventDefault();
        return;
      }
      handleKeydown(domEvent, setInputPopup, insertNonEditable, user.id, setChipTarget, prevKeyRef, smartChipTarget, setTaskSearchPopup, overallSearchRef, setTempChipId);
    },
    [setInputPopup, prevKeyRef.current]
  );

  /**
   * Callback function to handle image insertion.
   * @param {Object} args - The arguments object.
   * @author Shivam Mishra
   */

  /**
   * Callback function to create input rules for formatting nodes.
   * @param {Object} nodes - The nodes object.
   * @author Shivam Mishra
   */
  const inputRuleCallback = useCallback((nodes) => inputRule(nodes), []);

  /**
   * handles open and closing of image modal
   * @author Shivam Mishra
   */
  const toggleDialogs = () => {
    setOpenImage(!openImage);
  };

  /**
   * useCallback function to handle click event.
   * @param {MouseEvent} event - The click event.
   * @author Shivam Mishra
   */
  const handleClickCallback = useCallback((_, event) => {
    !editableRef.current && handleAnchorClick(event, editableRef, dispatch, history);
    if (modeRef.current === number.ONE || !editableRef.current) {
      event.preventDefault();
      return;
    }
    handleSmartChipClick(event, editableRef, setShowEditChip, setSmartChipId, setEditChip, popupVisible, setPopupVisible, popupAnchor, setPopupAnchor);
  }, []);

  /**
   * useCallback function to handle mouseover event on anchor elements.
   * This function checks if the target is an anchor element, extracts the "tId" parameter from its href attribute,
   * fetches task details using the "tId" if available, and updates the component state accordingly.
   * @param {Event} _ - The event object (not used in the function logic).
   * @param {MouseEvent} domEvent - The mouseover event object.
   * @author Shivam Mishra
   */
  const onMouseOver = useCallback(
    async (_, domEvent) => {
      const { target } = domEvent;
      handleLinkHover(target, cancelTokenRef, setSelectedLink, setUrl, setVisible, editableRef, setTaskInfo, setEditFrame, setEditFrameId, setHashPopupVisible);
    },
    [visible, hashPopupVisible, taskInfo]
  );

  const onImageInsert = (args) => {
    const { files, view, event } = args;
    const nodeType = view.state.schema.nodes.image;
    const position =
      event.type === "drop"
        ? view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })
        : null;
    insertImageFiles({
      view,
      files,
      nodeType,
      position,
    });
    return files.length > 0;
  };

  /**
   * Function to mount the editor view.
   * This function initializes the editor view with the provided state and plugins,
   * and attaches event listeners for keyboard events and picture opening.
   * @param {Object} event - The event object containing view properties.
   * @param {EditorState} event.viewProps.state - The state of the editor view.
   * @returns {EditorView} - Returns the mounted editor view.
   * @author Shivam Mishra
   */

  const updateSchema = (schema, extraNodes = {}) => {
    const paragraph = {
      ...schema.spec.nodes.get("paragraph"),
    };
    paragraph.attrs = paragraph.attrs || {};
    paragraph.attrs["dir"] = {
      default: null,
    };

    // Update schema with paragraph and any additional nodes
    let nodes = schema.spec.nodes.update("paragraph", paragraph);

    // Add any extra nodes if needed (nonEditable, iframe, etc.)
    Object.keys(extraNodes).forEach((nodeName) => {
      nodes = nodes.addToEnd(nodeName, extraNodes[nodeName]);
    });

    return new Schema({ nodes, marks: editorMarks });
  };

  /**
   * Prepare Editor according to the task.
   * @param {Event} event
   * @returns Reference to the editor
   */
  const onMount = (event) => {
    const state = event.viewProps.state;
    let { schema } = state;

    // Common schema update
    const extraNodes = { nonEditable, iframe, icon };
    const mySchema = updateSchema(schema, extraNodes);
    const webSocketUrl = createwebsocketUrl(envConfig.BASE_URL.Y3_HTTP_URL);

    if (!collab || !collab?.value || !webSocketUrl) {
      setupNonCollabMode(event, mySchema, desc);
    } else {
      setupCollabMode(event, mySchema, webSocketUrl);
    }
    return viewRef.current;
  };

  const setupNonCollabMode = (event, mySchema, desc) => {
    const doc = EditorUtils.createDocument(mySchema, desc);

    viewRef.current = new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        handleDOMEvents: {
          ...(event.viewProps.handleDOMEvents || {}),
          keydown: (_, domEvent) => handleKeydownCallback(domEvent),
          click: (view, domEvent) => handleClickCallback(view, domEvent),
          mouseover: (view, domEvent) => onMouseOver(view, domEvent),
        },
        state: EditorState.create({
          doc,
          plugins: [...tableResizing(), imageResizing(), inputRuleCallback(mySchema?.nodes), insertImagePlugin(onImageInsert), keymap, ...event.viewProps.state.plugins],
        }),
      }
    );
  };

  /**
   * Setup Editor with Collaboration
   * @param {Event} event
   * @param {Object} mySchema
   * @param {String} webSocketUrl
   */
  const setupCollabMode = (event, mySchema, webSocketUrl) => {
    const ydoc = new Y.Doc({ gc: false });
    const permanentUserData = new Y.PermanentUserData(ydoc);
    permanentUserData.setUserMapping(ydoc, ydoc.clientID, user.name);

    const wsProvider = createWebSocketProvider(webSocketUrl, ydoc);
    const yXmlFragment = ydoc.getXmlFragment("prosemirror");
    ydocRef.current = ydoc;
    yXmlFragmentRef.current = yXmlFragment;

    let { doc, mapping } = initProseMirrorDoc(yXmlFragment, mySchema);
    /**ensures the ProseMirror document has a valid structure.
     * If the document is undefined or empty, it initializes it with a default paragraph node. */
    if ((!doc || doc.childCount === 0) && !collab.isYdocExists && !desc) {
      const defaultNode = mySchema.nodes.paragraph.createAndFill();
      doc = mySchema.nodes.doc.create(null, defaultNode);
    }
    const state = event.viewProps.state;
    const awareness = wsProvider.awareness;

    setupAwareness(awareness);
    viewRef.current = createEditorView(event, doc, mapping, state, mySchema, awareness, yXmlFragment, permanentUserData);
    setProvider(wsProvider);
    if (yjsDocRef) {
      yjsDocRef.current = ydoc;
    }
  };

  /**
   * Prepare web socket url
   * @param {*} webSocketUrl
   * @param {*} ydoc
   * @returns Web socket connection
   */
  const createWebSocketProvider = (webSocketUrl, ydoc) => {
    return new WebsocketProvider(webSocketUrl, collab?.YdocRoom.toString(), ydoc, {
      params: {
        docId: collab?.YdocRoom.toString(),
      },
    });
  };

  /**
   * Create a Editor View
   * @returns New Editor
   */
  const createEditorView = (event, doc, mapping, state, mySchema, awareness, yXmlFragment, permanentUserData) => {
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        handleDOMEvents: {
          ...(event.viewProps.handleDOMEvents || {}),
          keydown: (_, domEvent) => handleKeydownCallback(domEvent),
          click: (view, domEvent) => handleClickCallback(view, domEvent),
          mouseover: (view, domEvent) => onMouseOver(view, domEvent),
        },
        state: EditorState.create({
          doc: doc,
          plugins: [
            ySyncPlugin(yXmlFragment, { mapping, permanentUserData: permanentUserData }),
            yCursorPlugin(awareness),
            yUndoPlugin(),
            imageResizing(),
            inputRuleCallback(mySchema?.nodes),
            insertImagePlugin(onImageInsert),
            keymap,
            ...state.plugins,
          ],
        }),
      }
    );
  };

  /**
   * Prepare a list of active users on the editor
   * @param {Object} awareness
   */
  const setupAwareness = (awareness) => {
    awareness.setLocalStateField("user", {
      name: user.name,
      color: generateRandomHexColor(),
    });
  };

  /**
   * function to update a non-editable node with specified text and ID into the editor.
   * @param {string} text - The text content of the non-editable node.
   * @param {string} id - The ID of the non-editable node.
   * @author Shivam Mishra
   */
  const updateNonEditable = (text, newNodeId, chipClass = "") => {
    if (editorRef.current) {
      const { view } = editorRef.current.state;
      const { state, dispatch } = view;
      const { schema, doc, tr } = state;
      if (chipClass === "Bookmark") {
        addBookmark(view);
      } else {
        // Traverse through all the nodes in the document
        doc.descendants((node, pos) => {
          if (node.type.name === "nonEditable" && node.attrs.id === tempChipID) {
            // Create a new node with updated attributes or content
            const updatedNode = schema.nodes.nonEditable.create(
              {
                ...node.attrs,
                id: newNodeId,
                class: chipClass,
              },
              schema.text(text)
            );
            // Replace the old node with the new node
            const transaction = tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
            dispatch(transaction);
          }
        });
        view.focus();
        setInputPopup(false);
      }
    }
  };

  /**
   * useCallback function to insert a non-editable node with specified text and ID into the editor.
   * @param {string} text - The text content of the non-editable node.
   * @param {string} id - The ID of the non-editable node.
   * @author Shivam Mishra
   */
  const insertNonEditable = useCallback(async (text, id, chipClass = "shortcode") => {
    const { view } = editorRef.current.state;
    const schema = view.state.schema;

    // Get the new node from the schema
    const nodeType = schema.nodes.nonEditable;

    // Create a new node with the selected text
    const node = await nodeType.createAndFill({ class: chipClass, id: id }, schema.text(text));

    // Insert the new node
    await EditorUtils.insertNode(view, node);
    await view.focus();
    setInputPopup(false);

    return true;
  }, []);

  /**
   * Debounced update task api call
   * @param {Callback}
   * @returns {Void}
   * @authpr Shivam Mishra
   */
  const debouncedSave = useCallback(
    _.debounce(() => {
      setUpdateAutoSaveKey((prevKey) => prevKey + number.ONE);
    }, number.FIVE_HUNDRED),
    [autoSaveKey]
  );

  /**
   * useCallback function to handle change event.
   * @param {Object} event - The change event object.
   * @authpr Shivam Mishra
   */
  const handleChange = useCallback((event) => {
    const nextValue = event.html;
    setDesc(nextValue);
    autoSaveKey && debouncedSave();
  }, []);

  const handleWrapperClick = (ev) => {
    const tr = editorRef.current.state.view.state.tr;

    const textBeforeCursor = tr.doc.textBetween(tr.selection.from - 1, tr.selection.from);

    prevKeyRef.current = textBeforeCursor;
  };

  /**
   * urlify text on enter key press
   * Params {*} event
   * @author Shivam Mishra
   */
  const onKeyDown = useCallback((ev) => {
    if (ev.key === "Enter") {
      if (document.getElementById("k-editor-link-url") && document.getElementById("k-editor-link-text")) {
        ev.preventDefault();
        const insertButton = document.querySelector(".k-button-solid-primary");
        if (insertButton) {
          insertButton.click();
        }
      }
    }

    if (ev.key === "Backspace") {
      handleWrapperClick();
    }
  }, []);

  /**
   * Handle paste HTML event to cleanup and sanitize HTML content.
   * @param {object} event - The event object containing pasted HTML content.
   * @returns {string} - Sanitized HTML content.
   * @author Shivam Mishra
   */
  const handlePasteHtml = React.useCallback((event) => {
    let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);

    if (event.nativeEvent.clipboardData) {
      html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
    }
    return html;
  }, []);

  const deleteBookmark = (bookmarkId) => {
    const { view } = editorRef.current.state;
    removeBookmark(view, bookmarkId);
    setPopupVisible(false);
  };

  return (
    <div className='dt-kendo-editor position-relative overflow-auto'>
      {
        <Tooltip anchorElement='target' parentTitle={true} position='bottom'>
          <div onClick={handleWrapperClick} onKeyDown={onKeyDown}>
            <Editor
              tools={editableRef.current ? toolbarList(toolbar) : []}
              onChange={!collab?.value ? handleChange : undefined}
              value={!collab?.value ? desc : undefined}
              defaultEditMode='div'
              onMount={onMount}
              ref={editorRef}
              onPasteHtml={(event) => handlePasteHtml(event)}
              className={!editableRef.current || (mode && mode !== number.TWO) ? "disable-toolbar" : ""}
            />
          </div>
        </Tooltip>
      }
      {!hidePreview && (
        <EditorPopup
          insertNonEditable={insertNonEditable}
          updateNonEditable={updateNonEditable}
          editorRef={editorRef}
          toggleDialogs={toggleDialogs}
          editableRef={editableRef}
          acknowledgeMailEditor={acknowledgeMailEditor}
          showEditIframe={showEditIframe}
          deleteBookmark={deleteBookmark}
        />
      )}
      {showEditIcons && (
        <div className={` button-align-top  position-absolute button-align`}>
          <button className={` description-button  mr-2 p-0 rounded-circle `} onClick={toggleEditable}>
            <span className={editIcon?.class}>{editIcon?.icon}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default React.memo(KendoEditor);
