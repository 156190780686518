import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllAttachments } from '../../../../actions/attachments';
import { number, quote } from '../../../../config';
import CommonAttachment from './CommonAttachment';
import { getAttachmentObject, isValidSize } from './attachments.helper';
import { useAttachmentContext } from './context/attachments.context';
import { useAttachmentHelpers, useDownloadAttachment } from './hooks/commonAttachments.hooks';
import { handleTaskKeyUpdate } from '../../../Tasks/tasks.service';

/**
 * Attachment Component for new task
 * @returns JSX
 * @author Himanshu Negi
 */
const NewTaskAttachment = () => {
  const { attachments, setAttachments, visible, link, showLoader,
    fileSizeExceedMsg, setFileSizeExceedMsg, loaderType  } = useAttachmentContext();

  const dispatch = useDispatch();
  const { isNewTask } = useSelector(state => state.taskSidebar);
  const { user } = useSelector(state => state.auth);
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails)
  const { allAttachments } = useSelector((state) => state.attachments);
  const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails);
  const { downloadAttachmentFile } = useDownloadAttachment();
  const { handleFocus, toggleDialog, showAttachmentIcon } = useAttachmentHelpers();

    /**
     * Pre populate selected workflow attachments
     * @author Himanshu Negi
     */
    useEffect(() => {
      if(taskWorkflowDetails?.workflowAttachments?.length && !isCloneTask && taskWorkflowDetails?.workflowFeatures[number.FIVE]?.IsSelected === number.ONE){
        const newAttachments  = taskWorkflowDetails?.workflowAttachments?.map((attachment) => ({...attachment,  IsActive: { type: "Buffer", data: [1] }, IsCopyAttachment: true}));
        setAttachments([...newAttachments]);
        dispatch(setAllAttachments([...newAttachments]))
      }
    },[taskWorkflowDetails?.workflowAttachments])

     /**
     * intial attachments in case of new task
     * @author Himanshu Negi
     */
    useEffect(() => {
      if(isCloneTask){
        setAttachments([...cloneTask?.attachments]);
        dispatch(setAllAttachments([...cloneTask?.attachments]))
        handleTaskKeyUpdate(true, "attachmentCount", cloneTask?.attachmentCount)
      }
    },[isCloneTask])

  /**
   * Add attachment handler for new task
   * @param {*} event
   * @author Himanshu Negi
   */
  const addAttachmentHelper = async (event) => {
    const files = event?.target?.files;
    if (!files.length) return
    const filesForUI = [];
    for (let i = number.ZERO; i < files.length; i++) {
      if (!isValidSize(files[i].size, null)) {
        setFileSizeExceedMsg(quote.LIMIT_EXCEEDED);
        continue;
      }
      const imageUrl = URL.createObjectURL(files[i]);
      const attachmentObject = getAttachmentObject(files[i], imageUrl, user);
      filesForUI.push(attachmentObject);
    }

    setAttachments((prev) => [...prev, ...filesForUI]);
    dispatch(setAllAttachments([...allAttachments, ...filesForUI]))

  }

  /**
   * Edit Attachment helper for new task
   * @param {*} attachmentId
   * @param {*} newAttachmentName
   * @author Himanshu Negi
   */
  const editAttachmentHandler = (attachmentId, newAttachmentName) => {
    const updatedAttachments = attachments?.map((attachment) => attachment?.AttachmentId === attachmentId ? { ...attachment, AttachmentName: newAttachmentName } : attachment)
    setAttachments(updatedAttachments);
    dispatch(setAllAttachments([...updatedAttachments]));
  }

  /**
   *  Delete Attachment helper for new task
   * @param {*} attachmentId
   * @author Himanshu Negi
   */
  const deleteAttachmentHandler = (attachmentId) => {
    const updatedAttachments = attachments?.filter((attachment) => attachment?.AttachmentId !== attachmentId);
    setAttachments(updatedAttachments);
    dispatch(setAllAttachments([...updatedAttachments]));
  }


  return (
    <CommonAttachment
      isNewTask={isNewTask}
      task={null}
      projectId={null}
      attachments={attachments}
      viewState={false}
      visible={visible}
      link={link}
      showLoader={showLoader}
      fileSizeExceedMsg={fileSizeExceedMsg}
      loaderType={loaderType}
      addAttachmentHelper={addAttachmentHelper}
      handleFocus={handleFocus}
      showAttachmentIcon={showAttachmentIcon}
      toggleDialog={toggleDialog}
      editAttachmentHandler={editAttachmentHandler}
      deleteAttachmentHandler={deleteAttachmentHandler}
      downloadAttachmentFile={downloadAttachmentFile}
    />
  )
}

export default NewTaskAttachment
