import { combineReducers } from "redux";
import { withReduxStateSync } from "redux-state-sync";
import { RESET_STORE } from "../actions/action-types";
import projectForm from "./ProjectForm";
import storeActivityLog from "./activityLog";
import admin from "./admin";
import adminOnboard from "./adminUserOnboard";
import alertNotification from "./alertNotification";
import app from "./app";
import attachments from "./attachments";
import auth from "./auth";
import bookmarks from "./bookmarks";
import cloneTaskDetails from "./cloneTaskDetails";
import comments from "./comments";
import common from "./common";
import company from "./company";
import companyUsers from "./companyUsers";
import contentManager from "./contentManager";
import history from "./history";
import kanbanUpdates from "./kanbanUpdates";
import latestSidebar from "./latestSidebar";
import linkedTasks from "./linkedTasks";
import loader from "./loader";
import myBodyConfig from "./myTaskBodyConfig";
import navigationTasks from "./navigationTasks";
import notes from "./notes";
import notifications from "./notifications";
import planMyDay from "./planMyday";
import projectSettings from "./projectSettings";
import projectSidebar from "./projectSidebar";
import projectWorkflows from "./projectWorkflows";
import allProjects from "./projects";
import queueProjects from "./queueProjects";
import queueTaskPage from "./queueTaskPage";
import allQueues from "./queues";
import recurrence from "./recurrence";
import reminders from "./reminders";
import selectedProject from "./selectedProject";
import sidebarContent from "./sidebarContent";
import superAdmin from "./superAdmin";
import tasks from "./task";
import taskSidebar from "./taskSidebar";
import taskWorkflowDetails from "./taskWorkflowDetails";
import team from "./team";
import teamFilter from "./teamFilter";
import teamSidebar from "./teamSidebar";
import initializeTour from "./tour";
import userPosition from "./userPosition";

const allReducers = combineReducers({
  auth,
  company,
  loader,
  allQueues,
  companyUsers,
  allProjects,
  projectForm,
  team,
  teamFilter,
  queueProjects,
  storeActivityLog,
  tasks,
  myBodyConfig,
  comments,
  projectSettings,
  selectedProject,
  notes,
  bookmarks,
  notifications,
  superAdmin,
  reminders,
  taskSidebar,
  alertNotification,
  projectSidebar,
  latestSidebar,
  history,
  admin,
  common,
  recurrence,
  teamSidebar,
  queueTaskPage,
  projectWorkflows,
  taskWorkflowDetails,
  linkedTasks,
  navigationTasks,
  sidebarContent,
  userPosition,
  planMyDay,
  adminOnboard,
  initializeTour,
  app,
  attachments,
  cloneTaskDetails,
  kanbanUpdates,
  contentManager,
});

export default withReduxStateSync((state, action) => {
  if (action.type === RESET_STORE) {
    state = {};
  }

  return allReducers(state, action);
});
