import React from "react";
import "../../../../../src/components/Tasks/Reminders/reminder.scss";
import DynamicReminderPopup from "../../../../components/Tasks/Reminders/DynamicReminderPopup";
import { useReminders } from "./remindersHook";

/**
 * Parent Component : Header.js
 * Desc - It open the popup to setup non -task based reminders
 */
const AddReminderPopup = ({ showReminderPopup, setShowReminderPopup, popupAnchor }) => {
  const { popupBodyContent, handleSaveReminder, handleCancel } = useReminders(setShowReminderPopup);

  return (
    <div>
      <DynamicReminderPopup
        show={showReminderPopup}
        setShowReminder={setShowReminderPopup}
        popupBodyContent={popupBodyContent}
        popupAnchor={popupAnchor}
        handleSaveReminder={handleSaveReminder}
        handleCancel={handleCancel}
        extraWidth={true}
        animate={true}
        skipButtons={true}
      />
    </div>
  );
};

export default AddReminderPopup;
