import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, TimePicker } from "@progress/kendo-react-dateinputs";
import { format } from "date-fns";
import "../../../../../src/components/Tasks/Reminders/reminder.scss";
import { setCommonGridReminders } from "../../../../actions/reminders";
import { ReminderContext } from "../../../../components/Tasks/Reminders/ReminderContextProvider";
import { addReminder } from "../../../../components/Tasks/Reminders/reminder.service";
import { button, icon, label, notifyIcon, number, quote } from "../../../../config";
import { formatTime, parseTimeStringToDate } from "../../../../helper/common";
import { useCombinationKeys } from "../../../../helper/commonHooks";
import { dateConvert } from "../../../../utils";
import { getNotification } from "../../../../utils/common";

export const useReminders = (setShowReminderPopup) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [calenderValue, setCalenderValue] = useState(new Date());
  const {
    daysAfterChecked,
    setDaysAfterChecked,
    daysAfter,
    onTomorrow,
    setOnTomorrow,
    setDaysAfter,
    reminderMessage,
    setReminderMessage,
    showCalendar,
    setShowCalendar,
    setIsReminderMessageEmpty,
    isReminderMessageEmpty,
  } = useContext(ReminderContext);
  const { UserReminderTime } = useSelector((state) => state.auth.user);
  const { commonGridReminders } = useSelector((state) => state.reminders);
  const today = new Date();
  const formattedTime = formatTime(UserReminderTime, "HH:mm");
  let initialTime = parseTimeStringToDate(formattedTime);
  if (initialTime < today) {
    initialTime = new Date(today.getTime() + 60 * 60 * 1000);
  }
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const ctrlS = useCombinationKeys("s");
  const titleRef = useRef(null);

  /**
   * sets focus on title after one second
   * @author Sarthak Arora
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      handleFocus();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  /**
   * initialise IsReminderMessageEmpty
   * @author Sarthak Arora
   */
  useEffect(() => {
    setIsReminderMessageEmpty(!!!reminderMessage);
  }, []);

  /**
   * set the selected Date based on which checkbox is checked
   * @author Sarthak Arora
   */
  useEffect(() => {
    if (onTomorrow) {
      setSelectedDates([getFutureDate(1), ...selectedDates]);
    } else if (daysAfterChecked) {
      setSelectedDates([getFutureDate(daysAfter), ...selectedDates]);
    } else setSelectedDates([new Date()]);
  }, [onTomorrow, daysAfterChecked, daysAfter]);

  /**
   * triggers save on CTRL + S
   * @author Sarthak Arora
   */
  useEffect(() => {
    ctrlS && handleSaveReminder();
  }, [ctrlS]);

  /**
   * gives the date object for a future date
   * @param {*} days
   * @returns
   * @author Sarthak Arora
   */
  const getFutureDate = (days) => {
    today.setDate(today.getDate() + days);
    return today;
  };

  /**
   * toggles tommorrow checkbox state
   * @author Sarthak Arora
   */
  const handleOnTomorrow = () => {
    setOnTomorrow(!onTomorrow);
  };

  /**
   * toggles daysAfter checkbox state
   * @author Sarthak Arora
   */
  const handleDaysAfterChecked = () => {
    setDaysAfterChecked(!daysAfterChecked);
  };

  /**
   * sets the daysAfter based on input
   * @author Sarthak Arora
   */
  const handleNumberOfDaysAfter = (e) => {
    if (!e.target.value) {
      setDaysAfter(number.ONE);
      setDaysAfterChecked(false);
    }
    setDaysAfter(e.target.value);
  };

  /**
   *   handles the increment and decrement of number of days for normal reminders (past due date)
   * @param {boolean} increment - Whether increment button is pressed or decrement
   *
   * @author Sarthak Arora
   */

  const handleDaysAfterChange = (increment) => {
    const newDaysAfter = increment ? daysAfter + number.ONE : daysAfter - number.ONE;
    if (newDaysAfter >= number.TWO) setDaysAfter(newDaysAfter);
  };

  /**
   * toggles calender checkbox
   * @author Sarthak Arora
   */
  const handleShowCalender = () => {
    setShowCalendar(!showCalendar);
    if (!showCalendar) setOnTomorrow(false);
  };

  /**
   * returns the updated commonGridReminders
   * @param {*} response
   * @returns {Object} updatedCommonGridReminders
   * @author Sarthak Arora
   */
  const getUpdatedCommonGridReminders = ({ Id, ReminderMessage, ReminderTime, ReminderDate, ReminderType, EntityId, IsSeen }) => {
    const newReminder = {
      Id: Id,
      ReminderMessage: ReminderMessage,
      ReminderTime: ReminderTime,
      ReminderDate: ReminderDate,
      ReminderType: ReminderType,
      IsSeen: IsSeen ? IsSeen : 0,
      Status: new Date(ReminderDate) > new Date() ? label.FUTURE : label.DUE,
    };
    const updatedCommonGridReminders = [...commonGridReminders, newReminder];
    return updatedCommonGridReminders;
  };

  /**
   * Creates an alert notification action.
   * @param {Date} reminderDate - Date of the reminder being created
   * @param {string} reminderMessage - Message of the reminder being created
   * @param {number} reminderType - type of reminder ( 1 for custom/normal , 2 for dynamic , 3 for general)
   * @author Sarthak Arora
   */

  const createReminder = async (reminderDate, reminderMessage, reminderType, extraParams) => {
    const payload = {
      userId: user.id,
      reminderMessage: reminderMessage,
      reminderTime: format(selectedTime, "HH:mm"),
      reminderDate: dateConvert(reminderDate),
      reminderId: null,
      reminderType: reminderType,
      ...extraParams,
    };
    const res = await dispatch(addReminder(payload));
    if (res) {
      await dispatch(setCommonGridReminders(getUpdatedCommonGridReminders(res)));
    } else {
      getNotification(quote.FAILED_TO_ADD_REMINDER, notifyIcon.ERROR_ICON);
      setShowReminderPopup(false);
    }
    return res;
  };

  /**
   *   handles the creation of reminder based on the type
   * @author Sarthak Arora
   */

  const handleSaveReminder = useCallback(async () => {
    let reminderCount = 0;
    let res;

    if (onTomorrow) {
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + number.ONE);
      res = await createReminder(tomorrow, reminderMessage, number.THREE);
      if (res) reminderCount++;
    }

    if (daysAfterChecked) {
      const reminderDateAfterToday = new Date(today.getTime() + daysAfter * 24 * 60 * 60 * 1000);
      res = await createReminder(reminderDateAfterToday, reminderMessage, number.THREE);
      if (res) reminderCount++;
    }

    if (showCalendar) {
      const reminderOnCustomDate = new Date(calenderValue);
      res = await createReminder(reminderOnCustomDate, reminderMessage, number.THREE);
      if (res) reminderCount++;
    }

    if (reminderCount) {
      getNotification(quote.REMINDER_SET, notifyIcon.SUCCESS_ICON);
    } else getNotification(quote.FAILED_TO_ADD_REMINDER, notifyIcon.ERROR_ICON);
    setDaysAfter(number.TWO);
    setShowReminderPopup(false);
  }, [onTomorrow, daysAfterChecked, reminderMessage, daysAfter, showCalendar]);

  /**
   *  sets the reminderMessage
   *  @author: Sarthak Arora
   */
  const inputEvent = (event) => {
    setIsReminderMessageEmpty(event.target.value === "");
    setReminderMessage(event.target.value);
  };

  /**
   * handle the cancel btn
   * @author Sarthak Arora
   */
  const handleCancel = () => {
    setShowReminderPopup(false);
  };

  /**
   * handle the change in calender date
   * @author Sarthak Arora
   */
  const handleCalendarChange = (e) => {
    setCalenderValue(e.value);
  };

  /**
   * change handler for TimePicker
   * @author Sarthak Arora
   */
  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  /**
   * focus on the title field
   * @author Sarthak Arora
   */
  const handleFocus = () => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  };

  /**
   *  restricts the key down to numbers only
   *  @author: Sarthak Arora
   */
  const handleKeyDown = (e) => {
    if (!(e.key === "ArrowLeft" || e.key === "ArrowRight" || e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || (e.key >= "0" && e.key <= "9"))) {
      e.preventDefault();
    }
  };

  /**
   * main content for the popup
   * @author Sarthak Arora
   */
  const popupBodyContent = () => {
    return (
      <>
        <div className='pt-3'>
          <div className='form-group d-flex'>
            <div className='d-flex flex-column w-50 col-md-7 first-column'>
              <div className='mb-2'>
                <label>
                  <input id='dynamic-reminder-popup-tomorrow-checkbox' className='mr-2' type='checkbox' checked={onTomorrow} onChange={handleOnTomorrow} />
                  {label.TOMORROW}
                </label>
              </div>
              <div className='d-flex align-items-center mb-2'>
                <input id='dynamic-reminder-popup-days-after-checkbox' className='mr-2 ' type='checkbox' checked={daysAfterChecked} onChange={handleDaysAfterChecked} />
                <span className='d-flex align-items-center'>
                  <input
                    className='days-from-today mr-2 '
                    type='numeric'
                    value={daysAfter}
                    disabled={!daysAfterChecked}
                    onChange={handleNumberOfDaysAfter}
                    onKeyDown={handleKeyDown}
                    maxLength={number.TWO}
                  />
                  <div className='dynamic-reminder-popup-inc-dec-btn-group d-flex flex-column'>
                    <button
                      id='dynamic-reminder-popup-inc-days-after'
                      className='mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none'
                      disabled={!daysAfterChecked}
                      onClick={() => handleDaysAfterChange(true)}>
                      {icon.ARROW_UP}
                    </button>
                    <button
                      id='dynamic-reminder-popup-dec-days-after'
                      className='mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none'
                      disabled={!daysAfterChecked}
                      onClick={() => handleDaysAfterChange(false)}>
                      {icon.ARROW_DOWN}
                    </button>
                  </div>

                  {label.DAYS_FROM_TODAY}
                </span>
              </div>
              <div className='rem-calender'>
                <input type='checkbox' onChange={handleShowCalender}></input>
                <Calendar value={calenderValue} onChange={handleCalendarChange} disabled={!showCalendar} navigation={false} min={today} />
              </div>
            </div>
            <div className='d-flex flex-column pr-0 col-md-5'>
              <div className='mb-4'>
                <label>{label.TITLE}</label>
                <textarea
                  id='dynamic-reminder-popup-reminder-title'
                  className='w-100  form-control'
                  rows={6}
                  ref={titleRef}
                  onChange={inputEvent}
                  placeholder={label.TITLE}
                  maxLength={80}
                  autoFocus
                  onFocus={handleFocus}
                />

                <div className={`${isReminderMessageEmpty ? "show" : "hide"}`}>
                  <p className='text-red reminder-title-warning m-0 mt-2'>{quote.REMINDER_TITLE_EMPTY}</p>
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-between margin-time'>
                <label className='mb-0'>{label.TIME}</label>
                <div className='mr-4'>
                  <TimePicker steps={{ minute: 5 }} defaultValue={selectedTime} className=' ml-2 w-75 ' onChange={handleTimeChange} />
                </div>
              </div>
              <div className='mt-5 text-right'>
                <button id='dynamic-reminder-popup-reminder-cancel-btn' className='btn  btn-secondary mr-2 reminder-confirmation-btn' onClick={handleCancel}>
                  {button.CANCEL}
                </button>
                <button
                  id='dynamic-reminder-popup-reminder-save-btn'
                  className=' btn btn-primary reminder-confirmation-btn '
                  disabled={isReminderMessageEmpty || (!onTomorrow && !daysAfterChecked && !showCalendar)}
                  onClick={handleSaveReminder}>
                  {button.SAVE}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return { getFutureDate, popupBodyContent, handleSaveReminder, handleCancel };
};
