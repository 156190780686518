import React from 'react';
import { icon, tooltip } from '../../config';
import { openSidebar } from '../../components/Tasks/tasks.service';
import { Tooltip } from '@progress/kendo-react-tooltip';

/**
 * Displays action icons for copying the task URL, opening the task in a new tab, and triggering a linked task popup.
 * @author Muskan Thakur
 */
const TaskIcons = ({ url, taskInfo, copyToClipboard, anchor, setShow, setShowPopup }) => {
    return (
        <Tooltip position='bottom' parentTitle={true}>
        <div className="task-icons-end" ref={anchor}>
            {(url || taskInfo) && (
                <span onClick={copyToClipboard} title={tooltip.COPY_TASK_LINK} id="copy-hyperlink-btn">
                    {icon.COPY_CONTENT}
                </span>
            )}
            <span id="editor-popup-hovered-button" title={tooltip.OPEN_TASK}onClick={() => openSidebar({ id: taskInfo.Id })}>
                {icon.OPEN_IN_NEW}
            </span>
            <span title={tooltip.LINK_THIS_TASK} onClick={() => { setShowPopup(true); setShow(true) }}>{icon.MEDIATION}</span>
        </div>
        </Tooltip>
    );
};

export default React.memo(TaskIcons);
