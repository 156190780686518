import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { button, icon, label, number } from "../../../config";
import { onBlur, onFocus, onOpen } from "../../../utils/kendo";
import { ReminderContext } from "./ReminderContextProvider";
import ReminderPopup from "./ReminderPopup";

/**
 * Parent Component : HandleReminder
 * Renders the popup for Advanced Reminders 
   @author: Sarthak Arora
 **/

const DynamicReminderPopup = React.memo(
  ({ show, popupAnchor, setShowReminder, popupBodyContent, handleCancel, handleSaveReminder, showCustomPopup, closeCustomPopup, extraWidth, animate, skipButtons , anchorAlign , popupAlign}) => {
    const deleteRef = React.useRef(null);
    const blurTimeoutRef = React.useRef(null);
    const { task } = useSelector((state) => state.taskSidebar);
    const { setIsAddClicked, displayWarning, setEditAddRem, isReminderMessageEmpty } = useContext(ReminderContext);
    const [isSaved, setIsSaved] = useState(false);
    const [openDuration, setOpenDuration] = React.useState(300);
    const [closeDuration, setCloseDuration] = React.useState(300);
    const handleSave = (e) => {
      if (!isSaved) {
        handleSaveReminder();
        setIsSaved(true);
        e.stopPropagation();
      }
    };

    /**
     *  hides the reminder popup
     * @author Sarthak Arora
     */

    const hidePopup = () => {
      setShowReminder(false);
      setIsAddClicked && setIsAddClicked(false);
    };

    return (
      <Popup
        anchor={popupAnchor?.current}
        animate={
          animate
            ? {
                openDuration,
                closeDuration,
              }
            : false
        }
        anchorAlign={anchorAlign}
        popupAlign={popupAlign}
        show={show}
        className={`${extraWidth ? "non-task-reminder-popup" : "dynamic-reminder-popup"} dt-popup `}
        onOpen={() => onOpen(deleteRef)}>
        <div tabIndex={number.ZERO} ref={deleteRef} onFocus={() => onFocus(blurTimeoutRef)} onBlur={() => onBlur(blurTimeoutRef, hidePopup)}>
          <div className='dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3'>
            <div className='dt-popup-title'>{label.SET_REMINDER}</div>
            <span id='dynamic-reminder-popup-close' className='dt-popup-cancel-btn cursor-pointer ml-2' title={label.CLOSE_POPUP} onClick={handleCancel}>
              <Tooltip anchorElement='target' parentTitle={true} position='bottom'>
                {icon.CLOSE}
              </Tooltip>
            </span>
          </div>
          {popupBodyContent()}
          {!skipButtons && (
            <div className=' dt-popup-footer text-right'>
              <button id='dynamic-reminder-popup-reminder-cancel-btn' className='btn  btn-secondary mr-2 reminder-confirmation-btn' onClick={handleCancel}>
                {button.CANCEL}
              </button>
              <button id='dynamic-reminder-popup-reminder-save-btn' className=' btn btn-primary reminder-confirmation-btn ' disabled={displayWarning || isReminderMessageEmpty} onClick={handleSave}>
                {button.SAVE}
              </button>
            </div>
          )}
          {showCustomPopup && (
                    <ReminderPopup
                        popupAnchor={popupAnchor}
                        show={show}
                        onClick={closeCustomPopup}
                        TaskId={task?.taskId}
                        Name={task?.Name}
                        setShowReminder={setShowReminder}
                        disable={false}
                        setEditAddRem={setEditAddRem}
                    />
                )}
              </div>
          </Popup>

    );
  }
);

export default DynamicReminderPopup;
