import React from "react";
import { useSelector } from "react-redux";
import { icon, quote } from "../../config";
import config from "../../env.config";
import { getTaskUrl } from "../../helper/common";
import { copyTextToClipboard, sanitizeUrl } from "../../utils/common";

const BookmarkPopup = ({ setPopupVisible, deleteBookmark, target }) => {
  const { tenantAppUrl } = useSelector((state) => state.app);
  const { task } = useSelector((state) => state.taskSidebar);
  const { id } = target;

  /**
   * function used for copying taskUrl
   */
  const copyTaskUrl = () => {
    const fullTaskUrl = (tenantAppUrl ? tenantAppUrl : config.BASE_URL.APP_URL) + sanitizeUrl(getTaskUrl(task.taskId));
    const bookmarkURL = new URL(fullTaskUrl);
    bookmarkURL.hash = id; // Append or update the hash in the URL
    copyTextToClipboard(bookmarkURL, quote.BOOKMARK_COPIED_TO_CLIPBOARD);
    setPopupVisible(false);
  };

  return (
    <div className='d-flex p-2'>
      <p className='mb-0'>{quote.BOOKMARK}</p>
      <span className='ml-3 cursor-pointer' title='Copy' onClick={copyTaskUrl}>
        {icon.COPY_CONTENT}
      </span>
      <span className='ml-3 cursor-pointer' title='Delete' onClick={() => deleteBookmark(id)}>
        {icon.DELETE_ICON}
      </span>
      <span class='ml-3 cursor-pointer' onClick={() => setPopupVisible(false)}>
        {icon.CLOSE}
      </span>
    </div>
  );
};

export default BookmarkPopup;
