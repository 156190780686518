import React, { useEffect, useMemo, useRef, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { SplitButton } from "@progress/kendo-react-buttons";
import { BadgeContainer } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { updateCurrUser } from "../../../../actions/auth";
import { setUpdateLayoutDesc } from "../../../../actions/common";
import { setSelectedContentItem } from "../../../../actions/contentManager";
import { showTaskSidebarOnTop } from "../../../../actions/latestSidebar";
import { resetLinkTaskForNewTask } from "../../../../actions/linkedTasks";
import { clearAllNotes, setShowNotesList } from "../../../../actions/notes";
import { setNotificationCount, setShowNotifications } from "../../../../actions/notifications";
import { setIsApprovalFilterSelected } from "../../../../actions/projects";
import { resetSidebarReducer, setShowTaskSidebar } from "../../../../actions/taskSidebar";
import BackForwardNavigation from "../../../../components/BackForwardNavigation/BackForwardNavigation";
import { BreadcrumbComponent } from "../../../../components/ContentManagementSystem/ContentManager/Breadcrumb";
import { convertToBreadcrumbData, searchTreeItem } from "../../../../components/ContentManagementSystem/helper";
import Loader from "../../../../components/Loader/Loader";
import Notes from "../../../../components/Notes/Notes";
import { getNotes } from "../../../../components/Notes/notes.service";
import Notifications from "../../../../components/Notifications/Notifications";
import { getNotificationsCount } from "../../../../components/Notifications/notifications.service";
import OverallSearch from "../../../../components/OverallSearch/OverallSearch";
import { ReminderContextProvider } from "../../../../components/Tasks/Reminders/ReminderContextProvider";
import { action, button, icon, label, number, route, tooltip } from "../../../../config";
import { getQueryParams, getTimeFromDate } from "../../../../helper/common";
import { useCombinationKeys } from "../../../../helper/commonHooks";
import { splitString } from "../../../../utils";
import { appendString, dateConvert } from "../../../../utils/common";
import { onOpen } from "../../../../utils/kendo";
import { isDescriptionUpdated } from "../../../services/common.service";
import { isNewStreamAdded } from "../../../services/projects.service";
import CollabCentralLayout from "../../CollabCentralLayout";
import { showDescriptionIcon } from "../../layout.constants";
import UserDropdown from "../UserDropdown/UserDropdown";
import AddReminderPopup from "./AddReminderPopup";
import MultiUploadDropdown from "./MultiUploadDropdown";
import PlanMyDay from "./PlanMyDay";
import ToggleTheme from "./ToggleTheme";
import "./header.scss";

const MainHeader = () => {
  const homeRef = useRef();
  const profileRef = useRef();
  const buttonRef = useRef();
  const overallSearchRef = useRef();
  const layoutDescriptionRef = useRef();
  const [openProfile, setOpenProfile] = useState(false);
  const [pageParamState, setPageParamState] = useState({});
  const [currentRoute, setCurrentRoute] = useState({});
  const [notesLoading, setNotesLoading] = useState(false);
  const [projectNameState, setProjectNameState] = useState("");
  const [project, setProject] = useState("");
  const [queueName, setQueueName] = useState("");
  const [queueIdparam, setQueueId] = useState(null);
  const [isOwner, setIsOwner] = useState();
  const [isDescUpdated, setIsDescUpdated] = useState();
  const [showDescription, setShowDescription] = useState(false);
  const [showReminderPopup, setShowReminderPopup] = useState(false);
  const location = useLocation();
  const newDate = new Date();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const { mode, isDescriptionUpdate, isStreamUpdate } = auth.user;
  const [interactionName, setInteractionName] = useState(auth.user.lastSelectedInteraction);
  const notes = useSelector((state) => state.notes);
  const notifications = useSelector((state) => state.notifications);
  const allProjectsList = useSelector((state) => state.tasks.defaultDetails.allProjectsList);
  const allQueueList = useSelector((state) => state.allQueues);
  const { data } = useSelector((state) => state.allProjects);
  const loading = useSelector((state) => state.loader.loading);
  const isCollabCentralOpened = useSelector((state) => state.common.isCollabCentralOpened);
  const dispatch = useDispatch();
  const ctrlD = useCombinationKeys("d");
  const { currentTheme } = useThemeSwitcher();
  const user = auth.user;
  const operationalTeamName = useSelector((state) => state.auth.user.operationalTeamName);
  const createTask = getQueryParams("createTask", location.search);
  const { id, companyId } = useSelector((state) => state.auth.user);
  const { selectedContentManagerItem, contentManagerData } = useSelector((state) => state.contentManager);
  let { projectId } = pageParamState;
  const path = location.pathname;
  const isTaskRoute = path.includes("task");
  const { queueId } = useParams();
  const isReminderPath = path === route.PRIVATE_ROUTE.REMINDER.PATH;
  const addButtonRef = useRef();

  const contentMangementSystem = useMemo(() => mode === number.TWO && contentManagerData?.length && isTaskRoute, [mode, contentManagerData, isTaskRoute]);

  /* Used to get notification count for the main header */
  useEffect(() => {
    dispatch(getNotificationsCount(user.id, getTimeFromDate(newDate), dateConvert(newDate)));
    setCurrentRouteFunction();
  }, []);

  useEffect(() => {
    const determineInteractionName = () => {
      const lastInteraction = auth.user.lastSelectedInteraction;
      switch (true) {
        case projectId && lastInteraction === label.TEAM_CONNECT:
          return "Team Canvas";
        case pageParamState.queueId && lastInteraction === label.TEAM_CONNECT:
          return label.WORKSPACE_CANVAS;
        case !projectId && lastInteraction === label.TEAM_CONNECT:
          return "My Canvas";
        default:
          return lastInteraction;
      }
    };
    setInteractionName(determineInteractionName());
  }, [auth.user.lastSelectedInteraction, projectId, pageParamState.queueId]);

  useEffect(() => {
    (async () => {
      const result = await isDescriptionUpdated(companyId, label.COMPANY, id)();
      setIsDescUpdated(result?.IsUpdated);
    })();
  }, []);

  useEffect(() => {
    if (!isCollabCentralOpened) {
      setShowDescription(false);
    }
  }, [isCollabCentralOpened]);

  useEffect(() => {
    ctrlD && handleAddTaskButton();
  }, [ctrlD]);

  /**
   * handles theme mode of drutas
   * @author Sejal
   */
  useEffect(() => {
    if (currentTheme == "light") {
      import("../../../../assets/themes/light-theme-variables.scss");
      import("../../../../assets/themes/light-theme.scss");
    } else if (currentTheme == "dark") {
      import("../../../../assets/themes/dark-theme-variables.scss");
      import("../../../../assets/themes/dark-theme.scss");
    }
  }, [currentTheme]);

  useEffect(() => {
    let { projectId } = pageParamState;
    let projectList;
    projectList = allProjectsList;
    setQueueName(data?.find((i) => i.ProjectId == projectId)?.QueueName);
    setProjectNameState(projectList?.find((i) => i.ProjectId == projectId)?.ProjectName);
  }, [pageParamState.projectId, allProjectsList]);

  useEffect(() => {
    setQueueName(allQueueList?.find((i) => i.QueueId == pageParamState.queueId)?.QueueName);
    if (pageParamState.projectId) setIsOwner(data?.find((i) => i.ProjectId == projectId)?.RoleId === 4 ? true : false);
  }, [pageParamState.queueId, allQueueList, pageParamState.projectId]);

  useEffect(() => {
    if (pageParamState.queueId) setIsOwner(allQueueList?.find((i) => i.QueueId == pageParamState.queueId)?.RoleId == 2 ? true : false);
  }, [pageParamState.queueName, allQueueList, mode]);

  useEffect(() => {
    if (pageParamState.queueId) setIsOwner(allQueueList?.find((i) => i.QueueId == pageParamState.queueId)?.RoleId === 2 ? true : false);
  }, [pageParamState.queueName, allQueueList, mode]);

  useEffect(() => {
    const personalBoard = auth.user.Name + label.S_BOARD;
    let currentProject = projectNameState ? projectNameState : queueName ? queueName : personalBoard;
    setProject(currentProject);
    setQueueName(data?.find((i) => i.ProjectId == projectId)?.QueueName);
    setQueueId(data?.find((i) => i.ProjectId == projectId)?.QueueId);
    if (data) setIsOwner(data?.find((i) => i.ProjectId == projectId)?.RoleId === 4 ? true : false);
  }, [projectNameState, auth.user]);

  useEffect(() => {
    (async () => {
      let { queueId } = pageParamState;
      if (queueId) {
        const result = await isDescriptionUpdated(queueId, label.QUEUE_ENTITY, auth.user.id)();
        dispatch(updateCurrUser({ isDescriptionUpdate: result.IsUpdated }));
      }
    })();
  }, [pageParamState.queueId]);

  useEffect(() => {
    (async () => {
      if (location) {
        setCurrentRouteFunction();
        dispatch(setUpdateLayoutDesc(false));
      }
    })();
  }, [location]);

  /**
   * handling the open/close of dropdown menu
   * @returns {boolean to open/close dropdown}
   */
  const handleDropdownClick = (e) => {
    setOpenProfile(!openProfile);
  };

  /**
   * Sets router object from routes.js based on current route for header and icon
   */
  const setCurrentRouteFunction = () => {
    let pathname = location.pathname;
    setPageParams(pathname);
    let currentRoute = Object.values({ ...route.PRIVATE_ROUTE, ...route.ADMIN_ROUTE, ...route.SUPER_ADMIN_ROUTE }).find((r) => {
      return pathname.includes("superAdmin") ? r.PATH.includes(pathname) : r.PATH.includes(splitString(pathname, "/")[1]);
    });
    setCurrentRoute(currentRoute);
  };

  /**
   *
   * Sets state variable as page params task page
   * @param {String} pathname
   * @author Himanshi Chawla
   */
  const setPageParams = (pathname) => {
    if (matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH })) {
      const { params } = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
      setPageParamState(params);
    } else if (matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH })) {
      const { params } = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH });
      setPageParamState(params);
    }
  };

  const handleAddTask = async () => {
    await handleRemoveParams();
    handleAddTaskButton();
  };

  const handleAddTaskButton = async () => {
    await dispatch(resetSidebarReducer());
    await dispatch(resetLinkTaskForNewTask());
    dispatch(showTaskSidebarOnTop());
    dispatch(setShowTaskSidebar({ showTaskSidebar: true, isNewTask: true }));
  };

  /**
   * Removes params when add task is call
   * @author Shivam Mishra
   */
  const handleRemoveParams = () => {
    if (createTask) history.push(route.PRIVATE_ROUTE.TASKS.PATH);
  };

  /**
   * handling the open/close of layout description
   * @params {void}
   * @returns {void}
   * @author Shivam
   */
  const handleShowDescription = () => {
    setShowDescription(!showDescription);
  };
  /**
   * handling the open/close of notification dropdown and clears notification count
   * @returns {void}
   */
  const handleNotificationButtonClick = () => {
    if (!notifications.showNotification) dispatch(setNotificationCount(number.ZERO));
    dispatch(setShowNotifications(!notifications.showNotification));
    dispatch(setIsApprovalFilterSelected(false));
  };

  const handleProject = async () => {
    if (!projectId) {
      await dispatch(updateCurrUser({ mode: number.ZERO }));
    }
    if (isOwner) await dispatch(updateCurrUser({ mode: number.ZERO }));
  };

  let queueClick = (pageParamState.queueId && allQueueList?.find((i) => i.QueueId == pageParamState.queueId)?.RoleId == 2) || (isOwner && projectId);
  let projectClick = !projectId || isOwner;
  const handleQueue = async () => {
    if (pageParamState.queueId && allQueueList?.find((i) => i.QueueId == pageParamState.queueId)?.RoleId == 2) {
      await dispatch(updateCurrUser({ mode: number.ZERO }));
    }
    if (isOwner && projectId) history.push(route.PRIVATE_ROUTE.QUEUE_TASKS.PATH + "/" + queueIdparam + "/" + encodeURIComponent(queueName));
  };
  /***
   * Toggles the showReminderPopup
   * @author Sarthak
   */
  const handleAddReminder = () => {
    setShowReminderPopup(!showReminderPopup);
  };

  const itemsHomePage = [
    {
      text: (
        <span>
          {" "}
          <span className='mr-1'>{icon.BELL}</span>
          {label.ADD_REM}
        </span>
      ),
      action: handleAddReminder,
    },
  ];

  const itemsReminderPage = [
    {
      text: (
        <>
          <span>
            <span className='mr-2'>{icon.ADD_TASK}</span>
            {label.ADD_TASK}
          </span>
        </>
      ),
      action: handleAddTask,
    },
  ];

  const popupSettings = {
    popupClass: "custom-split-popup",
    anchorAlign: { vertical: "bottom", horizontal: "right" },
    popupAlign: { vertical: "top", horizontal: "right" },
  };

  const handleItemClick = (event) => {
    const { item } = event;
    if (item.action) {
      item.action();
    }
  };

  /**
   * handles the breadcrumbs based on user selection
   * @param {Object} event
   * @author Muskan Thakur
   */
  const handleBreadcrumbSelection = (event) => {
    if (!event.nativeEvent.keyCode) {
      const newItem = searchTreeItem(contentManagerData, { path: event.id });
      dispatch(setSelectedContentItem(newItem));
    }

    if (event.nativeEvent.keyCode === number.THIRTEEN) {
      const newItem = searchTreeItem(contentManagerData, { path: event.id });
      dispatch(setSelectedContentItem(newItem));
    }
  };

  return (
    <React.Fragment>
      <Tooltip anchorElement='target' position='bottom' parentTitle='true'>
        <header id='page-topbar' className='position-fixed top-0 right-0'>
          <div className='navbar-header d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <Tooltip anchorElement='target' position='bottom' parentTitle='true'>
                <div className='position-relative'>
                  <button
                    className='btn collab-central d-flex justify-content-between cursor-pointer bg-transparent ml-3'
                    id='side-panel-collab-central'
                    ref={layoutDescriptionRef}
                    onClick={handleShowDescription}>
                    {icon.ATR}
                    {button.COLLAB_CENTRAL}
                  </button>
                  {showDescription && <CollabCentralLayout isOrganisationDesc={true} anchor={layoutDescriptionRef} setShowPopup={handleShowDescription} classicMode={false} hidePreview={true} />}
                </div>
              </Tooltip>
              <div className='d-flex navbar-left align-items-center'></div>
              {contentMangementSystem ? (
                <BreadcrumbComponent data={selectedContentManagerItem} handleBreadcrumbSelection={handleBreadcrumbSelection} />
              ) : (
                isTaskRoute && (
                  <span className='btn '>
                    {projectId ? (
                      mode === 1 ? (
                        <>
                          <span className={`${queueClick ? "blue cursor-pointer" : ""}`} onClick={handleQueue}>
                            {queueName}
                          </span>
                          {icon.RIGHT_BREADCRUMB}
                          <span className={`${projectClick ? "blue cursor-pointer" : ""}`} onClick={handleProject}>
                            {project}
                          </span>
                          {icon.RIGHT_BREADCRUMB}
                          {interactionName}
                        </>
                      ) : (
                        <>
                          <span className={`${queueClick ? "blue cursor-pointer" : ""}`} onClick={handleQueue}>
                            {queueName}
                          </span>
                          {icon.RIGHT_BREADCRUMB}
                          {project}
                        </>
                      )
                    ) : pageParamState.queueId ? (
                      mode === 1 ? (
                        <>
                          <span className={`${queueClick ? "blue cursor-pointer" : ""}`} onClick={handleQueue}>
                            {pageParamState.queueName}
                          </span>
                          {icon.RIGHT_BREADCRUMB}
                          {interactionName}
                        </>
                      ) : (
                        pageParamState.queueName
                      )
                    ) : mode === 1 ? (
                      <>
                        <span className={`${projectClick ? "blue cursor-pointer" : ""}`} onClick={handleProject}>
                          {project}
                        </span>
                        {icon.RIGHT_BREADCRUMB}
                        {interactionName}
                      </>
                    ) : (
                      project
                    )}
                  </span>
                )
              )}
            </div>
            <div className='d-flex align-items-center'>
              {contentMangementSystem ? (
                <div ref={addButtonRef}>
                  <MultiUploadDropdown handleAddTask={handleAddTask} handleAddReminder={handleAddReminder} popupSettings={popupSettings} addButtonRef={addButtonRef} />
                  {showReminderPopup && (
                    <ReminderContextProvider>
                      <AddReminderPopup showReminderPopup={showReminderPopup} setShowReminderPopup={setShowReminderPopup} popupAnchor={addButtonRef} />
                    </ReminderContextProvider>
                  )}
                </div>
              ) : (
                <div ref={addButtonRef}>
                  <SplitButton
                    popupSettings={popupSettings}
                    onItemClick={handleItemClick}
                    className='btn-width btn btn-primary mr-3 tour-tasks-add-task'
                    text={
                      isReminderPath ? (
                        <span id='header-add-task-button' title={tooltip.ADD_TASK_CTRLD}>
                          <span>{icon.BELL_ICON}</span> {label.ADD_REM}
                        </span>
                      ) : (
                        <span id='header-add-task-button' title={tooltip.ADD_TASK_CTRLD}>
                          <span>{icon.ADD_TASK}</span> {label.ADD_TASK}
                        </span>
                      )
                    }
                    items={isReminderPath ? itemsReminderPage : itemsHomePage}
                    onButtonClick={isReminderPath ? handleAddReminder : handleAddTask}
                  />
                  {showReminderPopup && (
                    <ReminderContextProvider>
                      <AddReminderPopup showReminderPopup={showReminderPopup} setShowReminderPopup={setShowReminderPopup} popupAnchor={addButtonRef} />
                    </ReminderContextProvider>
                  )}
                </div>
              )}
              {/* OverallSearch component */}
              <div className='app-search' ref={overallSearchRef}>
                {" "}
                <OverallSearch anchor={overallSearchRef} popupClass='header-search' />
              </div>
              {/* PlanMyDay component */}
              {!!auth.user.PlanMyDay && (
                <div className={`dropdown plan-my-day-header-icon`}>
                  {" "}
                  <PlanMyDay />
                </div>
              )}
              {/* BackForwardNavigation component */}
              <div className={`dropdown`}>
                {" "}
                <BackForwardNavigation />{" "}
              </div>
              {/* BadgeContainer for Notifications */}
              <div className='dropdown'>
                <BadgeContainer>
                  <div className='cursor-pointer' id='header-notification-box' onClick={handleNotificationButtonClick}>
                    <button type='button' id='button-header-notify-icon' className='btn header-item header-icon header-hover-btn' title={tooltip.NOTIFICATIONS}>
                      {icon.NOTIFICATIONS_ICON}
                    </button>
                    {/* Conditionally showing notification indicator */}
                    {notifications.notificationCount > number.ZERO && !notifications.showNotification && (
                      <span className='notification-newstream-indicator rounded-circle position-absolute notification-indicator '></span>
                    )}
                  </div>
                </BadgeContainer>
                {/* Conditionally showing Notifications component */}
                {notifications.showNotification && <Notifications />}
              </div>
              {/* UserDropdown */}
              <div className='d-inline-block dropdown'>
                <button type='button' id='button-header-profile-icon' className='btn header-item header-icon header-hover-btn' ref={buttonRef} onClick={() => handleDropdownClick()}>
                  {/* Conditionally rendering user profile picture or default icon */}
                  {auth.user.PhotoLink ? <img alt='Profile Pic' className='profile-picture rounded-circle object-fit-cover' src={auth.user.PhotoLink} /> : icon.ACCOUNT_CIRCLE}
                </button>
                {/* Conditionally showing UserDropdown */}
                <div ref={profileRef}>{openProfile && <UserDropdown openProfile={openProfile} buttonRef={buttonRef} toggleDropdown={handleDropdownClick} />}</div>
              </div>
            </div>
          </div>
          {/* Loader component */}
          {loading && <Loader />}
        </header>
      </Tooltip>
      {/* Notes component */}
      <Notes />
    </React.Fragment>
  );
};

export default MainHeader;
