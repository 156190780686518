import { notifyIcon, requestMethod } from "../../config/index";
import config from "../../env.config";
import { fetch } from "../../shared/services/api.service";
import { getNotification } from "../../utils/common";
import { checkApiSuccess } from "../../utils/index";

const CONTENT_MANAGER_API = `${config.BASE_URL.BASE_API_URL}/content`;

/**
 * API call for fetching organization content attachments
 * @param {Object} { parentId, companyId }
 * @returns organization content attachments
 * @author Muskan Thakur
 */
export const getContentAttachments = async ({ parentId, companyId }) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${CONTENT_MANAGER_API}/getContentAttachments`,
      params: { parentId: parentId, companyId: companyId },
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * API call for fetching organization content attachments count
 * @param {Object} companyId
 * @returns organization content attachments count
 * @author Muskan Thakur
 */
export const getContentAttachmentsCount = async ({ companyId }) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${CONTENT_MANAGER_API}/getContentAttachmentsCount`,
      params: { companyId: companyId },
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data.attachmentCount;
  }
};

/**
 * API call for adding organization content attachments
 * @param {Object} (It consists of payload, userId, companyId)
 * @author Muskan Thakur
 */
export const addContentAttachments = async ({ payload, userId, companyId }) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${CONTENT_MANAGER_API}/addContentAttachments`,
    params: { userId, companyId },
    data: payload,
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * API call for editing organization content attachments
 * @param {Object} payload
 * @author Muskan Thakur
 */
export const updateContentAttachment = async (payload) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${CONTENT_MANAGER_API}/updateContentAttachment `,
    data: payload,
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * API call for deleting organization content attachments
 * @param {Object} payload
 * @author Muskan Thakur
 */
export const deleteContentAttachment = async (payload) => {
  const requestConfig = {
    method: requestMethod.DELETE,
    url: `${CONTENT_MANAGER_API}/deleteContentAttachment `,
    data: payload,
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};
