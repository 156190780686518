import { fileAudioIcon, fileDataIcon, fileIcon, fileImageIcon, filePdfIcon, filePptIcon, fileTxtIcon, folderIcon } from "@progress/kendo-svg-icons";
import { format } from "date-fns";
import { dateFormat, label, notifyIcon, number, quote } from "../../config";
import { appendFieldsFromObject, createFormData } from "../../helper/formDataHelper";
import { addContentAttachments } from "../../shared/services/contentManger.services";
import store from "../../store";
import { getNotification } from "../../utils/common";

/**
 * Converts a file extension to an icon and type.
 *
 * @param {string} item - The file name or path.
 * @returns {Object|null} An object containing the icon and type, or null if no item is provided.
 */
export const convertExtensionToIcon = (item) => {
  if (!item) {
    return null;
  }
  const extension = item.split(".").length > 1 ? item.split(".")[1] : null;

  switch (extension ? extension.toLowerCase() : null) {
    case "pdf":
      return {
        svgIcon: filePdfIcon,
        type: "Data",
      };
    case "ppt":
    case "pptx":
      return {
        svgIcon: filePptIcon,
        type: "Data",
      };
    case "xlsx":
    case "xls":
      return {
        svgIcon: fileDataIcon,
        type: "Data",
      };
    case "jpg":
    case "png":
      return {
        svgIcon: fileImageIcon,
        type: "Image",
      };
    case "txt":
    case "doc":
    case "docx":
      return {
        svgIcon: fileTxtIcon,
        type: "Text",
      };
    case "mp3":
    case "mp4":
    case "mp":
      return {
        svgIcon: fileAudioIcon,
        type: "Text",
      };
    case null:
      return {
        svgIcon: folderIcon,
        type: label.FOLDER,
      };
    default:
      return {
        svgIcon: fileIcon,
        type: label.FOLDER,
      };
  }
};

/**
 * Extracts the name of a file or folder from a given path.
 *
 * @param {string} path - The file or folder path.
 * @returns {string} The name of the file or folder.
 */
export const getName = (path) => {
  if (!path) {
    return path;
  }
  return path.split("/").pop();
};

/**
 * Retrieves the folder path from a given file path.
 *
 * @param {string} path - The file path.
 * @returns {string} The folder path.
 */
export const getFolderPath = (path) => {
  if (!path) {
    return path;
  }
  const pathArr = path.split("/");
  pathArr.pop();
  return pathArr.join("/");
};

/**
 * Formats a date into a specified format using an internationalization object.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date.
 */
export const convertDateFormat = (date) => {
  return date ? format(new Date(date), dateFormat.CONTENT_ATTACHMENT_DATE_FORMAT) : date;
};

/**
 * Recursively maps and formats data items.
 *
 * @param {Array<Object>} data - The data items to map.
 * @returns {Array<Object>} The mapped and formatted data items.
 */
export const mapData = (data, parentPath) => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const sanitizedName = sanitizeName(item.name);
    const currentPath = parentPath ? `${parentPath}/${sanitizedName}` : sanitizedName;

    return {
      id: item.attachmentId || item.id,
      type: item.type,
      name: sanitizedName,
      path: currentPath,
      dateCreated: convertDateFormat(item.createdAt || null) || item.dateCreated,
      dateModified: convertDateFormat(item.updatedAt || null) || item.dateModified,
      size: item.type === label.FILE ? item.size : "",
      icon: convertExtensionToIcon(sanitizedName),
      items: item.items && item.items.length ? mapData(item.items, currentPath) : [],
      hasChildren: item.hasChildren,
      expanded: item.expanded || false,
      createdBy: item.createdBy,
      disabled: item.disabled || false,
      attachmentKey: item.type === label.FILE ? item.attachmentKey : "",
      folderTreeIcon: item.folderTreeIcon || null,
    };
  });
};

/**
 * helper function to create root folder objects
 */
const createRootFolder = (id, name, path, folderTreeIcon, options = {}) => ({
  id,
  type: label.FOLDER,
  name,
  path,
  items: [],
  hasChildren: false,
  disabled: true,
  folderTreeIcon,
  ...options,
});

/**
 * Constructs the My Library structure, including root folders and mapped state data.
 *
 * @param {Array<Object>} stateData
 * @returns {Array<Object>} - The constructed My Library folder structure.
 */
export const constructMyDrive = (stateData = []) => {
  const myDriveRoot = createRootFolder("root", "My Library", "/My Library", "CLOUD_DONE", {
    disabled: false,
  });

  const recentRoot = createRootFolder("root", "Recent", "/Recent", "RECENT_CONTENT");

  const sharedWithMeRoot = createRootFolder("root", "Shared with me", "/Shared with me", "SHARED_CONTENT");

  const trashRoot = createRootFolder("root", "Trash", "/Trash", "DELETE_ICON");

  const myDriveData = mapData(stateData, "/My Library");

  return [
    {
      ...myDriveRoot,
      items: myDriveData,
      hasChildren: myDriveData.some((data) => data.type === label.FOLDER),
    },
    { ...recentRoot },
    { ...sharedWithMeRoot },
    { ...trashRoot },
  ];
};

/**
 * Formats data using the `mapData` function.
 *
 * @param {Array<Object>} data - The data to format.
 * @returns {Array<Object>} The formatted data.
 */
export const formatData = (data, projectName) => {
  return mapData(data, projectName);
};

/**
 * Recursively constructs a folder tree from data.
 *
 * @param {Array<Object>} data - The data to process.
 * @param {Object} selectedItem - The selected folder or file.
 * @returns {Array<Object>} The folder tree.
 */
export const getFolderTree = (data, selectedItem = null) => {
  if (!data) {
    return data;
  }
  const newItems = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.path && !currentItem.path.includes(".")) {
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = getFolderTree(currentItem.items, selectedItem);
      }
      newItems.push({
        ...currentItem,
        selected: selectedItem ? selectedItem.path === currentItem.path : false,
      });
    }
  }
  return newItems;
};

/**
 * Searches for a specific item in a tree structure.
 *
 * @param {Array<Object>} data - The data tree to search.
 * @param {Object} selectedItem - The item to find.
 * @returns {Object|null} The found item or null.
 */
export const searchTreeItem = (data, selectedItem) => {
  if (!selectedItem) {
    return data;
  }

  for (let index = 0; index < data.length; index++) {
    const currentItem = data[index];
    if (currentItem.path === selectedItem.path) {
      return currentItem;
    }
    if (currentItem.items && currentItem.items.length) {
      const foundItem = searchTreeItem(currentItem.items, selectedItem);
      if (foundItem) {
        return foundItem;
      }
    }
  }
};

/**
 * Adds data items to a specified location in the tree.
 * @returns {Array<Object>} The updated data.
 */
export const addDataItem = async (data, selectedItem = null, files) => {
  const state = store.getState();
  const { user } = state.auth;

  if (selectedItem.id === label.ROOT) {
    const payload = {
      type: label.FILE,
      files: files.map((file) => file.getRawFile()),
    };
    const formData = createFormData();
    appendFieldsFromObject(formData, payload);
    const addedAttachments = await addContentAttachments({
      payload: formData,
      userId: user.id,
      companyId: user.companyId,
    });

    if (addedAttachments) {
      selectedItem.items = selectedItem.items || [];
      const newFiles = addedAttachments.map((attachment, index) => {
        const file = files[index];
        return {
          id: attachment.attachmentId,
          path: `${selectedItem.path}/${file.name}`,
          dateCreated: convertDateFormat(new Date()),
          dateModified: convertDateFormat(new Date()),
          size: file.size,
          name: file.name,
          type: attachment.type,
          icon: convertExtensionToIcon(file.name),
          items: [],
          hasChildren: attachment.hasChildren,
          createdBy: user.id,
          attachmentKey: attachment.attachmentKey,
        };
      });

      selectedItem.items.push(...newFiles);
    }

    return data;
  }

  if (!data) {
    return data;
  }

  return await Promise.all(
    data.map(async (item) => {
      const currentItem = { ...item };

      if (currentItem.path === selectedItem.path) {
        if (!currentItem.items) {
          currentItem.items = [];
        }

        const payload = {
          parentId: selectedItem.id,
          type: label.FILE,
          files: files.map((file) => file.getRawFile()),
        };

        const formData = createFormData();
        appendFieldsFromObject(formData, payload);

        const addedAttachments = await addContentAttachments({
          payload: formData,
          userId: user.id,
          companyId: user.companyId,
        });

        if (addedAttachments) {
          const newFiles = addedAttachments.map((attachment, index) => {
            const file = files[index];
            return {
              id: attachment.attachmentId,
              parentId: selectedItem.id,
              type: attachment.type,
              path: `${currentItem.path}/${file.name}`,
              name: file.name,
              dateCreated: convertDateFormat(new Date()),
              dateModified: convertDateFormat(new Date()),
              size: file.size,
              icon: convertExtensionToIcon(file.name),
              items: [],
              hasChildren: attachment.hasChildren,
              createdBy: user.id,
              attachmentKey: attachment.attachmentKey,
            };
          });

          currentItem.items.push(...newFiles);
        }
      } else if (currentItem.items && currentItem.items.length) {
        currentItem.items = await addDataItem(currentItem.items, selectedItem, files);
      }

      return currentItem;
    })
  );
};

/**
 * Adds a new folder to the data tree at a specified location.
 * @returns {Array<Object>} The updated data.
 */
export const addFolder = async (data, selectedItem, folderCount) => {
  const state = store.getState();
  const { user } = state.auth;

  if (selectedItem.id === label.ROOT) {
    const folderName = folderCount ? `${label.NEW_FOLDER} (${folderCount})` : label.NEW_FOLDER;
    const payload = {
      parentId: null,
      type: label.FOLDER,
      path: `${selectedItem.path}/${folderName}`,
      folderName: folderName,
    };

    let addedAttachment = await addContentAttachments({
      payload,
      userId: user.id,
      companyId: user.companyId,
    });

    if (addedAttachment && addedAttachment.length) {
      const newFolder = {
        id: addedAttachment[0].attachmentId,
        parentId: null,
        path: `${selectedItem.path}/${folderName}`,
        name: folderName,
        type: label.FOLDER,
        dateCreated: convertDateFormat(new Date()),
        dateModified: convertDateFormat(new Date()),
        icon: convertExtensionToIcon(folderName),
        items: [],
        hasChildren: addedAttachment[0].hasChildren,
        expanded: false,
        createdBy: user.id,
      };
      selectedItem.items.push(newFolder);
      if (!selectedItem.hasChildren) selectedItem.hasChildren = true;
    }

    return data;
  }

  if (!data) {
    return data;
  }

  const updatedData = await Promise.all(
    data.map(async (item) => {
      const currentItem = { ...item };

      if (currentItem.path === selectedItem?.path) {
        const folderName = folderCount ? `${label.NEW_FOLDER} (${folderCount})` : label.NEW_FOLDER;

        if (!currentItem.items) {
          currentItem.items = [];
        }
        const payload = {
          parentId: selectedItem.id,
          type: label.FOLDER,
          folderName: folderName,
          size: number.ONE_THOUSAND_TWENTY_FOUR,
          path: `${currentItem.path}/${folderName}`,
        };

        let addedAttachment = await addContentAttachments({
          payload,
          userId: user.id,
          companyId: user.companyId,
        });

        if (addedAttachment && addedAttachment.length) {
          const newFolder = {
            id: addedAttachment[0].attachmentId,
            parentId: selectedItem.id,
            name: folderName,
            type: label.FOLDER,
            path: `${currentItem.path}/${folderName}`,
            dateCreated: convertDateFormat(new Date()),
            dateModified: convertDateFormat(new Date()),
            icon: convertExtensionToIcon(folderName),
            items: [],
            hasChildren: addedAttachment[0].hasChildren,
            expanded: false,
            createdBy: user.id,
          };

          currentItem.items.push(newFolder);
          if (!currentItem.hasChildren) currentItem.hasChildren = true;
        }
      }
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = await addFolder(currentItem.items, selectedItem, folderCount);
      }

      return currentItem;
    })
  );

  return updatedData;
};

/**
 * Toggles the view button group state between grid and list views.
 *
 * @param {Object} btnGroupState - The current state of the button group.
 * @param {string} view - The desired view ("grid" or "list").
 * @returns {Object} The updated button group state.
 */
export const toggleViewBtnGroup = (btnGroupState, view) => {
  if (!btnGroupState.listView && view !== label.CMS_GRID) {
    return { gridView: false, listView: true };
  }
  if (!btnGroupState.gridView && view !== label.LIST) {
    return { gridView: true, listView: false };
  }
  return btnGroupState;
};

/**
 * Toggles the sort button group state between ascending and descending order.
 *
 * @param {Object} btnGroupState - The current state of the button group.
 * @param {string} curState - The current sort state ("asc" or "desc").
 * @returns {Object} The updated button group state.
 */
export const toggleSortBtnGroup = (btnGroupState, curState) => {
  if (!btnGroupState.sortDesc && curState !== "asc") {
    return { sortAsc: false, sortDesc: true };
  }
  if (!btnGroupState.sortAsc && curState !== "desc") {
    return { sortAsc: true, sortDesc: false };
  }
  return btnGroupState;
};

/**
 * Converts a file size in bytes to a human-readable format.
 *
 * @param {number} bytes - The size in bytes.
 * @param {number} [decimals=2] - The number of decimal places.
 * @returns {string} The formatted size string.
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * Edits a specific data item by updating its path and modification date.
 *
 * @param {Array<Object>} data - The current data.
 * @param {Object} selectedItem - The item to edit.
 * @param {string} newPath - The new path for the selected item.
 * @returns {Array<Object>} The updated data.
 */
export const editDataItem = (data, selectedItem, newPath, name) => {
  if (!data) {
    return data;
  }
  const newItems = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.path === selectedItem.path) {
      currentItem.path = newPath;
      currentItem.name = name;
      currentItem.dateModified = new Date();
    }

    if (currentItem.items) {
      currentItem.items = editDataItem(currentItem.items, selectedItem, newPath, name);
    }
    newItems.push(currentItem);
  }
  return newItems;
};

/**
 * Removes a specific data item from the tree structure.
 *
 * @param {Array<Object>} data - The current data.
 * @param {Object} selectedItem - The item to remove.
 * @returns {Array<Object>} The updated data with the item removed.
 */
export const removeDataItem = (data, selectedItem) => {
  if (!data) {
    return data;
  }
  const newItems = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.path !== selectedItem.path) {
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = removeDataItem(currentItem.items, selectedItem);
        const totalFolders = currentItem?.items?.filter((item) => item.type === label.FOLDER);
        if (!totalFolders?.length) {
          currentItem.hasChildren = false;
        }
      }

      newItems.push(currentItem);
    }
  }
  return newItems;
};

/**
 * Converts a selected item's path into breadcrumb data.
 *
 * @param {Object|null} selectedItem - The selected item to convert.
 * @returns {Array<Object>} The breadcrumb data.
 */
export const convertToBreadcrumbData = (selectedItem, project) => {
  let path = [];

  if (!selectedItem) {
    return [
      {
        id: project,
        name: project,
      },
    ];
  }

  if (selectedItem.path) {
    const items = selectedItem.path.split("/");
    let curItemPath = [];

    for (let i = 0; i < items.length; i++) {
      curItemPath.push(items[i]);

      if (items[i] === project) {
        path.push({
          id: project,
          name: items[i],
        });
      } else {
        path.push({
          id: curItemPath.join("/"),
          name: items[i],
        });
      }
    }
  }
  return path;
};

/** Updates the tree data with fetched children
 * @author Muskan Thakur
 */
export const updateTreeData = (data, folderId, children = [], expanded = false, markFetched = false) => {
  return data.map((item) => {
    if (item.id === folderId) {
      return {
        ...item,
        expanded,
        isFetched: markFetched ? true : item.isFetched, // Update only if explicitly marked
        items: children.length > 0 ? children : item.items || [],
      };
    }

    if (item.items && item.items.length > 0) {
      return {
        ...item,
        items: updateTreeData(item.items, folderId, children, expanded, markFetched),
      };
    }

    return item;
  });
};

/**
 *  Trim whitespace and problematic characters
 * @returns {sanitizeName}
 * @author Muskan Thakur
 */
export const sanitizeName = (name) => {
  const sanitized = name.trim().replace(/('|&#39;|\/)/g, ""); // Remove problematic characters

  // Ensure name is not empty after sanitization
  if (!sanitized) {
    getNotification(quote.NON_EMPTY_NAME, notifyIcon.WARNING_ICON);
    return false;
  }

  return sanitized;
};

/**splitter item details */
export const contentManagerSplitBtnItems = [
  { text: "Name", value: "path" },
  { text: "File Size", value: "size" },
  { text: "Date Created", value: "dateCreated" },
];
