import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { set } from "lodash";
import { createTaskViaSharedFlow } from "../../../../../actions/taskSidebar";
import { icon, label, notifyIcon, number, quote, route } from "../../../../../config";
import { getQueryParams } from "../../../../../helper/common";
import Notify from "../../../../../shared/components/Kendo/Notify";
import { createDynamicTeamName } from "../../../../../utils/common";
import { filterData } from "../../../../../utils/kendo";
import { DrutasDropdown } from "../../../../DrutasDropdown/DrutasDropdown";
import { assignedTypes } from "../../../../Tasks/tasks.constants";
import { handleTaskKeyUpdate } from "../../../../Tasks/tasks.service";
import {
  getAssignedIdElem,
  getCurrentProject,
  getFilterAssigneeList,
  getProjectList,
  getRelatedAssignedType,
  getRelatedAssignedTypeNew,
  handleGetProjectWorkflows,
  setDisabledForAssignment,
  userPartOfAssignedProject,
  userPartOfAssignedProjectNew,
} from "../../../sidebar.helper";
import { getSelectedWorkflowDetails, handleNewRelatedAssignment, handleRelatedAssignment, initialProjectChangeEffect, shareWorkflowEffect } from "../taskDetail.helper";
import TaskIsReturnable from "./TaskIsReturnable";
import { TaskAssignmentContext } from "./taskAssignment.context";

/**
 * TaskAssignmentId component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskAssignmentIdNew = () => {
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const location = useLocation();
  const dispatch = useDispatch();
  const [assignedIdList, setAssignedIdList] = useState([]);
  const [notify, setNotify] = useState(false);
  const [filterText, setFilterText] = useState("");
  const { currentTheme } = useThemeSwitcher();

  const assignmentState = useContext(TaskAssignmentContext);
  const {
    assignmentType,
    assignedId,
    setAssignedId,
    setExternalUser,
    setCurrentProject,
    setWorkflowAssignmentType,
    isDisabled,
    setIsDisabled,
    setAssignmentType,
    selection,
    setSelection,
    delegationType,
  } = assignmentState;

  const contentRef = useRef();

  const { defaultDetails } = useSelector((state) => state.tasks);
  const { task, isNewTask, newTaskData, sharedWorkflowDetail, showTaskSidebar } = useSelector((state) => state.taskSidebar);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.assignedId);
  const { user } = useSelector((state) => state.auth);

  const createTask = getQueryParams("createTask", location.search) || sharedWorkflowDetail.createTask;
  const sharedProjectId = getQueryParams("pId", location.search) || sharedWorkflowDetail.sharedProjectId;
  const sharedWorkflowId = getQueryParams("fId", location.search) || sharedWorkflowDetail.sharedFlowId;
  const ProjectName = assignmentType?.key === number.TWO ? assignedId?.label : assignedId?.ProjectName;

  const dynamicPlaceholder = useMemo(() => {
    if (assignmentType?.key === number.TWO) return quote.SEARCH_USER;
    if (assignmentType?.key === number.THREE) return quote.SEARCH_TEAM;
    if (assignmentType?.key === number.FOUR) return quote.SEARCH_TEAM_USER;
  }, [assignmentType?.key]);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isNewTask) {
      const queueParams = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH });
      setDisabledForAssignment({ task, setIsDisabled, delegationType, queueParams });
    }
  }, [task.taskId, isNewTask, setDisabledForAssignment, task.IsLocked]);

  // set dd2 list based on dd1
  useEffect(() => {
    if (assignmentType) {
      getAndFilterAssigneIdList();
    }
  }, [assignmentType]);

  //sets dd2 existing value in case of existing task
  useEffect(() => {
    (async () => {
      if (task?.taskId) {
        const assignee = await getAssignedIdElem(defaultDetails, task?.CurrentAssignedType, task.CurrentAssignedId);
        setSelection(["team", "user"]);
        setAssignmentType(assignedTypes?.find((type) => Number(type.key) === Number(number.FOUR)));
        setAssignedId(assignee);
        await handleGetProjectWorkflows(assignmentType == number.TWO ? parseInt(assignee?.myProjectId) : parseInt(assignee?.value));
      }
    })();
  }, [task?.taskId, task?.CurrentAssignedId]);

  useEffect(() => {
    if (task?.CurrentRelatedAssignedType) {
      const assignedTypePayload = { AssignedType: task?.CurrentAssignedType, AssignedId: task?.CurrentAssignedId, RelatedAssignedType: task?.CurrentRelatedAssignedType };
      const currentRelatedAssignedType = getRelatedAssignedTypeNew(assignedTypePayload);
      const workflowAssignedType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value == currentRelatedAssignedType);
      setWorkflowAssignmentType(workflowAssignedType ? workflowAssignedType : null);
    }
  }, [task?.taskId, task?.CurrentRelatedAssignedType]);

  //sets assignId in redux in case of new task
  useEffect(() => {
    (async () => {
      if (isNewTask) {
        await handleTaskKeyUpdate(isNewTask, "assignedId", assignedId?.value);
        await handleTaskKeyUpdate(isNewTask, "assignedType", assignedId?.AssignmentType);
      }
    })();
  }, [isNewTask, assignedId]);

  //sets dd1, dd2, dd3, dd4 when base project changes
  useEffect(() => {
    (async () => {
      if (isNewTask && !createTask) {
        const payload = { defaultDetails, assignmentState, isNewTask, task, newTaskData, user };
        await initialProjectChangeEffect(payload);
      }
    })();
  }, [isNewTask, newTaskData?.project]);

  /**
   * sets dd1, dd2, dd3, dd4 for shared workflow url
   * @author Shivam
   */
  useEffect(() => {
    (async () => {
      if (createTask && newTaskData?.project) {
        const payload = { defaultDetails, assignmentState, isNewTask, task, newTaskData, user, sharedProjectId, sharedWorkflowId };
        await shareWorkflowEffect(payload);
        dispatch(createTaskViaSharedFlow({ createTask: false, sharedFlowId: null, sharedProjectId: null }));
      }
    })();
  }, [createTask, newTaskData?.project]);

  /**
   * handles assignId change incase of user assignment
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleUserAssignedId = async (selectedAssignId) => {
    setNotify(true);
    setExternalUser(true);
    const payload = { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task };
    await handleNewRelatedAssignment(payload);
  };

  /**
   * handles assignId change incase of project assignment
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleProjectAssignedId = async (selectedAssignId) => {
    const payload = { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task };
    await handleNewRelatedAssignment(payload);
  };

  /**
   * makes required changes when assignId is changed
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
  const assignIdStateChanges = async (selectedAssignId) => {
    setAssignedId(selectedAssignId);
    await setCurrentProject(getCurrentProject({ assignmentType, assignedId: selectedAssignId }));
    const requiredWorkflowAssignmentType = defaultDetails?.relatedAssignmentTypeList.find(
      (type) => type.value == (userPartOfAssignedProjectNew({ ...assignmentState, assignedId: selectedAssignId }) ? number.TWO : number.FIVE)
    );
    setWorkflowAssignmentType(requiredWorkflowAssignmentType ? requiredWorkflowAssignmentType : null);
  };

  /**
   * handles assignedId change
   * @param {Object} event
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleChange = async (event) => {
    if (event) {
      const selectedAssignId = event;
      await assignIdStateChanges(selectedAssignId);
      switch (selectedAssignId?.AssignmentType) {
        case number.TWO:
          const userProjectWorkflows = selectedAssignId?.myProjectId && (await handleGetProjectWorkflows(parseInt(selectedAssignId?.myProjectId)));
          isNewTask && (await getSelectedWorkflowDetails(userProjectWorkflows, selectedAssignId?.myProjectId));
          handleUserAssignedId(selectedAssignId);
          return;
        case number.THREE:
          const selectedProjectWorkflows = selectedAssignId?.ProjectId && (await handleGetProjectWorkflows(parseInt(selectedAssignId?.ProjectId)));
          isNewTask && (await getSelectedWorkflowDetails(selectedProjectWorkflows, selectedAssignId?.ProjectId));
          handleProjectAssignedId(selectedAssignId);
          return;
        case number.FOUR:
          if (selectedAssignId?.QueueId) {
            const selectedProjectWorkflows = selectedAssignId?.ProjectId && (await handleGetProjectWorkflows(parseInt(selectedAssignId?.ProjectId)));
            isNewTask && (await getSelectedWorkflowDetails(selectedProjectWorkflows, selectedAssignId?.ProjectId));
            handleProjectAssignedId(selectedAssignId);
            return;
          } else {
            const userProjectWorkflows = selectedAssignId?.myProjectId && (await handleGetProjectWorkflows(parseInt(selectedAssignId?.myProjectId)));
            isNewTask && (await getSelectedWorkflowDetails(userProjectWorkflows, selectedAssignId?.myProjectId));
            handleUserAssignedId(selectedAssignId);
            return;
          }
        default:
          break;
      }
    }
  };

  /**
   * sets assignedId list on the basis of assignmentType
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */

  const getAndFilterAssigneIdList = (event) => {
    setFilterText(event?.target?.value ? event?.target?.value : "");
    switch (assignmentType?.key) {
      case number.TWO:
        const customFilterUser = event
          ? {
              logic: "or",
              filters: [
                { field: "Email", operator: "contains", value: event?.target?.value },
                { field: "label", operator: "contains", value: event?.target?.value },
              ],
            }
          : null;
        let assigneeList = getFilterAssigneeList();
        assigneeList = assigneeList?.map((assignee) => ({ ...assignee, AssignmentType: 2 }));
        assigneeList = event ? filterData(customFilterUser, assigneeList) : assigneeList;
        setAssignedIdList(assigneeList);

        break;
      case number.THREE:
        const customFilterProject = event
          ? {
              logic: "or",
              filters: [
                { field: "ProjectCode", operator: "contains", value: event.target.value },
                { field: "label", operator: "contains", value: event.target.value },
              ],
            }
          : null;
        let projects = getProjectList();
        projects = projects?.map((project) => ({ ...project, AssignmentType: 3 }));
        projects = event ? filterData(customFilterProject, projects) : projects;
        setAssignedIdList(projects);
        break;
      case number.FOUR:
        const customFilterHybrid = event
          ? {
              logic: "or",
              filters: [
                { field: "Email", operator: "contains", value: event.target.value },
                { field: "label", operator: "contains", value: event.target.value },
                { field: "ProjectCode", operator: "contains", value: event.target.value },
                { field: "label", operator: "contains", value: event.target.value },
              ],
            }
          : null;
        let assignees = getFilterAssigneeList();
        assignees = assignees?.map((assignee) => ({ ...assignee, AssignmentType: 2 }));
        let teams = getProjectList();
        teams = teams?.map((project) => ({ ...project, AssignmentType: 3 }));

        let lists = [...teams, ...assignees];
        lists = event ? filterData(customFilterHybrid, lists) : lists;
        setAssignedIdList(lists);
      default:
        break;
    }
  };

  /**
   * custom dropdown Item render for DD2
   * @returns JSX
   * @author Prachi Jain
   */
  const itemRenderNew = (item, handleOnClick) => {
    let colorCode = currentTheme == "dark" ? "white" : "#000000";
    return (
      <>
        {item?.AssignmentType === number.TWO ? (
          <div className='d-flex align-items-center drutas-dropdown-popup-element px-3 py-2 truncate-text' onClick={() => handleOnClick(item)}>
            <div className='profile-picture-container mr-3'>{item?.PhotoLink ? <img src={item?.PhotoLink} className='profile-picture' /> : <span>{icon.USER_ICON}</span>}</div>
            <div className='truncate-text d-flex align-items-center'>
              <div className='truncate-text'>
                <div className='assignee-name truncate-text'>{item?.label}</div>
                <div className='assignee-email truncate-text'>{item?.Email}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex align-items-center drutas-dropdown-popup-element px-3 py-2 truncate-text' onClick={() => handleOnClick(item)}>
            <div className='profile-picture-container mr-3 ' style={{ color: item?.Privacy == number.ONE ? "#56B2D9" : item?.Privacy == number.TWO ? colorCode : "maroon" }}>
              {icon.PROJECT_ICON}
            </div>
            <div className='truncate-text d-flex align-items-center'>
              <div className='truncate-text'>
                <div className='assignee-name truncate-text'>{item?.label}</div>
                <div className='assignee-email truncate-text'>{item?.ProjectCode}</div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  /**
   * custom dropdown value render for DD2
   * @returns JSX
   * @author Prachi Jain
   */
  const valueRenderNew = (item) => {
    let colorCode = currentTheme == "dark" ? "white" : "#000000";
    return (
      <>
        {item?.AssignmentType === number.TWO ? (
          <div className='d-flex align-items-center justify-content-between drutas-dropdown-popup-element truncate-text'>
            <div className='d-flex align-items-center d-flex align-items-center truncate-text'>
              <div className='image-box mr-3'>{item?.PhotoLink ? <img src={item?.PhotoLink} /> : <span className='assignee-icon'>{icon.USER_ICON}</span>}</div>
              <div className='truncate-text'>
                <div className='assignee-name truncate-text'>{item.label}</div>
              </div>
            </div>
            <span>{icon.ARROW_DROP_DOWN_ICON}</span>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-between drutas-dropdown-popup-element truncate-text'>
            <div className='d-flex align-items-center'>
              <div className='image-box mr-3 ' style={{ color: item?.Privacy == number.ONE ? "#56B2D9" : item?.Privacy == number.TWO ? colorCode : "maroon" }}>
                {icon.PROJECT_ICON}
              </div>
              <div className='truncate-text'>
                <div className='assignee-name truncate-text'>{item?.label}</div>
              </div>
            </div>
            <span>{icon.ARROW_DROP_DOWN_ICON}</span>
          </div>
        )}
      </>
    );
  };

  const handleOnOpen = () => {
    setFilterText("");
    getAndFilterAssigneIdList();
  };

  /**
   * handles User and Team filter logic
   * @param {*} filterType
   * @returns
   */
  const handleFilter = (filterType) => {
    if (filterType === "user") {
      if (selection?.includes("user") && selection.length === number.TWO) {
        const newSelections = selection?.filter((item) => item !== filterType);
        setAssignmentType(assignedTypes?.find((type) => type.key === number.THREE));
        setSelection(newSelections);
      } else {
        if (selection?.includes("user")) return;
        const newSelections = [...selection, filterType];
        if (newSelections.length === 2) {
          setAssignmentType(assignedTypes?.find((type) => type.key === number.FOUR));
        }
        setSelection(newSelections);
      }
      return;
    }

    if (filterType === "team") {
      if (selection?.includes("team") && selection.length === number.TWO) {
        const newSelections = selection?.filter((item) => item !== filterType);
        setAssignmentType(assignedTypes?.find((type) => type.key === number.TWO));
        setSelection(newSelections);
      } else {
        if (selection?.includes("team")) return;
        const newSelections = [...selection, filterType];
        if (newSelections.length === 2) {
          setAssignmentType(assignedTypes?.find((type) => type.key === number.FOUR));
        }
        setSelection(newSelections);
      }
      return;
    }
  };

  const clearFilterText = () => {
    getAndFilterAssigneIdList();
  };

  const filtersContent = () => {
    return (
      <>
        <div className='p-3 pb-2'>
          <div className='d-flex align-items-center justify-content-between'>
            <span>Search with</span>
            <div className='assignto-toggle-btn p-1'>
              <button className={`btn mr-2 ${selection?.includes("team") ? "btn-primary" : ""}`} onClick={() => handleFilter("team")}>
                {icon.GROUP} Teams
              </button>
              <button className={`btn ${selection?.includes("user") ? "btn-primary" : ""}`} onClick={() => handleFilter("user")}>
                {icon.PERSON} Users
              </button>
            </div>
          </div>
          <div className='assignto-search mt-3 position-relative'>
            <span className='assignto-search-icon'>{icon.SEARCH}</span>
            <input autoFocus={true} className='assignto-search-field w-100 p-1' placeholder={dynamicPlaceholder} value={filterText} onChange={getAndFilterAssigneIdList} />
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <div
        className={`tour-task-sidebar-asignmentId form-group col-lg-6 col-md-6 col-sm-6 ${
          showTaskDetailPanel && !isNewTask ? "text-truncate mr-3" : "d-flex align-items-left flex-column task-returnable "
        }`}>
        {showTaskDetailPanel && !isNewTask ? (
          <>
            <label className='mr-2' htmlFor=''>
              {label.ASSIGN_TO}
            </label>
            <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
              <div className='text-truncate' title={ProjectName}>
                {ProjectName}
              </div>
            </Tooltip>
          </>
        ) : (
          <div className='d-flex mt-auto'>
            <div className='w-100'>
              <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
                <div className='d-flex'>
                  <div className='task-assignee w-100'>
                    <div ref={contentRef}>
                      <div className='d-flex align-content-center align-content-between'>
                        <label className='mr-2' htmlFor=''>
                          {label.ASSIGN_TO}
                        </label>
                        <TaskIsReturnable />
                      </div>
                      <DrutasDropdown
                        value={assignedId}
                        onChange={handleChange}
                        data={assignedIdList}
                        label={"label"}
                        key={"value"}
                        isfilter={true}
                        filterContent={filtersContent}
                        itemRender={itemRenderNew}
                        valueRender={valueRenderNew}
                        disabled={isDisabled}
                        popupClass='dd2-height'
                        clearFilterText={clearFilterText}
                      />
                    </div>
                    {notify && <Notify message={createDynamicTeamName(quote.EXTERNAL_TASK, user.operationalTeamName)} icon={notifyIcon.WARNING_ICON} />}
                    {loader && <div className='preloader loader-wrap'></div>}
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(TaskAssignmentIdNew);
