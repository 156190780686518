import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Input } from "@progress/kendo-react-inputs";
import { closeProjectSidebar } from "../../../../actions/projectSidebar";
import { addProjectWorkflows } from "../../../../actions/projectWorkflows";
import { button, icon, label, notifyIcon, number, quote, route } from "../../../../config";
import { getWorkflowUrl } from "../../../../helper/common";
import { getProjectWorkflows } from "../../../../shared/services/projects.service";
import { editSorting } from "../../../../shared/services/workflow.services";
import { createDynamicTeamName, getNotification, joinString } from "../../../../utils/common";
import { DragRowWithDragEnd, GridContext, reordering } from "../../../../utils/kendo";
import "../../../Workflow/WorkflowSharePopup.scss";
import "../../projectSidebar.scss";
import WorkflowDefaultAssignee from "./WorkflowDefaultAssignee";
import WorkflowInformationPopup from "./WorkflowInformationPopup";

const Drag = (props) =>
  props.dataItem.IsDefault ? (
    <td>
      <span>{icon.DEFAULT_PROJECT}</span>
    </td>
  ) : (
    <td>{props.dataItem.IsActive ? <DragRowWithDragEnd {...props} /> : <></>}</td>
  );
const Name = (props) => (
  <td>
    <WorkflowInformationPopup {...props} />
  </td>
);
const Icon = (props) => (
  <td>
    <WorkflowIcon {...props} />
  </td>
);
const PanelPopup = (props) => (
  <td>
    <WorkflowDefaultAssignee {...props} />
  </td>
);

/**
 * Parent Component: ProjectSidebarSections
 * Child Component: WorkflowInformationPopup, WorkflowDefaultAssignee
 * It comprises of project workflows with their icons and quick settings
 * @author Muskan Thakur
 */
const WorkflowPanel = () => {
  const { projectDetails, updatedFields } = useSelector((state) => state.projectSidebar);
  const { user } = useSelector((state) => state.auth);

  const [workflows, setWorkflows] = useState(null);
  const [workflowAccess, setWorkflowAccess] = useState(null);
  const [reorderedData, setReorderedData] = useState(null);
  const [notifyUserDisableSave, setNotifyUserDisableSave] = useState(null);
  const [notifySharedWorkflow, setNotifySharedWorkflow] = useState(null);
  const [activeItem, setActiveItem] = useState();
  const [searchText, setSearchText] = useState("");
  const [allWorkflows, setAllWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const searchInputRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    searchInputRef?.current?.focus();
  }, [searchInputRef.current]);

  /**sets project workflows */
  useEffect(() => {
    (async () => {
      if (projectDetails.ProjectId) {
        setLoading(true);
        const projectWorkflows = await dispatch(getProjectWorkflows({ projectId: projectDetails.ProjectId }));
        if (projectWorkflows) {
          setWorkflows(projectWorkflows);
          setAllWorkflows([...projectWorkflows]);
          setLoading(false);
        }
      }
    })();
  }, [projectDetails.ProjectId]);

  useEffect(() => {
    setWorkflowAccess(projectDetails?.RoleId || projectDetails.ProjectId == user.myProjectId);
  }, [projectDetails]);

  /**sets notify user condition in case all workflows and project is closed and user changes the project privacy to open */
  useEffect(() => {
    setNotifyUserDisableSave(workflows?.map((workflow) => workflow?.WorkflowPrivacy)?.every((item) => item === number.TWO) && updatedFields.Privacy == number.ONE);
  }, [notifyUserDisableSave, updatedFields.Privacy]);

  /**sets notify shared worklfow condition if there is only one workflow which is not shared and user changes the project privacy to open */
  useEffect(() => {
    workflows?.length && setNotifySharedWorkflow(workflows?.length == number.ONE && workflows[number.ZERO]?.WorkflowPrivacy != number.ONE && updatedFields.Privacy == number.ONE);
  }, [workflows, updatedFields.Privacy]);

  /**notifies the user a/c to the condition*/
  useEffect(() => {
    notifyUserDisableSave && getNotification(quote.MAKE_WORKFLOW_SHARED, notifyIcon.WARNING_ICON);
  }, [notifyUserDisableSave]);

  /**notifies the user a/c to the condition*/
  useEffect(() => {
    notifySharedWorkflow && getNotification(createDynamicTeamName(quote.DEFAULT_WORKFLOW_SHARED, user.operationalTeamName), notifyIcon.WARNING_ICON);
  }, [notifySharedWorkflow]);

  /**
   * to get the order of workflow
   * @param {*} reorderedData
   * @returns order of workflow
   */
  const getEntities = (reorderedData) => {
    const entityIds = reorderedData.map((data) => data.WorkflowId);
    dispatch(addProjectWorkflows({ projectId: projectDetails?.ProjectId, workflows: reorderedData }));
    return { entityIds };
  };

  /**
   * Reorders the data according to the drag and drop
   * @param {*} dataItem
   */
  const reorder = (dataItem) => {
    let reorderedData = reordering(dataItem, activeItem, workflows);
    if (reorderedData) setReorderedData(reorderedData);
  };

  /**
   * api call for editSorting when the workflow is dropped
   */
  const onDrop = () => {
    if (reorderedData) {
      const entity = getEntities(reorderedData);
      const entityIds = joinString(entity?.entityIds, ",");
      const workflowIds = joinString(
        workflows?.map((data) => data.WorkflowId),
        ","
      );

      if (entityIds !== workflowIds) {
        const payload = {
          entityIds: entityIds,
          entityName: route.PRIVATE_ROUTE.WORKFLOW.ENTITY_NAME,
        };
        dispatch(editSorting(payload));
      }
      setWorkflows(reorderedData);
    }
  };

  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  const workflowContextValue = {
    projectDetails,
    workflowAccess,
    setWorkflows,
    workflows,
    reorder: reorder,
    dragStart: dragStart,
    onDrop: (e) => onDrop(e),
    activeItem,
    setActiveItem,
  };

  /**renders row color based on its activity (white for active and grey for inactive workflow)  */
  const rowRender = (trElement, props) => {
    const trProps = { style: props.dataItem.IsActive ? { backgroundColor: "white" } : { backgroundColor: "rgb(0, 0, 0,0.04)" }, className: "project-workflow-grid-row" };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  const handleAddWorkflowButtonClick = () => {
    dispatch(closeProjectSidebar());
  };

  /**
   * Search workflow
   * @param {*} event
   * @author Himanshu Negi
   */
  const searchWorkflowHandler = (event) => {
    if (!event) return;
    setSearchText(event.target.value);
    if (event.target.value) {
      const filteredWorkflows = workflows?.filter((workflow) => workflow?.WorkflowName.toLowerCase().includes(event.target.value?.toLowerCase()));

      setWorkflows([...filteredWorkflows]);
    } else {
      setWorkflows([...allWorkflows]);
    }
  };

  return (
    <>
      <div className='form-row mb-2'>
        <div className='col-md-12 form-row justify-content-between'>
          <div className='col-md-6'>
            <Input id='workflow-panel-search-input' value={searchText} onChange={searchWorkflowHandler} placeholder={label.SEARCH} ref={searchInputRef} />
          </div>
          <Link to={getWorkflowUrl(projectDetails?.ProjectId, projectDetails?.ProjectName)} id='workflow-panel-wf-name-on-click' className='col-md-6 text-right'>
            <button className='btn btn-sm btn-primary' aria-label={quote.ADD_WORKFLOW} disabled={!workflowAccess} id='button-workflow-panel-add-wf' onClick={handleAddWorkflowButtonClick}>
              {button.ADD_WORKFLOW}
            </button>
          </Link>
        </div>
      </div>
      <div className='form-row '>
        <div className='form-group col-md-12'>
          {loading ? (
            <Skeleton shape='rectangle' className='card-skeleton mt-3 ml-2' />
          ) : (
            workflows &&
            workflows.length !== 0 && (
              <GridContext.Provider value={workflowContextValue}>
                <Grid data={workflows} rowRender={rowRender} className='dark-theme-grid freeze-grid-header'>
                  <Column cell={Drag} width='31px' />
                  <Column cell={Icon} width='31px' />
                  <Column cell={Name} title={label.NAME} />
                  <Column cell={PanelPopup} title={label.SETTINGS} width='90px' />
                </Grid>
              </GridContext.Provider>
            )
          )}
        </div>
      </div>
    </>
  );
};
export default WorkflowPanel;

/**renders workflow Icon (color based on workflow privacy) */
const WorkflowIcon = (props) => {
  return <span style={{ color: props.dataItem?.WorkflowPrivacy == number.ONE ? "#56b2d9" : props.dataItem?.WorkflowPrivacy == number.TWO ? "black" : "maroon" }}>{icon[props.dataItem?.Icon]}</span>;
};
