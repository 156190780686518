import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupPropsContext } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import RelationList from "../../components/OverallSearch/RelationList";
import { getRelationData, openSidebar } from "../../components/Tasks/tasks.service";
import { icon, label } from "../../config";
import { usePopup } from "../../helper/commonHooks";
import { formatDate } from "../editor.helper";
import TaskDetailsContent from "./TaskDetailsContent";
import TaskIcons from "./TaskIcons";
import "./taskDetail.scss";

/**
 * Non Editable Editor
 * PC KendoEditor
 * @author Shivam Mishra
 */
const TaskDetails = ({ taskInfo, hashPopupVisible, url, copyToClipboard }) => {
  const { Name, ProjectTaskId, Priority, Assignee, DueDate, IsTaskComplete } = taskInfo;
  const { show, setShow, anchor, contentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const { task } = useSelector((state) => state.taskSidebar);
  const { assigneeList } = useSelector((state) => state.tasks.defaultDetails);
  const userInfo = assigneeList?.find((user) => user.value === Assignee);
  /**
   * load link task relation list.
   * @call {*} getLinkTasks
   */
  useEffect(() => {
    (async () => {
      await dispatch(getRelationData());
    })();
  }, []);

  return (
    <>
      {hashPopupVisible ? (
        <div className='details-popup-minwidth'>
          <div className='task-details-container mb-2'>
            <Tooltip parentTitle={true} position='bottom'>
              <span className='task-label'>
                {ProjectTaskId} {IsTaskComplete ? icon.GREEN_CHECK : icon.CHECK_ICON}
              </span>
            </Tooltip>
            <TaskIcons url={url} taskInfo={taskInfo} copyToClipboard={copyToClipboard} anchor={anchor} setShow={setShow} setShowPopup={setShowPopup} />
          </div>
          <div className='horizontal-divider'></div>
          <TaskDetailsContent taskInfo={taskInfo} userInfo={userInfo} />
        </div>
      ) : (
        <div className='row'>
          {task.taskId && (
            <div
              className='editor-link-icon position-absolute cursor-pointer '
              ref={anchor}
              onClick={() => {
                setShowPopup(true);
                setShow(true);
              }}>
              {icon.MEDIATION}
            </div>
          )}
          <div className='col-md-12'>
            <div className='container pt-2 pb-2 details-popup-minwidth'>
              <div className='d-flex d-flex justify-content-between justify-content-center align-items-center mt-3 mb-3'>
                <div>{ProjectTaskId}</div>
                <div className='ml-2 mr-2'>{Name}</div>
                <div className='d-flex justify-content-center'>{IsTaskComplete ? icon.GREEN_CHECK : icon.CHECK}</div>
              </div>
              <div className='d-flex d-flex justify-content-between justify-content-center align-items-center'>
                <div>{userInfo.label ?? label.UNASSIGNED}</div>
                <div className='ml-2 mr-2'>{icon[Priority]}</div>
                <div>{formatDate(DueDate)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <PopupPropsContext.Provider
          value={(props) => ({
            ...props,
            appendTo: anchor.current,
          })}>
          <RelationList
            anchor={anchor}
            hashPopupVisible={hashPopupVisible}
            show={show}
            setShowPopup={setShowPopup}
            selectedItem={taskInfo}
            relationListRef={contentRef}
            blurTimeoutRef={blurTimeoutRef}
            onOpen={onOpen}
            onFocus={onFocus}
            onBlur={onBlur}
            setShow={setShow}
          />
        </PopupPropsContext.Provider>
      )}
    </>
  );
};

export default React.memo(TaskDetails);
