import { number } from "../../config";

export const WORKFLOW_DEFAULT_VALUES = {
   active: true,
   showDescription: true,
   showAttachment: true,
   showSubChildTask: true,
   showComment: true,
   showTaskDescPopup: false,
   tags: [],
   tagIds: [],
   follower: [],
   taskName: "",
   dueDateDays: number.THREE,
   archiveDays: number.ONE,
   selectFirstStage: true,
   reminder: [],
   startFlowAfter: number.ZERO,
   isDefault: false,
   desc: "",
   subtaskData: [],
   childtaskData: [],
   attachments: [],
   flowElementData: [],
   existingFlowElements: [],
   existingSubtask: [],
   existingChildtask: [],
   stages: [],
   simplifiedDetail: true,
   effort: number.ZERO,
   workflowReminders: []
}

export const projectWorkflowKeyMap = {
   'name': 'WorkflowName',
   'description': 'WorkflowDescription',
   'taskName': 'TaskName',
   'privacy': 'WorkflowPrivacy',
   'isActive': 'IsActive',
   'taskPriority': 'TaskPriority',
   'taskDueDateAfter': 'TaskDueDateAfter',
   'defaultAssignee': 'DefaultAssignee',
   'icon': 'Icon'
}

export const workflowLabels = {
  WORKFLOW_DETAILS: "Workflow Details"
}
export const workflowQuote = {
  TASK_DETAILS_PANEL: "Task name, priority, and reminders will be configured based on the settings selected below for tasks created using this workflow",
  TAGS_PANEL: "The selected tags will be added to every task created using this workflow",
  FOLLOWERS_PANEL: "The selected followers will be added to every task created using this workflow",
  TIMESHEET_PANEL: "The expected efforts set the due date for every task created using this workflow",
  DESCRIPTION_PANEL: "The task description will be copied from the section below for each task created using this workflow",
  ATTACHMENTS_PANEL: "Attachments added here will be included in every task created using this workflow",
  SUB_TASK_CHILD_TASK_PANEL: "Sub-Tasks and Child Tasks added here will be included in every task created using this workflow",
  COMMENTS_PANEL: "If the comments toggle is enabled, the comments panel will be visible for every task created using this workflow",
  EMAIL_INTEGRATIONS: "Tasks can be auto-created for every email received. To enable this, turn on 'Incoming Emails",
  INTERNAL_FLOW_ELEMENTS: "Tasks will be automatically assigned to team members at each stage change for every task created using the workflow"

}
