import { Popup } from '@progress/kendo-react-popup';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleTaskKeyUpdate, updateDescMode } from '../../components/Tasks/tasks.service';
import { number, icon, label } from '../../config';
import { onOpen, onFocus, onBlur } from '../../utils/kendo';
import { MODE_ARRAY } from '../editor.Constant';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { EditorSvgIcon } from './EditorSvgIcon';

/**
* Update Mode  for Editor
* PC Editor Tolbar
* @PC editorTools
* @author Shivam mishra
*/
const EditorMode = () => {
  const dispatch = useDispatch();

  const anchor = React.useRef();
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const { mode, modeCreator, taskId } = useSelector((state) => state.taskSidebar.task);
  const { user } = useSelector((state) => state.auth);
  const { isNewTask , newTaskData} = useSelector((state) => state.taskSidebar);
  const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails)
  const modeItem = MODE_ARRAY.find((i) => i.modeId === (mode || newTaskData?.mode) ?? MODE_ARRAY[number.ZERO]);

  React.useEffect(() => {
    if(isNewTask && !cloneTask){
      handleTaskKeyUpdate(isNewTask, "modeCreator", user.id)
    }
  }, [isNewTask]);

  React.useEffect(() => {
    if(isCloneTask && isNewTask){
      handleTaskKeyUpdate(isNewTask, "mode", cloneTask?.mode)
      handleTaskKeyUpdate(isNewTask, "modeCreator", user?.id)
    }
  }, [isCloneTask]);

  /**
* Handles opening and closing of mode popup
* @param {None}
* @author Shivam Mishra
*/
  const togglePopup = () => {
    setShow(!show);
  };

  /**
  * updates mdes for editor
  * @param {Object} item - The arguments object.
  * @author Shivam Mishra
  */
  const updateMode = (item) => {
    if (isNewTask) {
      handleTaskKeyUpdate(isNewTask, "mode", item.modeId)
      handleTaskKeyUpdate(isNewTask, "modeCreator", user.id)
    }
    else dispatch(updateDescMode({ userId: user.id, taskId: taskId, mode: item.modeId }))
    togglePopup()
  }

  return (
    <div className='custom-toolbar-container'>
        <div onClick={togglePopup} ref={anchor} className={`btn custom-toolbar-btn font-weight-semi-bold ${modeItem ? modeItem.class : ''}`} title={modeItem?.mode ?? label.EDIT}>
          <div className='d-flex align-items-center'>
            {modeCreator ? modeItem?.icon : icon.KEYBOARD_HIDE}
            <span className='editor-tool-down-caret'>
              <EditorSvgIcon />
            </span>
          </div>
        </div>
      <Popup anchor={anchor.current} show={show}
        collision={{
          horizontal: "fit",
          vertical: "flip",
        }}
        onOpen={() => onOpen(deleteRef)}
        className={'editor-mode-container'}
      >
        <div
          tabIndex={number.ZERO}
          ref={deleteRef}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, togglePopup)}
        >
          {MODE_ARRAY.map((item, index) =>
            <div className={`cursor-pointer w-100 d-block custom-toolbar-menu-item`} key={index} onClick={() => { updateMode(item) }}>
              <span className={`mr-2 p-1 ${item.class}`}>{item.icon}</span>
              <span className='p-1' >{item.mode}</span>
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
};

export default EditorMode;
