import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Switch } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import useOnClickOutside from "use-onclickoutside";
import { setOpenDashboardDesc, setUpdateLayoutDesc, updatesIsCollabCentralOpened } from "../../actions/common";
import CollabCentral from "../../components/CollabCentral/CollabCentral";
import { checkEntityOwner } from "../../components/Queues/QueueTasks/queue.common";
import { getEntityDescription } from "../../components/Tasks/tasks.service";
import { getEntityOwners, openTeamSidebar } from "../../components/TeamSideBar/teamSideBar.common";
import { icon, label, number, route } from "../../config";
import KendoEditor from "../../editor/KendoEditor";
import { splitString } from "../../utils/common";
import { getAllQueues } from "../services/queues.service";

/**
 * LayoutDescription for collab central
 * @parentComponent Header
 * @author Shivam Mishra
 */

const CollabCentralLayout = ({ anchor, setShowPopup, showPopup, isOrganisationDesc, hidePreview, classicMode }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const allQueues = useSelector((state) => state.allQueues);

  const editableRef = React.useRef(false);
  const viewRef = React.useRef(null);
  const editorRef = React.useRef(null);
  const layoutDescRef = React.useRef(true);

  const [isEditable, setIsEditable] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [desc, setDesc] = useState("");
  const [isClassicMode, setIsClassicMode] = useState(classicMode);

  const pathname = location.pathname;
  const [page, entityId] = splitString(pathname, "/").slice(number.ONE, number.THREE);
  const entityIdToUse = isOrganisationDesc ? user.companyId : entityId ? entityId : parseInt(user.myProjectId);
  const entityName = isOrganisationDesc ? label.COMPANY : page === "queuetasks" ? label.QUEUE_ENTITY : label.PROJECT;
  const isEditAccess = isOrganisationDesc ? false : entityId ? checkEntityOwner(getEntityOwners(entityName, entityIdToUse)) : true;

  /**
   * fetches all queues and closes open task sidebar
   * @author Shivam
   */
  useEffect(() => {
    dispatch(updatesIsCollabCentralOpened(true));
    const action = getAllQueues();
    !allQueues?.length && dispatch(action);
  }, []);

  /**
   * fetches the description
   * @author Shivam
   */
  const fetchData = async () => {
    const description = await dispatch(getEntityDescription(entityIdToUse, entityName, user.id));
    setDesc(description ?? "");
  };

  useEffect(() => {
    if (isClassicMode) fetchData();
    setShowDesc(true);
  }, [isClassicMode]);

  /**
   * Opens description in edit mode
   * @param None
   * @return None
   * @author Shivam
   */
  const toggleEditable = () => {
    setShowPopup();
    dispatch(setUpdateLayoutDesc(true));
    setIsEditable(!isEditable);

    if (entityName === label.PROJECT) {
      dispatch(setOpenDashboardDesc(true));
    }
    if (entityName === label.QUEUE_ENTITY) {
      openTeamSidebar(entityId);
    }
  };

  /**
   * Determine the appropriate icon for the editor based on conditions
   * @param None
   * @return IconNumber {integer}
   * @author Shivam
   */
  const getEditorEditIcon = () => {
    if (isEditAccess) return number.TWO;
    return number.FOUR;
  };

  /**
   * @author Shivam
   * redirects to edit page of collab central
   */
  const editCollabCentral = () => {
    setShowPopup();
    history.push(route.ADMIN_ROUTE.ROLES_PERMISSIONS.ROUTER_PATH);
  };

  const closeCollabCentral = () => {
    setShowPopup();
  };
  /**
   * toggles classic view
   *  @author Sarthak Arora
   */
  const handleClassicMode = () => {
    setIsClassicMode(!isClassicMode);
  };

  /**
   * closes the popup on outside click classic view
   *  @author Sarthak Arora
   */
  useOnClickOutside(layoutDescRef, (e) => {
    if (e.target.title === "layout-description" || e.target.id === "workflow-smart-link-option" || e.target.id === "workflow-url-option") {
      return;
    }
    setShowPopup();
  });

  return (
    <>
      <div id='layout-description-outer'>
        <Popup
          popupClass={"layout-description-editor position-relative border-0"}
          show={true}
          animate={false}
          collision={{
            horizontal: "fit",
            vertical: "flip",
          }}>
          <div ref={layoutDescRef} id='layout-description' className={showDesc ? "d-block" : "d-none"}>
            {isOrganisationDesc && showDesc && (
              <span>
                <h2 className='layout-description-header m-0'>{label.COLLAB_CENTRAL}</h2>
                <span className='position-absolute edit-pencil-icon' id='layout-description-close-icon' onClick={closeCollabCentral}>
                  {icon.CLOSE}
                </span>

                {isOrganisationDesc && user.isAdmin ? (
                  <div>
                    {isClassicMode && (
                      <span className='position-absolute edit-pencil-icon mr-5' id='layout-description-edit-icon' onClick={editCollabCentral}>
                        {icon.EDIT_DESCRIPTION}
                      </span>
                    )}
                  </div>
                ) : (
                  <span></span>
                )}
              </span>
            )}
            {isClassicMode ? (
              <div className={isOrganisationDesc ? "collab-central-editor" : "user-description-editor"}>
                <KendoEditor
                  desc={desc}
                  setDesc={setDesc}
                  viewRef={viewRef}
                  editableRef={editableRef}
                  toggleEditable={toggleEditable}
                  editorRef={editorRef}
                  editorEditIcon={getEditorEditIcon()}
                  hidePreview={hidePreview}
                  showEditIcons={true}
                />
              </div>
            ) : (
              <CollabCentral setShowPopup={setShowPopup} />
            )}
          </div>
        </Popup>
      </div>
    </>
  );
};
export default CollabCentralLayout;
