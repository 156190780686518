import React, { useEffect } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Loader } from "@progress/kendo-react-indicators";
import { number } from "../../../../config";
import { isImage, isPdf } from "../../../../shared/validators/validator";
import { isTrueBit } from "../../../../utils";
import { ImageModal } from "../Comments/ImageModal";
import AttachmentDeleteIcon from "./AttachmentDeleteIcon";
import AttachmentDownloadIcon from "./AttachmentDownloadIcon";
import "./Attachments.scss";
import AttachmentEditNameIcon from "./AttachmetEditNameIcon";
import { getAttachmentInfo, getFileExtension } from "./attachments.helper";

/**
 * Common component for Attachment render
 * Parent components => NewTaskAttachments, ExistingTaskAttachments
 * @param {*} props
 * @returns JSX
 * @author Himanshu Negi
 */
const CommonAttachment = (props) => {
  const {
    isNewTask,
    viewState,
    visible,
    link,
    attachments,
    task,
    projectId,
    addAttachmentHelper,
    handleFocus,
    fileSizeExceedMsg,
    showAttachmentIcon,
    toggleDialog,
    loaderType,
    showLoader,
    editAttachmentHandler,
    deleteAttachmentHandler,
    downloadAttachmentFile,
  } = props;

  /**
   * Returns JSX for attachment name
   * @param {Object} attachment
   * @returns {JSX}
   * @author Himanshu Negi
   */
  const getAttachmentName = (attachment) => {
    if (isImage(attachment.AttachmentName)) {
      return (
        <>
          <div id='common-image-attachments-name' className='link-text cursor-pointer mr-2 w-100 text-truncate' onClick={() => toggleDialog(attachment)}>
            {attachment.AttachmentName}
          </div>
          {attachment.CreatedOn && attachment.CreatedBy && <div className='date-time text-muted text-small'>{getAttachmentInfo(attachment)} </div>}
        </>
      );
    }

    return (
      <span id='common-attachments-name'>
        <span>
          <span
            className='link-text cursor-pointer mr-2 w-100 text-truncate'
            onClick={() => (isNewTask ? window.open(attachment.AttachmentLink, "_blank") : downloadAttachmentFile(attachment.AttachmentLink, attachment.AttachmentName))}>
            {attachment.AttachmentName}
          </span>
        </span>
        {attachment.CreatedOn && attachment.CreatedBy && <div className='date-time text-muted text-small'> {getAttachmentInfo(attachment)} </div>}
      </span>
    );
  };

  return (
    <React.Fragment>
      <div onClick={handleFocus}>
        <div className='w-100'>
          {!!(isNewTask || ((isTrueBit(task, "InApproval") || !task?.IsTaskComplete) && (!task?.isFollowed || viewState))) && (
            <>
              <label
                htmlFor='common-attachment-add-attachment-button'
                className={`${
                  projectId
                    ? "dashboard-attachment-button btn btn-sm blue font-weight-bold position-absolute dashboard-attachment-label"
                    : "add-attachment-button btn btn-sm btn-block mt-2 mb-3 btn-primary"
                }`}></label>
              <input id='common-attachment-add-attachment-button' title='' type='file' onChange={addAttachmentHelper} className='d-none' multiple />
            </>
          )}
          {showLoader && <Loader type={loaderType} className='infinite-spinner' />}
          {fileSizeExceedMsg && <p className={`red ${projectId && "text-right"}`}>{fileSizeExceedMsg}</p>}
        </div>
        <div>
          {attachments?.length > number.ZERO && (
            <div className={`file-uploaded-wrapper ${projectId && "project-dashboard-attachment"}`}>
              {attachments.map((attachment, index) => {
                const fileExtension = getFileExtension(attachment.AttachmentName);
                return (
                  <div className='file-uploaded d-flex align-items-center mb-10' key={attachment.AttachmentId}>
                    <div className='file-icon d-flex mr-2'>
                      <FileIcon extension={fileExtension} {...defaultStyles.png} />
                    </div>
                    <div className='file-name d-flex flex-column flex-grow-1'>{getAttachmentName(attachment)}</div>
                    <div className='d-flex'>
                      {showAttachmentIcon(attachment, viewState) && <AttachmentDeleteIcon attachment={attachment} deleteAttachmentHandler={deleteAttachmentHandler} />}
                      {showAttachmentIcon(attachment, viewState) && (
                        <AttachmentEditNameIcon attachment={attachment} editAttachmentHandler={editAttachmentHandler} attachmentName={attachment.AttachmentName} projectId={projectId} />
                      )}
                      {!isNewTask && showAttachmentIcon(attachment, viewState) && <AttachmentDownloadIcon attachment={attachment} />}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {visible && link && <ImageModal tag={`<img src=${link}>`} visible={true} link={link} toggleVisible={toggleDialog} />}
    </React.Fragment>
  );
};

export default CommonAttachment;
