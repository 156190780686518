import React, { useEffect, useState } from 'react';
import { icon, label } from '../../config';
import { useCombinationKeys } from '../../helper/commonHooks';
import './overallSearch.scss';
import OverallSearchPopup from './OverallSearchPopup';

const OverallSearch = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const ctrlF = useCombinationKeys("f");

  useEffect(() => {
    ctrlF && setShowPopup(true)
  }, [ctrlF]);

  return (
    <>
      <div className='overall-search-container'>
        <div className="position-relative d-none d-lg-block">
          <input
            title='Find... (Ctrl + F)'
            type="text"
            className="form-control search-input"
            placeholder="Find"
            autoComplete="off"
            onFocus={() => setShowPopup(true)}
          />
          <div className="overall-search-controls">
            <span
              className="alt-key-container"
              onClick={() => setShowPopup(true)}
            >
              {label.CTRL_F}
            </span>
            <span
              onClick={() => setShowPopup(true)}
            >
              {icon.SEARCH}
            </span>
          </div>
        </div>

        <div type="button" className="d-inline-block d-lg-none ms-2 dropdown">
          <button className="btn header-item header-icon header-hover-btn" onClick={() => setShowPopup(true)}>
            {icon.SEARCH}
          </button>
        </div>

        {showPopup &&
          <OverallSearchPopup
            anchor={props.anchor}
            relationStatus={props.relationStatus}
            setShowPopup={setShowPopup}
            entityName={props.entityName}
            popupClass={props.popupClass} />}
      </div>
    </>
  )
}

export default OverallSearch;
