import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { number } from "../../config";
import DynamicReminderPopup from "../Tasks/Reminders/DynamicReminderPopup";
import { useReminderContext } from "../Tasks/Reminders/ReminderContextProvider";
import WorkflowReminderInput from "../Tasks/Reminders/WorkflowReminderInput";
import "../Tasks/Reminders/reminder.scss";
import { useWorkflowContext } from "./WorkflowContextProvider";
import WorkflowReminderEditPopup from "./WorkflowReminderEditPopup";
import { useWorkflowDynamicReminderPopup, useWorkflowReminderList } from "./workflowRemindersHook";

const HandleWorkflowReminder = ({ show, anchor, toggleShowPopup, setShowPopup }) => {
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const { isAddClicked, isReminderClicked, setIsReminderClicked } = useReminderContext();
  const { workflowReminders, editName, editRem, editNoOfDays } = useWorkflowContext();
  const { projectWorkflows } = useSelector((state) => state);
  const { projectId, workflowId } = useParams();
  const anchorInputReminder = useRef(null);
  const defaultAssignee = useMemo(() => projectWorkflows[Number(projectId)]?.find((item) => item.WorkflowId === Number(workflowId))?.DefaultAssignee, [projectId, projectWorkflows, workflowId]);
  const { popupBodyContent, handleSaveReminder } = useWorkflowDynamicReminderPopup({ setShowPopup, defaultAssignee });

  const onClick = () => {
    setShowPopup(false);
  };

  const { handleClick, delReminder, setDelReminder, handleAddRem } = useWorkflowReminderList(onClick);
  /**
   *  sets the reminderMessage
   *  @author: Sarthak Arora
   */

  const handleCancel = () => {
    setShowPopup(false);
  };

  const closeCustomPopup = () => {
    setShowCustomPopup(false);
  };

  /**
   * renders the reminder details in Reminder List
   * @param {*} reminder
   */
  const reminderDetails = (reminder) => {
    return <div className='small text-secondary'>{`${reminder.NoOfDays} days before due date`}</div>;
  };
  return (
    <div>
      {show && (
        <DynamicReminderPopup
          popupAnchor={anchor}
          show={show}
          onClick={toggleShowPopup}
          setShowReminder={setShowPopup}
          popupBodyContent={popupBodyContent}
          handleCancel={handleCancel}
          handleSaveReminder={handleSaveReminder}
          showCustomPopup={showCustomPopup}
          closeCustomPopup={closeCustomPopup}
        />
      )}
      {workflowReminders.length > number.ZERO && (
        <WorkflowReminderInput
          onClick={onClick}
          show={show}
          reminders={workflowReminders}
          anchor={anchorInputReminder}
          reminderDetails={reminderDetails}
          handleAddRem={handleAddRem}
          setDelReminder={setDelReminder}
          handleClick={handleClick}
          delReminder={delReminder}
        />
      )}
      {isReminderClicked && (
        <WorkflowReminderEditPopup
          onClick={onClick}
          ReminderId={editRem}
          reminderMessage={editName}
          noOfDays={editNoOfDays}
          handleAddRem={handleAddRem}
          popupAnchor={anchorInputReminder}
          show={show}
          setIsReminderClicked={setIsReminderClicked}
        />
      )}
    </div>
  );
};

export default HandleWorkflowReminder;
