import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Switch } from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { closeProjectSidebar } from '../../../../actions/projectSidebar';
import { button, icon, label, number, quote } from '../../../../config';
import { accessBasedOnProjectMembers, getWorkflowUrl, popupAlign } from '../../../../helper/common';
import { usePopup } from '../../../../helper/commonHooks';
import { getProjectDetails, getProjectWorkflows } from '../../../../shared/services/projects.service';
import { editSorting, editWorkflow } from '../../../../shared/services/workflow.services';
import { copyTextToClipboard } from '../../../../utils/common';
import { GridContext } from '../../../../utils/kendo';
import { checkWorkflowRules } from '../../../Workflow/workflowHelper';
import '../../projectSidebar.scss';
import DefaultWorkflowShareIcon from './DefaultWorkflowShareIcon';
import { getExistingWorkflowPayload, updatedWorkflowValues } from './WorkflowPanelHelper';


const DefaultIcon = (props) => <td ><DefaultWorkflowShareIcon {...props} /></td>

/**
 * Parent Component: WorkflowPanel
 * It shows the quick settings for the respective worklflow
 */
const WorkflowInformationPopup = (props) => {
  const [showInfoIcon, setShowInfoIcon] = React.useState(false);
  const dispatch = useDispatch()
  const stagesContext = React.useContext(GridContext);
  const { projectDetails } = stagesContext
  const { show, setShow, contentRef: deleteRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
  const { setWorkflows, workflows } = stagesContext
  const { assignees } = useSelector((state) => state.projectSidebar);
  const projectWorkflows = useSelector((state) => state.projectWorkflows);
  const [defaultAssignee, setDefaultAssignee] = useState([]);
  const [workflowPrivacy, setWorkflowPrivacy] = useState([]);
  const [notifyClosedWorkflow, setNotifyClosedWorkflow] = useState(false);
  const [notifySharedWorkflow, setNotifySharedWorkflow] = useState(false);
  const [active, setActive] = useState(false);
  const [updatedValues, setUpdatedValues] = useState();
  const [updatedPayload, setUpdatedPayload] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [notifyRestrictedWorkflow, setNotifyRestrictedWorkflow] = useState(false);
  const [notifyWorkflowPrivacyChange, setNotifyWorkflowPrivacyChange] = useState(false);
  const [projectWorkflowList, setProjectWorkflowList] = useState(false);

  const [sharedWorkflowExist, setSharedWorkflowExist] = useState(false);
  const [closedOrRestrictedProject, setClosedOrRestrictedProject] = useState(false);
  const [sharedWorkflow, setSharedWorkflow] = useState(false);
  const [closedWorkflowExist, setClosedWorkflowExist] = useState(false);
  const [sharedOrRestrictedProject, setSharedOrRestrictedProject] = useState(false);
  const [closedWorkflow, setClosedWorkflow] = useState(false);
  const [allRestrictedWorkflows, setAllRestrictedWorkflows] = useState(false);
  const [closedOrSharedWorkflow, setClosedOrSharedWorkflow] = useState(false);
  const [sharedOrClosedProject, setSharedOrClosedProject] = useState(false);
  const [restrictedWorkflow, setRestrictedWorkflow] = useState(false);

  const projectSettings = useSelector((state) => state.projectSettings);
  const { id, companyId, myProjectId } = useSelector((state) => state.auth.user)

  const { defaultDetails } = useSelector((state) => state.tasks)
  const [project, setProject] = useState(null);
  const [workflowAccess, setWorkflowAccess] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const anchor = useRef();

  /**sets privacy related states*/
  useEffect(() => {
    setSharedWorkflowExist(workflows?.map((workflow) => workflow?.WorkflowPrivacy).some((item) => item === number.ONE));
    setClosedOrRestrictedProject(projectDetails.Privacy != number.ONE);
    setSharedWorkflow(workflowPrivacy?.value == number.ONE);
    setClosedWorkflowExist(workflows?.map((workflow) => workflow?.WorkflowPrivacy).every((item) => item === number.TWO));
    setSharedOrRestrictedProject(projectDetails.Privacy != number.TWO);
    setClosedWorkflow(workflowPrivacy?.value == number.TWO);
    setAllRestrictedWorkflows(workflows?.map((workflow) => workflow?.WorkflowPrivacy).every((item) => item === number.THREE));
    setClosedOrSharedWorkflow(workflowPrivacy?.value != number.THREE);
    setSharedOrClosedProject(projectDetails.Privacy != number.THREE);
    setRestrictedWorkflow(workflowPrivacy?.value == number.THREE);
    setProject(defaultDetails?.allProjectsList?.find((project) => project.ProjectId == projectDetails.ProjectId));
    setWorkflowAccess(project ? (accessBasedOnProjectMembers(project, user?.id) || (project?.ProjectId == user.myProjectId)) : projectDetails?.CreatedBy == user.id)
  }, [workflows, workflowPrivacy?.value, projectDetails.Privacy, project])

  /**sets projectWorkflowList to true if it exist in  projectWorkflows */
  useEffect(() => {
    setProjectWorkflowList(projectWorkflows.hasOwnProperty(projectDetails?.ProjectId))
  }, [projectWorkflows, projectDetails?.ProjectId])

  /**notifySharedWorkflow is set to true if there are no workflows with shared privacy, the project privacy is not shared, and a workflow privacy is changed to shared by the user.*/
  useEffect(() => {
    setNotifySharedWorkflow(!sharedWorkflowExist && closedOrRestrictedProject && sharedWorkflow)
  }, [sharedWorkflowExist, closedOrRestrictedProject, sharedWorkflow]);

  /**notifyClosedWorkflow is set to true if not all workflows have closed privacy, the project privacy is not closed, and a workflow privacy is changed to closed by the user.*/
  useEffect(() => {
    setNotifyClosedWorkflow(!closedWorkflowExist && sharedOrRestrictedProject && closedWorkflow)
  }, [closedWorkflowExist, sharedOrRestrictedProject, closedWorkflow]);

  /**The workflow privacy change condition is set to true if all workflows are restricted and a user updates a workflow's privacy to shared or closed.*/
  useEffect(() => {
    setNotifyWorkflowPrivacyChange(allRestrictedWorkflows && closedOrSharedWorkflow)
  }, [allRestrictedWorkflows, closedOrSharedWorkflow]);

  /**The restricted workflow condition is set to true if the privacy of all workflows is not restricted, the project privacy is not restricted, and a workflow privacy is changed to restricted by the user to make all workflows restricted.*/
  useEffect(() => {
    setNotifyRestrictedWorkflow(!allRestrictedWorkflows && sharedOrClosedProject && restrictedWorkflow)
  }, [allRestrictedWorkflows, sharedOrClosedProject, restrictedWorkflow]);

  const hideOnBlur = () => {
    setShow(false);
  }

  const togglePopup = () => {
    workflowAccess && setShow(!show)
  }

  /**sets quick setting fields for the workflow*/
  useEffect(() => {
    setDefaultAssignee(assignees?.find(a => a.value === props.dataItem?.DefaultAssignee))
    setActive(props.dataItem?.IsActive)
    setIsDefault(props.dataItem?.IsDefault)
    setWorkflowPrivacy(projectSettings?.privacyList?.find((c) => c.value == props.dataItem?.WorkflowPrivacy))
    setUpdatedPayload(getExistingWorkflowPayload(props.dataItem?.IsActive, props.dataItem?.WorkflowPrivacy, props.dataItem?.DefaultAssignee, props.dataItem?.IsDefault))
  }, [props.dataItem])

  /**sets updated values of a workflow*/
  useEffect(() => {
    setUpdatedValues(updatedWorkflowValues(active, workflowPrivacy?.value, defaultAssignee?.value, isDefault, updatedPayload))
  }, [active, workflowPrivacy?.value, defaultAssignee?.value, isDefault])

  /**sets values of quick settings in case of unsaved changes */
  useEffect(() => {
    if (!show) {
      setDefaultAssignee(assignees?.find(a => a.value === props.dataItem?.DefaultAssignee))
      setActive(props.dataItem?.IsActive)
      setIsDefault(props.dataItem?.IsDefault)
      setWorkflowPrivacy(projectSettings?.privacyList?.find((c) => c.value == props.dataItem?.WorkflowPrivacy))
      setUpdatedPayload(getExistingWorkflowPayload(props.dataItem?.IsActive, props.dataItem?.WorkflowPrivacy, props.dataItem?.DefaultAssignee, props.dataItem?.IsDefault))
    }
  }, [show])

  /**updates the edits made by user in the quick settings */
  const handleEditWorkflow = async () => {
    updatedValues["workflowId"] = props.dataItem?.WorkflowId;
    await dispatch(editWorkflow(updatedValues));
    updatedValues.hasOwnProperty("isDefault") && (await dispatch(editSorting({ entityName: "Workflows", projectId: projectDetails?.ProjectId })));
    let projectWorkflows = await dispatch(getProjectWorkflows({ projectId: projectDetails?.ProjectId }));
    setWorkflows(projectWorkflows)
    setShow(!show)
    if (notifyClosedWorkflow || notifySharedWorkflow || notifyRestrictedWorkflow || notifyWorkflowPrivacyChange) {
      dispatch(getProjectDetails({ projectId: projectDetails?.ProjectId, userId: id, companyId: companyId }))
    }
  }

  /**
   * handles privacy dropdown state
   * @param {Object} event
   * @returns {Null}
   * @author Himanshu Negi
   */
  const handlePrivacyChange = (event) => {
    let isLastSharedProject = false;
    if (projectDetails?.ProjectId === myProjectId) {
      isLastSharedProject = checkWorkflowRules(event, projectWorkflows[myProjectId]);
      if (isLastSharedProject) return;
    }
    !isLastSharedProject && setWorkflowPrivacy(event.target.value)
  }

  const handleWorkflowNameClick = () => {
    dispatch(closeProjectSidebar());
  }

  /**
 * copy Workflow Task Url To Clipboard
 * @param {Void}
 * @returns {Void}
 * @author Shivam
 */


  const DefaultWorkflowIcon = () => {
    return <span className='inactive-workflow'>
      <span >
        <i>{!props.dataItem.IsActive && label.INACTIVE_WORKFLOW}</i>
      </span>
    </span>
  }

  /**
     * copies the email to clipboard
     * @author {Sarthak Arora}
     */

  const copyToClipboard = () => {
    copyTextToClipboard(props?.dataItem.EmailId, label.EMAIL_COPIED)
  }


  return (
    <React.Fragment><div className="position-relative" onMouseOver={() => setShowInfoIcon(true)} onMouseLeave={() => setShowInfoIcon(false)}>
      <span className='workflow-name-col d-flex align-items-center'>
        <span className='text-truncate'>
          {workflowAccess ? <Link id="open-workflow" to={getWorkflowUrl(projectDetails?.ProjectId, projectDetails?.ProjectName, props.dataItem?.WorkflowId)} onClick={handleWorkflowNameClick} className="workflow-link">
            {props.dataItem?.WorkflowName}</Link> : props.dataItem?.WorkflowName}
        </span>

      </span>
      <Tooltip anchorElement="target" parentTitle={true} position="bottom">
        {props.dataItem?.EmailStatus && <span onClick={copyToClipboard} id='workflow-info-icon' title={props.dataItem?.EmailId} className='mail-icon top-0 position-absolute'>
          {icon.EMAIL_NOTIFICATION}
        </span>}</Tooltip>
      {<span id='workflow-info-icon' ref={anchor} onClick={togglePopup} className={` ${workflowAccess ? 'cursor-pointer information-icon position-absolute' : 'information-icon position-absolute'} ${showInfoIcon ? "show" : "hide"}`} >
        {icon.INFO_ICON}
      </span>}
      <DefaultWorkflowIcon />
    </div>
      <Popup show={show} anchor={anchor.current} onOpen={() => onOpen(deleteRef)} popupAlign={popupAlign("right", "top")} popupClass={'dt-popup'}>
        <div
          className='workflow-quick-setting-container'
          ref={deleteRef}
          tabIndex={number.ZERO}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
        >
          <div className="dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3" >
            <div className="dt-popup-title text-left text-truncate">
              {props.dataItem?.WorkflowName}
              <p className='small mb-0 pr-3 text-truncate'>
                {props.dataItem?.WorkflowDescription}
              </p>
            </div>
            <div className="d-flex">
              <span className='share-workflow-link'><DefaultIcon {...props} /></span>
              <span className="dt-popup-cancel-btn cursor-pointer ml-2" title={label.CLOSE_POPUP} onClick={hideOnBlur}>
                <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                  {icon.CLOSE}
                </Tooltip>
              </span>
            </div>
          </div>
          <div className='dt-popup-body workflow-quick-settings position-relative'>
            {!project?.IsPersonal && <div className='form-group'>
              <label>{label.ASSIGNEE}</label>
              <DropDownList
                data={[{ label: label.UNASSIGNED, value: null }, ...assignees.filter((assignee) => assignee.accountStatus != number.ONE)]}
                id='wf-default-assignee'
                value={defaultAssignee ? defaultAssignee : { label: label.UNASSIGNED, value: null }}
                dataItemKey="value"
                textField="label"
                onChange={(event) => { setDefaultAssignee(event.target.value); }}
              />
            </div>}
            {props.dataItem?.EmailStatus ? <div className='form-group'>
              <label>{label.EMAIL_FOR_TASK_CREATION}</label>
              <div className='d-flex'>
                <div className='workflow-email text-truncate w-100' title={props.dataItem?.EmailId}>{props.dataItem?.EmailId}</div>
                <span className='ml-2 cursor-pointer' onClick={copyToClipboard}>{icon.COPY_CONTENT}</span>
              </div>
            </div> : <></>}
            <div className='form-group'>
              <label>{label.PRIVACY}</label>
              <DropDownList
                onChange={handlePrivacyChange}
                id='wf-privacy'
                disabled={project?.IsPersonal && isDefault}
                value={workflowPrivacy}
                data={projectDetails?.ProjectId === myProjectId ? projectSettings?.privacyList?.filter((p) => p.value !== number.TWO) : projectSettings?.privacyList}
                textField="label"
              />
            </div>
            <div className='form-row'>
              <div className='col-md-6 d-flex align-content-center'>
                <label className='mt-1 mb-0'>{label.ACTIVE}</label>
                <div className='workflow-q-input flex-grow-1 workflow-panel-switch' title={props.dataItem?.IsDefault ? quote.CHOOSE_NEW_REPLACE_DEFAULT : ""}><Switch
                  checked={!!active}
                  onChange={(() => { setActive(!active) })}
                  disabled={!!(isDefault)}
                /></div>
              </div>
              <div className='col-md-6 d-flex align-content-center'>
                <label className='mt-1 mb-0'>{label.MAKE_DEFAULT}</label>
                <div className='workflow-q-input flex-grow-1 workflow-panel-switch'>
                  <Switch
                    disabled={!active || ((workflowPrivacy?.value === number.THREE && project?.IsPersonal) || props.dataItem?.IsDefault)}
                    checked={!!isDefault}
                    onChange={(() => { setIsDefault(!isDefault) })}
                  /></div>
              </div>
            </div>
          </div>
          <div className="dt-popup-footer text-right">
            <button disabled={_.isEmpty(updatedValues)} type="submit" className="btn btn-primary btn-width" title="Save" onClick={handleEditWorkflow}>{button.SAVE}</button>
          </div>
        </div>
      </Popup>
    </React.Fragment>
  )
}

export default WorkflowInformationPopup;
