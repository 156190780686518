// bookmarkUtils.js
import { ProseMirror } from "@progress/kendo-react-editor";
import { notifyIcon, quote } from "../../config";
import { getNotification } from "../../utils/common";

const { TextSelection } = ProseMirror;

export const addBookmark = (view) => {
  if (!view) return;
  const { state, dispatch } = view;
  const schema = state.schema;
  const nodeType = schema.nodes.icon;
  const uniqueId = `bookmark-${Date.now()}`;
  const { $from } = state.selection;
  const startOfParagraph = $from.start($from.depth);
  const paragraphNode = $from.node($from.depth);

  // Check if a bookmark already exists at the start of the paragraph
  if (paragraphNode.firstChild && paragraphNode.firstChild.type === nodeType) {
    getNotification(quote.BOOKMARK_EXIST, notifyIcon.WARNING_ICON);
    return;
  }
  const transaction = state.tr;
  // Detect the span containing `@`
  const pos = $from.pos; // Current cursor position
  const nodeBefore = $from.nodeBefore;

  if (
    nodeBefore &&
    nodeBefore.type.name === "nonEditable" // Check for the specific span class
  ) {
    const spanPos = pos - nodeBefore.nodeSize; // Calculate position of the span
    transaction.delete(spanPos, pos); // Delete the span containing the `@`
  }
  // Step 2: Insert the bookmark icon at the start of the paragraph
  const iconNode = nodeType.createAndFill({
    class: "material-symbols-outlined",
    id: uniqueId,
  });

  if (iconNode) {
    transaction.insert(startOfParagraph, iconNode);
    dispatch(transaction);
    view.focus();
    getNotification(quote.BOOKMARK_TAG_ADDED, notifyIcon.SUCCESS_ICON);
  }
};

// Function to remove a bookmark by its ID
export const removeBookmark = (view, bookmarkId) => {
  if (!view) return;

  const { state, dispatch } = view;
  const { tr, doc } = state;
  let bookmarkFound = false;

  // Iterate over all nodes in the document
  doc.descendants((node, pos) => {
    if (node.type.name === "icon" && node.attrs.id === bookmarkId) {
      // Remove the node by creating a transaction
      tr.delete(pos, pos + node.nodeSize);
      bookmarkFound = true;
      return false; // Stop further traversal
    }
    return true; // Continue traversal
  });

  if (bookmarkFound) {
    dispatch(tr); // Dispatch the transaction to update the document
    view.focus(); // Refocus the editor
    getNotification(quote.BOOKMARK_TAG_DELETED, notifyIcon.WARNING_ICON);
  }
};

export const navigateToBookmark = async (view, bookmarkId) => {
  if (!view) return;

  const { state } = view;
  const { doc } = state;
  let bookmarkPosition = null;

  // Find the position of the node with the matching bookmark ID
  doc.descendants((node, pos) => {
    if (node.type.name === "icon" && node.attrs.id === bookmarkId) {
      bookmarkPosition = pos;
      return false; // Stop further traversal
    }
    return true; // Continue traversal
  });

  if (bookmarkPosition !== null) {
    // Find the parent paragraph node
    const resolvedPos = doc.resolve(bookmarkPosition);
    const paragraphPos = resolvedPos.before(resolvedPos.depth); // Parent paragraph position
    const paragraphNode = doc.nodeAt(paragraphPos);

    if (paragraphNode && paragraphNode.type.name === "paragraph") {
      const start = paragraphPos; // Start position of the paragraph
      const end = paragraphPos + paragraphNode.nodeSize; // End position of the paragraph
      // Select the entire paragraph's text
      const transaction = state.tr.setSelection(TextSelection.create(doc, start, end - 1)).scrollIntoView();
      view.dispatch(transaction);

      // Ensure the editor is scrolled to the bookmark position
      const domNode = view.domAtPos(end)?.node;
      if (domNode && domNode.scrollIntoView) {
        domNode.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      // Scroll explicitly using Kendo's container or ProseMirror's DOM node
      const editorContainer = view.dom.parentElement; // Kendo's editor container
      const targetNode = view.domAtPos(end)?.node;
      if (editorContainer && targetNode) {
        const targetRect = targetNode.getBoundingClientRect();
        const containerRect = editorContainer.getBoundingClientRect();
        // Ensure the target node is visible in the container
        if (targetRect.top < containerRect.top || targetRect.bottom > containerRect.bottom) {
          editorContainer.scrollTop += targetRect.top - containerRect.top - 50; // Adjust offset if needed
        }
      }
      view.focus(); // Focus the editor to enable interaction
    }
  }
};
