import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { button, icon, kendo, label, number, quote } from "../../../config";
import { createProject } from "../../../shared/services/projects.service";
import { sliceString } from "../../../utils";
import { createDynamicTeamName } from "../../../utils/common";
import { filterData, onBlur, onFocus, onOpen } from "../../../utils/kendo";

const CreateProjectPopup = (props) => {
  const { anchor, show, setShow, queueId } = props;
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [queue, setQueue] = useState(null);
  const [queueError, setQueueError] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const deleteRef = useRef(null);
  const blurTimeoutRef = useRef(null);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const allQueues = useSelector((state) => state.allQueues);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    setFilteredList(allQueues?.sort((a, b) => a.QueueName.trim().localeCompare(b.QueueName.trim()))?.filter((q) => q.RoleId));
  }, [allQueues]);

  useEffect(() => {
    if (show) setTimeout(() => inputRef?.current?.focus(), number.FOUR_HUNDRED);
  }, [show]);

  /**
   * used to set value of projectName
   * @param {*} event
   */
  const handleProjectName = (e) => {
    const projectName = e.target.value;
    setName(projectName);
    setProjectCode(sliceString(projectName, number.FOUR).toUpperCase());
    if (!projectName) {
      setNameError(quote.REQUIRED_FIELD);
    } else if (projectName && projectName.replace(/ /g, "").length < number.FOUR) {
      setNameError(createDynamicTeamName(quote.PROJECT_NAME_LENGTH_ERROR, user.operationalTeamName));
    } else {
      setNameError("");
    }
  };

  /**
   * used to set value of queue
   * @param {*} event
   */
  const handleQueueChange = (e) => {
    const queue = e.target.value;
    setQueue(queue);
    if (!queue?.QueueId) {
      setQueueError(quote.REQUIRED_FIELD);
    } else {
      setQueueError("");
    }
  };

  /**
   * creates project
   * @param {*} event
   */
  const handleCreateProject = (e) => {
    e.preventDefault();
    const payload = { userId: user.id, companyId: user.companyId, name, projectCode, queue: queueId ? queueId : queue?.QueueId, workspacePage: queueId };
    dispatch(createProject(payload));
    setShow(false);
  };

  const hideOnBlur = () => {
    setShow(false);
  };

  const filterChangeForWorkSpace = async (event) => {
    const filteredListWorkspace = filterData(
      event.filter,
      allQueues?.sort((a, b) => a.QueueName.trim().localeCompare(b.QueueName.trim()))?.filter((q) => q.RoleId)
    );
    setFilteredList([...filteredListWorkspace]);
  };
  return (
    <React.Fragment>
      <Popup anchor={anchor.current} show={true} animate={true} onOpen={() => onOpen(deleteRef)} popupClass={"dt-popup"}>
        <div className='create-project-popup' ref={deleteRef} tabIndex={number.ZERO} onFocus={() => onFocus(blurTimeoutRef)} onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
          <div className='dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3'>
            <div className='dt-popup-title'>{label.ADD_TEAM}</div>
            <span className='dt-popup-cancel-btn cursor-pointer ml-2' title={label.CLOSE_POPUP} onClick={hideOnBlur}>
              <Tooltip anchorElement='target' parentTitle={true} position='bottom'>
                {icon.CLOSE}
              </Tooltip>
            </span>
          </div>
          <div className='dt-popup-body'>
            <div className='row'>
              <div className='form-group col-md-12'>
                <label htmlFor='project-name'>{label.NAME}</label>
                <Input id='project-name' type={"text"} ref={inputRef} name='projectName' value={name} onChange={handleProjectName} autoComplete='off' maxLength={kendo.INPUT_MAX_LENGTH} />
                <span className={`error-text text-danger ${!nameError && "d-none"}`}>{nameError ? nameError : quote.REQUIRED_FIELD}</span>
              </div>
              {!queueId && (
                <div className='form-group col-md-12'>
                  <label htmlFor='team-name'>{label.QUEUE_NAME}</label>
                  <DropDownList
                    id='team-name'
                    name='queue'
                    data={filteredList}
                    value={queue}
                    onChange={handleQueueChange}
                    textField='QueueName'
                    filterable={true}
                    onFilterChange={filterChangeForWorkSpace}
                    dataItemKey='QueueId'
                    defaultItem={{ QueueName: "Select Workspace" }}
                  />
                  <span className={`error-text text-danger ${!queueError && "d-none"}`}>{queueError ? queueError : "cskm"}</span>
                </div>
              )}
            </div>
          </div>
          <div className='dt-popup-footer text-right'>
            <button className='btn btn-secondary mr-2' onClick={hideOnBlur}>
              {button.CANCEL}
            </button>
            <button type='submit' id='create-team' className='btn btn-primary' disabled={name.replace(/ /g, "").length < number.FOUR || !(queue?.QueueId || queueId)} onClick={handleCreateProject}>
              {button.CREATE}
            </button>
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default CreateProjectPopup;
