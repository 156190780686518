import axios from "axios";
import { number } from "../../../../config";
import { limit, requestMethod } from "../../../../config/constants";
import Config from "../../../../env.config";
import { formatDate } from "../../../../helper/common";
import { splitString } from "../../../../utils";
import { bytesToSize, compareFileSize } from "../../../../utils/common";

/**
 * check current user is owner of the project.
 * for showing attachement delete icon in dashboard screen.
 */
export const checkOwner = (creator, user, projectOwners) => {
  if (creator == user?.id) {
    return true;
  } else {
    for (let i = 0; i < projectOwners?.length; i++) {
      if (projectOwners[i].UserId == user?.id) {
        return true;
      }
    }
    return false;
  }
};

/**
 * returns the the file extension from attachment name
 * @param {attachmentName} String
 * @returns String
 */
export const getFileExtension = (attachmentName) => {
  const file = attachmentName ? splitString(attachmentName, ".") : "";
  return file[file.length - number.ONE];
};
/**
 * returns the name of attachment  from attachment name string
 * @param {attachmentName} String
 * @returns String
 */
export const getattachmentName = (attachmentName) => {
  if (attachmentName) {
    const index = attachmentName.lastIndexOf(".");
    const fileName = attachmentName.substr(number.ZERO, index);
    return fileName;
  }
};

/**
 * sorts user list in comments mention
 * @param {string} AttachmentName, AttachmentId
 * @param {Array } attachments,
 * @returns Array of objects
 */
export const renameAttachmentHelper = (attachments, id, AttachmentName, AttachmentId) => {
  return attachments?.map((item) => {
    if (item[AttachmentId] == id) {
      return { ...item, AttachmentName: `${AttachmentName}` };
    }
    return item;
  });
};

export const isValidSize = (fileSize, projectId) => {
  let maxFileSize = projectId ? limit.DASHBOARD_LIMIT : limit.TASK_LIMIT;
  return compareFileSize(fileSize, maxFileSize);
};

/**
 * returns attachment information
 */
export const getAttachmentInfo = (attachment) => {
  let showFileSize = bytesToSize(attachment.FileSize);
  return `by ${attachment.CreatedBy} on ${formatDate(attachment.CreatedOn)}, ${showFileSize}`;
};

/**
 * returns file name(50 characters) and its extension
 * @param {String} filename
 * @returns {String}
 * @author Muskan Thakur
 */
export const checkNameSizeWithExtension = (filename) => {
  const extensionStart = filename.lastIndexOf(".");
  const extension = filename.slice(extensionStart);
  const filenameWithoutExtension = filename.slice(number.ZERO, extensionStart);
  const shortenedFilename = filenameWithoutExtension.slice(number.ZERO, limit.MAX_FILENAME_LENGTH) + extension;
  return shortenedFilename;
};

/**
 * returns file name(50 characters)
 * @param {String} filename
 * @returns {String}
 * @author Muskan Thakur
 */
export const checkNameSize = (filename) => {
  return filename.slice(number.ZERO, limit.MAX_FILENAME_LENGTH);
};

/**
 * downloads Attachment file
 * @param {String} file
 * @param {String} filename
 * @author Himanshu Negi
 */
export const downloadFile = async (file, filename, fileS3Key) => {
  const fileKey = file ? file.split("/")[number.THREE] + "/" + file.split("/")[number.FOUR] : fileS3Key;
  const response = await axios.request({
    method: requestMethod.GET,
    url: `${Config.BASE_URL.BASE_API_URL}/common/downloadAttachment`,
    params: { key: fileS3Key ?? fileKey, filename },
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const generateRandomId = () => {
  return `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

/**
 * Returns new attachment object for new task
 * @param {*} file
 * @param {*} imageUrl
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getAttachmentObject = (file, imageUrl, user) => {
  if (!file || !imageUrl) return;
  return {
    AttachmentId: generateRandomId(),
    AttachmentLink: imageUrl,
    AttachmentName: file.name,
    CreatedBy: user.name,
    CreatorId: user.id,
    CreatedOn: Date.now(),
    FileSize: file.size,
    IsActive: { type: "Buffer", data: [1] },
  };
};
