import _ from "lodash";
import { number } from "../../config";
import store from "../../store";
import { TASK_KEY_MAP } from "./sidebar.constants";

/**
 * Decides according to workflow settings whether a section is visible or not
 * @param {String} field
 * @returns {Boolean}
 * @author Muskan Thakur
 */
export const getRenderCondition = (field) => {
  const state = store.getState();
  const { showDescription, showAttachments, showComments, showLinkedTasksSubTasks } = state?.taskSidebar;
  if (!field) {
    return true;
  }
  switch (field) {
    case "ShowDescription":
      return !!showDescription;
    case "ShowAttachments":
      return !!showAttachments;
    case "ShowLinkedTasks":
      return !!showLinkedTasksSubTasks;
    case "ShowComments":
      return !!showComments;
    case "ShowWorkflow":
    case "ShowActivity":
      return true;
    default:
      return false;
  }
};

/**
 * Maps DB keys with UI and Backend keys
 * @param {String} key
 * @returns {String} DB key
 */
export const mapTaskKey = (key) => {
  return TASK_KEY_MAP[key];
};

export const getUpdatePayload = () => {
  let state = store.getState(),
    task = state.taskSidebar.task;
  const { positionId } = state.userPosition;

  return { taskId: task.taskId, userId: state.auth.user.id, taskHistoryId: task.taskHistoryId, positionId };
};

/**
 * to extract the name of key which is updated in exsiting task
 * @param {Object}
 * @returns {String}
 * @author Shivam Mishra
 */
export const getKey = (payloadObject) => {
  const arr = ["taskId", "userId", "taskHistoryId", "positionId"];
  const key = Object.keys(payloadObject).find((i) => !arr.includes(i));
  return key;
};

/**
 * creates payload for clone or new task
 * @returns {object}
 */
export const attachmentPayloadHelper = (allAttachments, taskId, userId, attachmentKey, entityName) => {
  const name = allAttachments.flatMap((i) => (i.CloneTask ? [i.AttachmentName] : [])).toString();
  const fileSize = allAttachments.flatMap((i) => (i.CloneTask ? [i.FileSize] : [])).toString();
  const key = allAttachments.flatMap((i) => (i.CloneTask ? [i.AttachmentKey] : []));
  let cloneOrNewTaskData = {
    attachments: allAttachments,
    taskId: taskId,
    attachmentKey: key,
    userId: userId,
    attachmentName: name,
    fileSize: fileSize,
    key: attachmentKey,
    entityName: entityName,
  };
  return cloneOrNewTaskData;
};

/**
 * returns the project where the task is currently active
 * @param {Array} flows
 */
export const getCurrentActiveProject = (flows) => {
  const reversedFlowElements = _.reverse([...flows]);
  const currentProject = reversedFlowElements?.find((flow) => flow?.isCompleted === number.ONE);
  const currentProjectIndex = flows?.findIndex((flow) => flow?.id === currentProject?.id);
  return { currentProject, currentProjectIndex };
};

/**
 * Formats a username and timestamp into a readable string with AM/PM.
 * Example: "JohnDoe (23/12 02:45 PM)"
 *
 * @param {string} username - The user's name to include in the formatted string.
 * @param {Date|string|number} date - A valid date object, ISO string, or timestamp to format.
 * @returns {string} - Formatted string in the format: "username DD MM YY".
 */
export const formatUserTimestamp = (username, date) => {
  // Ensure the provided date is converted to a Date object
  const now = new Date(date);

  // Extract day, month, and year components
  const day = String(now.getDate()).padStart(2, "0"); // Zero-pad day for single digits
  const month = now.toLocaleString("en-US", { month: "short" }); // Use abbreviated month name (e.g., Jan, Feb)
  const year = now.getFullYear().toString().slice(-2); // Extract the last two digits of the year

  // Build and return the formatted string
  return `${username} ${day} ${month} ${year}`;
};
