import React, { useEffect, useState } from "react";
import { label } from "../../../config/index";
import "./reminder.scss";

/**
 * Reminder List Component
 * Updated UI with button-like reminders and delete cross icon
 */
const WorkflowReminderInput = React.memo(({ reminders, handleClick, delReminder, setDelReminder, anchor }) => {
  const [reminderList, setReminderList] = useState([]);

  useEffect(() => {
    setReminderList(reminders);
  }, [reminders]);

  const handleDelete = (id, e) => {
    e.stopPropagation();
    delReminder(id, e);
  };
  return (
    <div className='reminders-container'>
      <div className='mt-4'>{label.REMINDERS}</div>
      <div className='reminders-list border p-1'>
        {reminderList?.length > 0 ? (
          reminderList.map((reminder) => (
            <div ref={anchor} key={reminder.Id} className='reminder-item d-inline-block' onClick={() => handleClick(reminder)}>
              <span className='reminder-text'>{`${reminder.NoOfDays} Days Before Due Date`}</span>
              <span className='delete-icon cursor-pointer red' onClick={(e) => handleDelete(reminder.Id, e)} title={label.DEL_REM}>
                ×
              </span>
            </div>
          ))
        ) : (
          <div className='no-reminders'>No reminders available.</div>
        )}
      </div>
    </div>
  );
});

export const ReminderItems = () => {
  return (
    <div ref={anchor} key={reminder.Id} className='reminder-item d-inline-block' onClick={() => handleClick(reminder)}>
      <span className='reminder-text'>{`${reminder.NoOfDays} Days Before Due Date`}</span>
      <span className='delete-icon cursor-pointer red' onClick={(e) => handleDelete(reminder.Id, e)} title={label.DEL_REM}>
        ×
      </span>
    </div>
  );
};

export default WorkflowReminderInput;
